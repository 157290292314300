import React from 'react';
import { Link } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import MetaTags from 'react-meta-tags'
import OOAKTable from '../../../util/oneofakinds'
import RetiredTable from '../../../util/retired'
import 'whatwg-fetch';

export default class PlayerWishlist extends React.Component{
  constructor(){
    super()
    this.state = {savedItems: [], loadedItems: false, allItems: [],  loadedAll: false, targetNumber: 0, OOAK:[], Retired:[], includeUniques: false, includeOOAK: false, Palindrome: false  }
    this.fetchSaved = this.fetchSaved.bind(this)
    this.fetchAll = this.fetchAll.bind(this)

  }

  reverseString(str) {
      var splitString = String(str).split("");
      var reverseArray = splitString.reverse();
      var joinArray = reverseArray.join("");
      return joinArray;
  }

  isPalindrome(number) {
    var flip = this.reverseString(number)
    if (number==flip) { return true } else { return false }
  }

  fetchSaved(personID){
    const key = utilities.apiKey;
    const savedItemsURL = endpoints.userProfile+personID+"/saveditems";
    var savedItems = []
    var itemTypeIds = []

    fetch(savedItemsURL,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {

      itemTypeIds=Object.keys(json.saveditems)

      itemTypeIds.map( item => {
        savedItems[item]=json.saveditems[item]
      })

      this.setState({
        loadedItems: true,
        savedItems: savedItems
      })
    })
  }

  fetchAll(personID){

    const key = utilities.apiKey;
    const savedItemsURL = endpoints.itemByID+"all";

    fetch(savedItemsURL,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        loadedAll: true,
        allItems: json
      })
    })
  }

  fetchOOAK(){
    var tempArray = []

    OOAKTable.OneOfAKind.map( item => {
        tempArray.push(item.item_type_id)
    })

    if (tempArray.length != 0) {
      this.setState({OOAK: tempArray})
    }

  }

  fetchRetired(){
    var tempArray = []

    RetiredTable.Retired.map( item => {
        tempArray.push(item.item_type_id)
    })

    if (tempArray.length != 0) {
      this.setState({Retired: tempArray})
    }

  }

  setOptions(includeUniques,targetNumber){
    if ((targetNumber=="U")||(includeUniques=="U")) {
      this.setState({includeUniques: true })
    }
    if ((targetNumber=="U")&&(includeUniques=="U")) {
      this.setState({includeOOAK: true })
    }
    if (targetNumber=="P") {
      this.setState({Palindrome: true})
    }
  }

  IncludeUniques(event){
    if (this.state.includeUniques) {
      if (this.state.targetNumber=="U") {
        if (this.state.includeOOAK) {
          this.setState({includeOOAK: false });
        } else {
          this.setState({includeOOAK: true });
        }
      } else {
        this.setState({includeUniques: false });
      }
    } else {
      this.setState({includeUniques: true });
    }
  }


  renderWishlist (targetNumber) {
    var needList = null
    var savedItems = this.state.savedItems
    var allItems = this.state.allItems
    var OOAKItems = this.state.OOAK
    var combinedItems = []
    var combinedNames = []
    var tempNumber=0
    var tempName=null
    var tempID = 0
    var outputHTML = []
    var tempHTML = null
    var apiImage = null
    var numberText = null
    var spliceArray = []
    var OOAKflag = false
    var uniqueFlag = false
    var needCount = 0
    var paliFlag = false
    var showFlag = false
    var RetiredItems = this.state.Retired;


    allItems.map( item => {
      tempID = item.id
      tempName = item.name
      OOAKflag = false
      uniqueFlag = false
      OOAKItems.map( item_type_id =>{
        if (item_type_id==tempID) {
          OOAKflag = true
        }
      })
      if (this.state.includeOOAK) {
        OOAKflag = false
      }
      if (item.set_id==25) {
        uniqueFlag = true
      }
      if (this.state.includeUniques) {
        uniqueFlag = false
      }
      if ((targetNumber=="U")&&(item.set_id!=25)) {
        uniqueFlag = true
      }
      if ((!uniqueFlag)&&(!OOAKflag)) {
        combinedItems[tempID]=0
        combinedNames[tempID]=tempName
      }
    })
    savedItems.map( savedItem => {
      tempID = savedItem.item.item_type_id
      tempNumber= savedItem.item.number
      if (combinedItems[tempID]==0) {
      combinedItems[tempID]=tempNumber
      }
    })

    combinedItems.map((item,key) => {
      if ((item>targetNumber)||(item==0)||(targetNumber=="P")) {
        if (item==0) {
          numberText = "Missing"
      } else {
          numberText = "#"+item
        }


        var retiredFlag = 0;

        RetiredItems.map( item_type_id =>{
          if (item_type_id==key) {
            retiredFlag = 1
          }
        })


        apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+key+"-100.png";

if (retiredFlag == 1) {
  tempHTML=<div key={key} className="card">
  <div className="card-image">
    <div className="itemSetDisplay">
    <Link to={"/tools/item/" + key }>
      <img src={apiImage}  alt="itemname"/>
      <img src="https://wallazee.global.ssl.fastly.net/images/site/retired-overlay.png" className="retired" />
    </Link>
    </div>
  </div>
  <div className="card-content">
    <div className="achievement-info">
      <h5>{numberText}</h5>
      <Link to={"/tools/item/" + key }>
        <h5>{combinedNames[key]}</h5>
      </Link>
    </div>
  </div>
  </div>
} else {
        tempHTML=<div key={key} className="card">
        <div className="card-image">
          <Link to={"/tools/item/" + key }>
            <img src={apiImage}  alt="itemname"/>
          </Link>
        </div>
        <div className="card-content">
          <div className="achievement-info">
            <h5>{numberText}</h5>
            <Link to={"/tools/item/" + key }>
              <h5>{combinedNames[key]}</h5>
            </Link>
          </div>
        </div>
        </div>
}
          if (targetNumber=="P") {
            if (this.isPalindrome(item)) {
              showFlag = false
            } else {
              showFlag = true
            }
          } else {
            showFlag = true
          }
          if (showFlag) {
            outputHTML.push(tempHTML)
            needCount++
          }
      }
    })

    return <div><div style={{backgroundColor: "#fff",width: "50%"}}>{needCount} items needed.</div><br/><font color="red">NOTE: This page is in BETA and not all features have been implemented yet.<br/>If you find an error on this page please message Mizak in game and let him know.</font><br/><br/><div className="flex" id="wishlist">{outputHTML}</div></div>
  }


  componentDidMount() {
    var targetPlayer = this.props.match.params.playerID
    var targetNumber = this.props.match.params.targetNumber
    var includeUniques = this.props.match.params.includeUniques

    this.setState({targetNumber: targetNumber})

    this.setOptions(includeUniques,targetNumber);
    this.fetchSaved(targetPlayer);
    this.fetchAll(targetPlayer);
    this.fetchOOAK();
    this.fetchRetired();
  }

  render() {
    var savedItems = null
    var itemCount = 0
    var allItems = null
    var allCount = 0
    var listTitle = null
    var extraText = null
    var UniqueText = null

    if ((this.state.loadedItems)&&(this.state.loadedAll)) {
      var displayNumber = Number(this.state.targetNumber)+1

      switch (this.state.targetNumber) {
          case "M":
            listTitle = "Missing Items"
            UniqueText = "Include Uniques"
            break;
          case "P":
            listTitle = "Missing Palindrome Items"
            UniqueText = "Include Uniques"
            break;
          case "U":
            listTitle = "Missing Unique Items"
            UniqueText = "Include One of a Kinds, Staff, and Small Limited Run Uniques"

            if (!this.state.includeOOAK) {
                extraText = "One of a Kinds, Staff, and Small Limited Run Uniques Not Included"
            }
            break;
          case "1":
              listTitle = "Missing Items Greater than 1"
              break;
          default:
            listTitle = "Missing Sub-"+displayNumber+" Items"
            UniqueText = "Include Uniques"
            break;
      }

      return (
        <div className="container" id="PlayerWishlist">
          <center>
            <h1>{listTitle}</h1>
            {extraText}<br/><br/>
            <div style={{backgroundColor: "#f46508",width: "50%"}}>
            Wishlist Options
            <form style={{backgroundColor: "#fff",width: "100%"}}>
              {UniqueText}: <input type="checkbox" name="uniques" onChange={this.IncludeUniques.bind(this)} />
            </form>
            </div>
            <div className="wishlist">
            {this.renderWishlist(this.state.targetNumber)}
            </div>
          </center>
        </div>
      )

    } else {
      return <div className="container" id="PlayerWishlist"><center><br/><br/><br/><br/><br/><h1>Loading...</h1><br/><br/><br/><br/><br/></center></div>
    }
  }
}
