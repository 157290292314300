import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { fetchSingleForum, fetchSingleForumSuccess } from './actions'
import ReactPaginate from 'react-paginate'
import TimeAgo from 'react-timeago'
import MetaTags from 'react-meta-tags'
import 'whatwg-fetch'

const mapStateToProps = (state) => {
  return {
    forums: state.forums
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleForum: (forumID, id, title, closed, sticky, latest_username, latest_created_at) => dispatch(fetchSingleForum(forumID, id, title, closed, sticky, latest_username, latest_created_at)),
    fetchSingleForumSuccess: () => dispatch(fetchSingleForumSuccess())
  }
}

class SingleForum extends Component {
  constructor(props){
    super(props)
    this.state = {
      isModalOpen: false
    }
  }

  componentWillMount(){
    this.props.fetchSingleForum(this.props.match.params.forumid, this.props.match.params.page)

  }

  renderForumItems() {

    var forumID = this.props.match.params.forumid
    var pageID = this.props.match.params.page

    return this.props.forums.posts.map( (item, i) => (

      <li key={i} className="panel-block"><article className="media">
        <figure className="media-left">
          <div className="image is-64x64">

            {
              (() => {if(item.name === ""){
                return <img src={item.image_url} alt={item.name}/>
              } else {
                return <Link to={'/tools/profile/' + item.name + '/bio'} ><img src={item.image_url} alt={item.name}/></Link>
              }
              })()
            }

          </div>
        </figure>
        <div className="media-content">
          <div className="content">
            <p>
              <a href={'/forums/' + forumID + '/' + pageID + '/' + item.id + '/0'} className="thread-title"><strong className="title is-5">{item.title}</strong></a> <small>{item.name} </small>

              {
                (() => {if(item.is_staff){
                  return <span className="tag is-warning">Staff</span>
                }})()
              }

              {
                (() => {if(item.is_beekeeper){
                  return <span className="tag is-info">BeeKeeper</span>
                }})()
              }

              {
                (() => {if(item.closed){
                  return <span className="tag is-dark">Closed</span>
                }})()
              }

              <br/>
              {item.subtitle}
            </p>
          </div>
          <div>
            <em>Last Post By: {item.latest_username}, <TimeAgo date={item.latest_created_at * 1000} /> </em>
          </div>

        </div>
        <div className="media-right forum-chevron">
          <a href={'/forums/' + forumID + '/' + pageID + '/' + item.id + '/0'}><i className="fa fa-chevron-right fa-2x" aria-hidden="false"></i></a>
        </div>
      </article>
      </li>
    ))
  }

  handlePageChange = (data) => {
    this.props.history.push('/forums/' + this.props.match.params.forumid + '/' + data.selected )
    this.props.fetchSingleForum(this.props.match.params.forumid, data.selected)
  }

  renderPagination(){

    // var pagination = []
    // var len = this.props.forums.total_pages
    //
    // for (var i=0; i<=len; i++){
    //   pagination.push(<li><a className="pagination-link" aria-label={'Go to page' + {i}} href={'/forums/' + this.props.match.params.forumid + "/" +  i }>{i + 1}</a></li>)
    // }
    //
    // return   <nav className="pagination is-centered" role="navigation" aria-label="pagination">
    //   <a className="pagination-previous">Previous</a>
    //   <a className="pagination-next" href={'/forums/'} >Next page</a>
    //   <ul className="pagination-list">
    //     {pagination}
    //   </ul>
    // </nav>

    var pageNumber = this.props.forums.total_pages + 1

    return <ReactPaginate previousLabel={"Previous"}
      nextLabel={"Next"}
      breakLabel={<span>...</span>}
      breakClassName={"pagination-ellipsis"}
      pageCount={pageNumber}
      marginPagesDisplayed={3}
      pageRangeDisplayed={5}
      pageLinkClassName={"pagination-link"}
      containerClassName={"pagination is-centered"}
      subContainerClassName={"pagination-list"}
      activeClassName={"is-current"}
      previousLinkClassName={"pagination-previous"}
      nextLinkClassName={"pagination-next"}
      onPageChange={this.handlePageChange}
           />

  }

  render(){

    if (!(this.props.forums.total_pages>=0)) {
      return  <div id="loading-container">
        <img id="starburst" src="https://wallazee.global.ssl.fastly.net/images/site/starburst.png" alt=""/>
        <h2 className="title is-2">Loading</h2>
      </div>
    } else {

    return <div className="container" id="forums-main">

      <MetaTags>
        <title>{this.props.forums.forum.title} | Forums | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
      </MetaTags>

      { (this.props.forums.can_add == 1) && <div className="text-center"><Link className="button outdoors" to={'/addthread/' + this.props.forums.forum.id}><span className="icon is-small"><i className="fa fa-plus" aria-hidden="true"></i></span> <span>Add Thread</span></Link><br/><br/></div> }

      {this.renderPagination()}
      <br/>
      <ul className="panel">
        <strong className="panel-heading">{this.props.forums.forum.title}</strong>

        {this.renderForumItems()}

      </ul>
    </div>
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleForum))
