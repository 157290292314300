const OneOfAKind={
  "OneOfAKind":[
    {
      "item_type_id": "251"
    },
    {
      "item_type_id": "352"
    },
    {
      "item_type_id": "404"
    },
    {
      "item_type_id": "437"
    },
    {
      "item_type_id": "438"
    },
    {
      "item_type_id": "522"
    },
    {
      "item_type_id": "524"
    },
    {
      "item_type_id": "533"
    },
    {
      "item_type_id": "775"
    },
    {
      "item_type_id": "776"
    },
    {
      "item_type_id": "873"
    },
    {
      "item_type_id": "911"
    },
    {
      "item_type_id": "939"
    },
    {
      "item_type_id": "1227"
    },
    {
      "item_type_id": "1228"
    },
    {
      "item_type_id": "1229"
    },
    {
      "item_type_id": "1230"
    },
    {
      "item_type_id": "1231"
    },
    {
      "item_type_id": "1232"
    },
    {
      "item_type_id": "1233"
    },
    {
      "item_type_id": "1396"
    },
    {
      "item_type_id": "1402"
    },
    {
      "item_type_id": "1472"
    },
    {
      "item_type_id": "1491"
    },
    {
      "item_type_id": "1509"
    },
    {
      "item_type_id": "1901"
    },
    {
      "item_type_id": "1903"
    },
    {
      "item_type_id": "1933"
    },
    {
      "item_type_id": "2026"
    },
    {
      "item_type_id": "2571"
    },
    {
      "item_type_id": "2575"
    },
    {
      "item_type_id": "3345"
    },
    {
      "item_type_id": "3346"
    },
    {
      "item_type_id": "3347"
    },
    {
      "item_type_id": "3229"
    },
    {
      "item_type_id": "1433"
    },
    {
      "item_type_id": "1492"
    },
    {
      "item_type_id": "1536"
    },
    {
      "item_type_id": "1663"
    },
    {
      "item_type_id": "1727"
    },
    {
      "item_type_id": "1817"
    },
    {
      "item_type_id": "1818"
    },
    {
      "item_type_id": "1821"
    },
    {
      "item_type_id": "2391"
    },
    {
      "item_type_id": "2392"
    },
    {
      "item_type_id": "2393"
    },
    {
      "item_type_id": "2394"
    },
    {
      "item_type_id": "2395"
    },
    {
      "item_type_id": "2396"
    },
    {
      "item_type_id": "2397"
    },
    {
      "item_type_id": "2398"
    },
    {
      "item_type_id": "2533"
    },
    {
      "item_type_id": "3387"
    },
    {
      "item_type_id": "3388"
    },
    {
      "item_type_id": "3389"
    },
    {
      "item_type_id": "1754"
    }

  ]
}

export default OneOfAKind
