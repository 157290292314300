import actionTypes from './actionTypes'

const defaultState = { userId: null, username: null, balance:null, birthday:'', sets:[], completed_sets:null, items:[], achievements: [], token:'', friends:[], lockeditems: [], pouchitems: {}}

const reducer = (state=defaultState,action) => {

  //https://babeljs.io/docs/plugins/transform-object-rest-spread/

  switch (action.type) {
    case actionTypes.FETCH_PROFILE:
      return { ...state,
        ...action.payload
      }
    case actionTypes.FETCH_PROFILE_DATA_SUCCESS:
      return { ...state,
        ...action.payload
      }
    case actionTypes.FETCH_USER_SETS:
      return { ...state,
         ...action.payload
      }
//    case actionTypes.FETCH_USER_LOCKED_ITEMS:
//      return { ...state,
//        ...action.payload
//      }
   case actionTypes.FETCH_USER_ACHIEVEMENTS:
     return { ...state,
       achievements: action.payload
     }
    case actionTypes.FETCH_USER_FRIENDS:
      return { ...state,
        ...action.payload
      }
      case actionTypes.FETCH_USER_POUCH:
        return { ...state,
          ...action.payload
        }
        case actionTypes.FETCH_USER_BIRTHDAY:
          return { ...state,
            ...action.payload
          }
    default:
        return state
  }

}

export default reducer
