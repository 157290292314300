import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink, Link } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import TimeAgo from 'react-timeago'
import 'whatwg-fetch';

export default class ItemHistory extends React.Component{
  constructor(){
    super()
    this.state = {story:[],itemInfo:null,foundStory: false, foundItem: false}
    this.fetchHistory = this.fetchHistory.bind(this)
    this.fetchItem = this.fetchItem.bind(this)
  }

  fetchHistory(targetID){
    const historyURL = endpoints.itemByID+"history/"+targetID;
    const key = utilities.apiKey;

    fetch(historyURL,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        story: json,
        foundStory: true
      });
    })
  }

  fetchItem(targetID){
    const itemurl = endpoints.itemByID+targetID;
    const key = utilities.apiKey;
    var tempitem = []

    fetch(itemurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      tempitem = Object.values(json)
      this.setState({
        itemInfo: tempitem[0]
      });
      this.setState({
        foundItem: true
      })
    })
  }

  renderBackToItem(){
    var targetURL = "/tools/item/" + this.state.itemInfo.item_type_id+"/num/"+this.state.itemInfo.number;
    var targetText = "Back to #"+this.state.itemInfo.number+ " " + this.state.itemInfo.name;

    var backButton = <span><NavLink className="button outdoors" to={targetURL}>{targetText}</NavLink></span>;
    return backButton;
  }

  renderHistory(){
    var outputHTML = []
    var i = 0
    var primaryURL = null
    var secondaryURL = null
    var tertiaryURL = null
    var profileURL = null
    var placeURL = null
    var storyTime = null
    var itemInfo = this.state.itemInfo

    if (this.state.story != null) {

    this.state.story.map ( (story, key) => {
      i++
      if (story.primary_item) {
        primaryURL="/tools/item/history/"+story.primary_item.item_id
      }
      if (story.secondary_item) {
        secondaryURL="/tools/item/history/"+story.secondary_item.item_id
      }
      if (story.tertiary_item) {
        tertiaryURL="/tools/item/history/"+story.tertiary_item.item_id
      }
      if (story.user) {
        profileURL="/tools/profile/"+story.user.id
      }
      if (story.place) {
        placeURL = "/tools/place/"+story.place.id
      }

      storyTime = (new Date(story.created_at.replace(/-/g, "/")+" UTC"));
      switch (story.type) {
        case "itemvault":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Saved
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <img src={story.primary_item.set_image}  height="64" width="64" alt={story.primary_item.set_name} />
                </div>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemunvault":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64"  alt={story.user.name}/></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <img src={story.primary_item.set_image}  height="64" width="64" alt={story.primary_item.set_name} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Moved
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                  #{story.primary_item.number}
                </div>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemswappedvault":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64"  alt={story.user.name}/></NavLink><br/>
                  <TimeAgo date={storyTime}/>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64"  alt={story.primary_item.name}/></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Swapped
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <NavLink to={secondaryURL}><img src={story.secondary_item.image_url}  height="64" width="64"  alt={story.secondary_item.name}/></NavLink><br/>
                  #{story.secondary_item.number}
                </div>
              </div>

            </div>
          </div>
        )
        case "itemswappedplace":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Swapped
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={secondaryURL}><img src={story.secondary_item.image_url}  height="64" width="64" alt={story.secondary_item.name} /></NavLink><br/>
                #{story.secondary_item.number}
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemtraded":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="70" width="70" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">

                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Traded
                </p>

              </div>

              <div className="level-item has-text-centered">

                <NavLink to={secondaryURL}><img src={story.secondary_item.image_url}  height="64" width="64" alt={story.secondary_item.name} /></NavLink><br/>
                #{story.secondary_item.number}

                </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itempickedup":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={placeURL}><img src={story.place.image_url} height="64" width="64" alt={story.place.name} /></NavLink>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Picked Up
                  </p>
                </div>
              </div>

                <div className="level-item has-text-centered">
                  <div>
                    <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                    #{story.primary_item.number}
                  </div>
                </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemfound":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={placeURL}><img src={story.place.image_url} height="64" width="64" alt={story.place.name} /></NavLink>
              </div>

              <div className="level-item has-text-centered">
                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Found
                </p>
              </div>

                <div className="level-item has-text-centered">
                  <div>
                    <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                    #{story.primary_item.number}
                  </div>
                </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "mysteryboxopened":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>


              <div className="level-item has-text-centered">
                <p className="title is-6" >
                  <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                  Mystery Box Opened
                </p>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "chestopened":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>


              <div className="level-item has-text-centered">
                <p className="title is-6" >
                  <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                  Chest Opened
                </p>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemgifted":
        var tempuser = null
        var tempname = null
        var Gifter = null
        var GifterURL = null
        if (story.gift_user) {
          tempuser=story.gift_user.image_url
          tempname=story.gift_user.username
          GifterURL="/tools/profile/"+story.gift_user.user_id
          Gifter=<div className="level-item has-text-centered"><NavLink to={GifterURL}><img src={tempuser} height="64" width="64"  alt={tempname}/></NavLink><br/>{tempname}</div>
        } else {
          tempuser="https://wallazee.global.ssl.fastly.net/images/site/logo.png"
          tempname=""
          Gifter=<div className="level-item has-text-centered"><img src={tempuser} height="64" width="64"  alt={tempname}/><br/>{tempname}</div>
        }
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              {Gifter}

              <div className="level-item has-text-centered">

                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Gifted
                </p>

              </div>


              <div className="level-item has-text-centered">

                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}

              </div>


            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "grabbagopened":


        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

                <div className="level-item has-text-centered"><NavLink to={secondaryURL}><img src={story.secondary_item.image_url} height="64" width="64" alt={story.secondary_item.name} /></NavLink><br/>{story.secondary_item.name}</div>

              <div className="level-item has-text-centered">

                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Opened
                </p>

              </div>


              <div className="level-item has-text-centered">

                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}

              </div>


            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable

        case "itemwalladrone":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div><img src="https://wallazee.global.ssl.fastly.net/images/variant/20171010-630cf53590204bc4fe637fbee34612a4b1ae24624e47e4ce35ad6b0-100.png" height="64" width="64" alt="WallaDrone" /><br/>WallaDrone</div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Returned
                  </p>
                </div>
              </div>

              <div className="level-right">
                <div className="level-item has-text-centered">
                  <div>
                    <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                    #{story.primary_item.number}
                  </div>
                  </div>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemunlockboxed":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div><img src="https://wallazee.global.ssl.fastly.net/images/site/lockbox.png" height="64" width="64" alt="Lock Box" /><br/>Lock Box</div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Moved
                  </p>
                </div>
              </div>

              <div className="level-right">
                <div className="level-item has-text-centered">
                  <div>
                    <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                    #{story.primary_item.number}
                  </div>
                  </div>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemlockboxed":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
              <div>
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Stashed
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div className="level-item has-text-centered">
                  <img src="https://wallazee.global.ssl.fastly.net/images/site/lockbox.png" height="64" width="64" alt="Lock Box" /><br/>Lock Box
                </div>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemdropped":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Dropped
                  </p>
                </div>
              </div>

                <div className="level-item has-text-centered">
                  <div>
                    <NavLink to={placeURL}><img src={story.place.image_url} height="64" width="64" alt={story.place.name} /></NavLink>
                  </div>
                </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itempurchased":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <img src="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png" height="64" width="64" alt="Honeycomb" />
              </div>

              <div className="level-item has-text-centered">

                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Purchased
                </p>

              </div>

              <div className="level-item has-text-centered">
                <div>
                  <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                  #{story.primary_item.number}
                </div>
                </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "marketpurchase":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>


              <div className="level-item has-text-centered">
                <img src="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png" height="64" width="64" alt="Honeycomb" />
              </div>

              <div className="level-item has-text-centered">
                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Purchased
                </p>
              </div>


              <div className="level-item has-text-centered">
                <div>
                  <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                  #{story.primary_item.number}
                </div>
                </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "marketsold":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Sold
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <img src="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png" height="64" width="64" alt="Honeycomb" />
                </div>
              </div>

            </div>
          </div>
        )
        break; // eslint-disable-line no-unreachable
        case "itemrecycled":
        return outputHTML.push(
          <div className="panel-block">
            <div key={i} className="level is-mobile">
              <div className="level-left">
                <div className="level-item has-text-centered">
                  <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                  <TimeAgo date={storyTime} />
                </div>
              </div>

              <div className="level-item has-text-centered">
                <NavLink to={primaryURL}><img src={story.primary_item.image_url} height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                #{story.primary_item.number}
              </div>

              <div className="level-item has-text-centered">
                <div>
                  <p className="title is-6" >
                    <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                    Recycled
                  </p>
                </div>
              </div>

              <div className="level-item has-text-centered">
                <img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/0-100.png" height="64" width="64" alt="Question Mark" />
              </div>

            </div>
          </div>
            )
            break; // eslint-disable-line no-unreachable
            case "itemcreated":
            return outputHTML.push(
              <div className="panel-block">
                <div key={i} className="level is-mobile">
                  <div className="level-left">
                    <div className="level-item has-text-centered">
                      <NavLink to={profileURL}><img src={story.user.image_url} height="64" width="64" alt={story.user.name} /></NavLink><br/>
                      <TimeAgo date={storyTime} />
                    </div>
                  </div>

                  <div className="level-item has-text-centered">
                    <NavLink to={secondaryURL}><img src={story.secondary_item.image_url} height="64" width="64" alt={story.secondary_item.name} /></NavLink><br/>
                    #{story.secondary_item.number}
                  </div>
                  <i className="fa fa-plus fa-1x" aria-hidden="true"></i><br/>



                  <div className="level-item has-text-centered">
                    <NavLink to={tertiaryURL}><img src={story.tertiary_item.image_url}  height="64" width="64" alt={story.tertiary_item.name} /></NavLink><br/>
                    #{story.tertiary_item.number}
                  </div>
                  <i className="fa fa-chevron-right fa-1x" aria-hidden="true"></i><br/>



                  <div className="level-item has-text-centered">
                    <NavLink to={primaryURL}><img src={story.primary_item.image_url}  height="64" width="64" alt={story.primary_item.name} /></NavLink><br/>
                    #{story.primary_item.number}
                  </div>

                </div>
              </div>
        )
        break; // eslint-disable-line no-unreachable
        default:
          return null;
          break; // eslint-disable-line no-unreachable
      }
    })
} else {
      return (
        <div className="panel-block">
          <div key={i} className="level is-mobile">
            <div className="level-left">
              <div className="level-item has-text-centered">
                <NavLink to='/tools/profile/16435'><img src='https://wallazee.global.ssl.fastly.net/images/users/13338-30a72428-6cbc-41cd-ab03-2d0ee0b9cb2c-200.jpg' height="64" width="64" alt='WallaAdmin' /></NavLink><br/>
                <TimeAgo date={itemInfo.created_at} />
              </div>
            </div>

            <div className="level-item has-text-centered">
              <NavLink to='/tools/item/{itemInfo.item_type_id}'><img src={itemInfo.image_url_200} height="64" width="64" alt={itemInfo.name} /></NavLink><br/>
              #{itemInfo.number}
            </div>

            <div className="level-item has-text-centered">
              <div>
                <p className="title is-6" >
                  <i className="fa fa-arrow-right fa-2x" aria-hidden="true"></i><br/>
                  Recycled
                </p>
              </div>
            </div>

            <div className="level-item has-text-centered">
              <img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/0-100.png" height="64" width="64" alt="Question Mark" />
            </div>

          </div>
        </div>
          )
    }

    return outputHTML;

  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.itemId != this.props.match.params.itemId) {
      var nextItem = nextProps.match.params.itemId
      this.setState({story:[],itemInfo:null,foundStory: false, foundItem: false});
      this.fetchHistory(nextItem);
      this.fetchItem(nextItem);
    }
  }


  componentWillMount() {
    var itemID = this.props.match.params.itemId
    this.fetchHistory(itemID);
    this.fetchItem(itemID);
  }

  render()  {
    var pageTitle = "WallaBee | WallaBee: Collecting and Trading Card Game on iOS";

    if ((this.state.foundItem==true)&&(this.state.foundStory==true)) {
      pageTitle=this.state.itemInfo.name + " - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
      var altTag = this.state.itemInfo.name;
      var apiImage=this.state.itemInfo.image_url_200

      var errorText=null;

      return <div className="container" id="itemHistoryPage">

        <MetaTags>
          <title>{pageTitle}</title>
        </MetaTags>

        <div className="text-center">
          <div className="card">
            <div className="card-image">
              <figure>
                <Link to={'/tools/item/' + this.state.itemInfo.item_type_id}>
                  <img src={apiImage} alt={altTag}/>
                </Link>
              </figure>
            </div>
            <h1>{this.state.itemInfo.name} #{this.state.itemInfo.number}</h1>
            {errorText}

            <div className="panel">

              {this.renderHistory()}

            </div>

          </div>
      {this.renderBackToItem()}
      </div>
      </div>


    } else {

      pageTitle="Loading Page... - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
      return <center>
      <MetaTags>
      <title>{pageTitle}</title>
      </MetaTags>
      <br/><br/><br/><br/><br/><h1>Loading...</h1><br/><br/><br/><br/></center>
    }
  }
}
