import React from 'react';
import { NavLink } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import MetaTags from 'react-meta-tags'
import 'whatwg-fetch';

export default class PlayerWishlistMenu extends React.Component{
  constructor(){
    super()
    this.state = {targetID: null, targetWish: null}
  }

  createWishList(event){

    if (event.target.value) {
      var targetWish = event.target.value
    } else {
      var targetWish = this.state.targetWish
    }
    if (targetWish != null) {
      var targetURL = "/tools/profile/"+this.state.targetID+"/wishlist/"+targetWish
      this.props.history.push(targetURL);
    }
  }


  componentDidMount() {
    var playerID = this.props.match.params.playerID
    this.setState({
      targetID: playerID
    })
  }

  render() {

  return (
    <div className="container" id="PlayerWishlist">
      <ul className="panel">
        <strong className="panel-heading text-center">What's a Wishlist?</strong>
        <li className="panel-block">
        Wishlists are automatic item catalogs created using specific filters. There are specific filters we have set up for your convenience, whether you're looking to finish sets, collect more uniques, or hunt down those last remaining number patterns. For example, if your goal is to fill your sets with items under #5000, simply select that filter and copy the URL. You can now easily share a list of items you're looking for without keeping track of them manually, making requesting items from other players quick and efficient. Wishlists stay updated to your account, so every time another player follows your URL, they see the current items you're looking for.</li>
      </ul>
      <ul className="panel">
        <strong className="panel-heading text-center">Defined Wish Lists</strong>
        <li className="panel-block">

        <form>Choose a Wishlist <select onChange={this.createWishList.bind(this)}>
          <option></option>
          <option value="M">Missing Items</option>
          <option value="U">Missing Unique Items</option>
          <option value="P">Missing Palindrome Items</option>
          <option value="9999">Missing Sub-10000 Items</option>
          <option value="7499">Missing Sub-7500 Items</option>
          <option value="4999">Missing Sub-5000 Items</option>
          <option value="2499">Missing Sub-2500 Items</option>
          <option value="1499">Missing Sub-1500 Items</option>
          <option value="999">Missing Triple Digit Items</option>
          <option value="499">Missing Sub-500 Items</option>
          <option value="199">Missing Sub-200 Items</option>
          <option value="99">Missing Double Digit Items</option>
          <option value="49">Missing Sub-50 Items</option>
          <option value="39">Missing Sub-40 Items</option>
          <option value="29">Missing Sub-30 Items</option>
        </select>
        </form>
        </li>
      </ul>
      <ul className="panel">
        <strong className="panel-heading text-center">Custom Wish List</strong>
        <li className="panel-block">
        <form>
        Enter Highest Number Collected (ie: if sub5000 enter 4999 in box)
        <input type="text" size="5" onChange={(e) => this.setState({ targetWish: e.target.value})}/>
        <input type="submit" onClick={this.createWishList.bind(this)}/>
        </form>
        </li>
      </ul>

    </div>
)
    }
}
