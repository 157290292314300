import React from 'react'
import Panel from '../../../components/panel'

    var placeArray =  [
  {
      'name': 'COURTHOUSE',
      'desc': ['Courthouses that are still in use','NOT: Historical court houses (historic structure)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/43-51e53b25-2074-403c-8990-1c17c63dd4aa-50.png',
      'id': 1
  },
  {
      'name':'FINANCE',
      'desc': ['Banks','Mortgage companies','Payday loan establishments','NOT: ATMs'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/39-51e53ac5-4d2c-4e91-aaa0-1360c63dd4aa-50.png',
      'id': 2
  },
  {
      'name':'FIRE',
      'desc': ['Fire departments','Volunteer fire department office','Fire training facilities'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/41-51e53af0-b7a4-4e1c-ab22-1a34c63dd4aa-50.png',
      'id': 3
  },
  {
      'name':'GOVERNMENT',
      'desc': ['Government offices, including motor vehicle departments (e.g., DMV)','Embassies','Prisons','Water treatment plants','Highway weigh stations','Border control','Military bases (note: individual locations within base conform to standard categories)','Military offices, including recruitment offices','NOT: Anything critical to national security'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/72-51e6f29c-0084-4452-ad1a-45cac63dd4aa-50.png',
      'id': 4
  },
  {
      'name':'HOTEL',
      'desc': ['Hotels/Hostels','Resorts','Bed and Breakfasts'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160808-c8c1428612a22480bab48e6132e54d239c2965c7938e21aff1a17eb-50.png',
      'id': 5
  },
  {
      'name':'MEDICAL',
      'desc': ['Hospitals','Doctors (e.g., general practice, specialists, ophthalmology, dentists, etc.)','Chiropractors, Physiotherapy clinics','NOT: Massage (service/other)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/37-51e53aa0-ed2c-46ba-b5d2-1e84c63dd4aa-50.png',
      'id': 6
  },
  {
      'name':'OFFICE',
      'desc': ['Radio/TV stations','Office towers','Other, where obvious','NOT: Insurance (service/other)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/44-51e53b59-1a04-4855-89d2-1c17c63dd4aa-50.png',
      'id': 7
  },
  {
      'name':'OTHER',
      'desc': ['Car wash','Airport lounges','Lawyers','Self-storage','Factories','Insurance agencies','Large water towers'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/46-51e53b80-cb50-49f6-ac4f-1c17c63dd4aa-50.png',
      'id': 8
  },
  {
      'name':'POLICE',
      'desc': ['Police stations'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/42-51e53b04-ae18-4781-a4c8-1bb0c63dd4aa-50.png',
      'id': 9
  },
  {
      'name':'POST OFFICE',
      'desc': ['Post offices','Courier, shipping, or packaging companies (e.g., FedEx, DHL, UPS)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/40-51e53adf-0404-495a-8c41-1c51c63dd4aa-50.png',
      'id': 10
  },
  {
      'name':'SERVICE STATION',
      'desc': ['Fuel stations','Vehicle mechanics','NOT: Car wash (service/other), vehicle rental (travel/other), vehicle dealerships (shopping/other)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/45-51e53b68-8b08-432b-a9a9-1f04c63dd4aa-50.png',
      'id': 11
  },
  {
      'name':'SALON/BARBER',
      'desc': ['Hair Salons','Barbers', '"SuperCuts" type places','NOT: Animal Groomers'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20170505-6c4737c1d211bba57ce34ce7049d53bad4e58efea13605592f6cf49-50.png',
      'id': 12
  },{
      'name':'VETERINARY CLINC',
      'desc': ['Veterinary Clinics','Animal Hospitals','Veterinarians'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/20180308-5d4282d21b4585fb25b48ab3e622f241b190a9f69b50ed4077ffba2-50.png',
      'id': 13

  }
  ]

export default class PlaceService extends React.Component {
      render(){
        const places = placeArray.map((place,i) => {
          return <Panel key={i} {...place}/>
        })

    return (
      <div>
        <div className="text-center">
          <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/36-51e53a8f-52a8-412c-b01e-1a34c63dd4aa-200.png" alt="Services"/>
          <h2>Services</h2>
        </div>

        {places}

      </div>
    )
  }
}
