import React from 'react'
import Panel from '../../../components/panel'

    var placeArray = [
  {
      'name': 'APPLE STORE',
      'desc': ['Exclusively Apple Stores'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-5ae08c6c0e2f5ab180af0e8487a3e4bf0d116276126e1f5367da9a4-50.png',
      'id': 1
  },
  {
      'name':'CLOTHING',
      'desc': ['Primarily clothing stores','Shoe/boot stores','Hat stores','Accessory stores'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-2311c656974b1224d68d0b138c8576f438e88738cb0dce5176cac1b-50.png',
      'id': 2
  },
  {
      'name':'FOOD & DRINK STORE',
      'desc': ['Grocery stores','Farmer’s markets with a fixed structure','Liquor stores','Convenience stores (e.g., 7-Eleven), including at gas stations when separate'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-6b58541f47513a57117fce99d6164037409f3730a366fcef39e2f5f-50.png',
      'id': 3
  },
  {
      'name':'MALL',
      'desc': ['Primarily closed-in malls and large-scale outlet malls','NOT: Plazas/strip malls, airport malls, outdoor collections of shops'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-5bd08aa2a9ea47d5595b2a00f6cdbe914b0e4b01d1d19ed47706276-50.png',
      'id': 4
  },
  {
      'name':'MUSIC/VIDEO',
      'desc': ['Music stores, including record stores','Musical instrument stores','Video purchase/rental stores','NOT: Redbox locations, Blockbuster'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-b9ff6b38c771838d414521f3a9341a92c4aa7cb2f76ab99ef100301-50.png',
      'id': 5
  },
  {
      'name':'OTHER',
      'desc': ['Car/truck/boat/motorcycle dealerships','Tire/auto parts stores','Book stores','Any other store not indicated in another sub-category'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-1aa965426d2355b0c261cc1eaea2919fe20a77aeafbcb5799ceaf18-50.png',
      'id': 6
  },
  {
      'name':'PET STORE',
      'desc': ['Stores for purchasing animals and accessories'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-720f5963fb93ccf7a16d111abcfba31b43df7fce82bfdd2c2c34b29-50.png',
      'id': 7
  },
  {
      'name':'PHARMACY',
      'desc': ['Exclusively pharmacies (RiteAid, CVS, Walgreen&#146;s, etc)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-2b985f2d7bb6f9173a1f153666c74599de05b49bf6a578814afebde-50.png',
      'id': 8
  },
  {
      'name':'SPORTS STORE',
      'desc': ['Athletic equipment stores (e.g., outdoors, hunting, fishing, ski stores, etc.)','Athletic shoe stores','Bicycle stores'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-6ddaf7a1ae360a8afb475af0eb019ca0a6e0e0c486db36c96c172cf-50.png',
      'id': 9
  },
  {
      'name':'TECHNOLOGY',
      'desc': ['Electronics retailers (e.g., Best Buy)','Cellular stores (e.g., Verizon, O2, etc.)','Photography shops'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-bfb5fa048a36bb3b49846902eb6cd5bbbec3856e3bca35d8399806e-50.png',
      'id': 10
  },
  {
      'name':'TOYS & GAMES',
      'desc': ['Toy stores (e.g., Toys R Us)','Gaming stores'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-f9df90bffa897465e9bb86a115325e6d220a55049860a8b9231e27b-50.png',
      'id': 11
  }
  ]

export default class PlaceShopping extends React.Component {
  render(){
    const places = placeArray.map((place,i) => {
      return <Panel key={i} {...place}/>
    })

    return (
      <div>
        <div className="text-center">
          <img src="https://wallazee.global.ssl.fastly.net/images/variant/20160721-1aa965426d2355b0c261cc1eaea2919fe20a77aeafbcb5799ceaf18-200.png" alt="Shopping Places"/>
          <h2>Shopping</h2>
        </div>
        {places}
      </div>
    )
  }
}
