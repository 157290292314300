const utilities = {

  apiKey:'25bc1362-2761-11e7-93ae-92361f002671',

  mixExceptions: [0,2409,2406,2403,1919,2722,2723,2212,2212,3350,3835,4011,4012,4013,6028,6029,5751,5752,5748,5752,5748,5751,5748,6030,6031,6032,6033,6027,6027,6027,6027,3820,3820,3820,3820,3820,3820,3820,3820,3820,3820,3820,3820,1060,1060,1060,1060,1060,1060,1060,1060,1060,1060,1060,1060,6369,6369,6369,6369,6369],

  formulaExceptions:[[0,0],[2414,2415],[2414,2415],[2414,2415],[1914,1917],[1971,2720],[1971,2720],[2202,2209],[2202,2210],[3348,3348],[3832,3833],[4034,4034],[4034,4034],[4034,4034],[6017,6020],[6017,6020],[5738,5747],[5738,5747],[5738,5747],[5739,5747],[5739,5747],[5740,5747],[5740,5747],[6017,6021],[6017,6022],[6017,6023],[6017,6024],[6017,6021],[6017,6022],[6017,6023],[6017,6024],[1324,2611],[1324,2609],[1324,2610],[1324,2608],[1324,2612],[1324,2613],[1324,2614],[1324,2615],[1324,2616],[1324,2617],[1324,2618],[1324,2620],[1324,2611],[1324,2609],[1324,2610],[1324,2608],[1324,2612],[1324,2613],[1324,2614],[1324,2615],[1324,2616],[1324,2617],[1324,2618],[1324,2620],[6351,6361,6362],[6351,6353,6360,6364],[6352,6359,6363],[6352,6354,6358,6364],[6355,6365,6366]],

  usedExceptions: [0,2131,2134,2414,2415,1914,1917,724,1055,2089,2090,2091,1971,2720,2202,2209,2210,3348,6017,6020,5738,5739,5740,5747,6021,6022,6023,6024,1324,2611,2609,2610,2608,2612,2613,2614,2615,2616,2617,2618,2620,6351,6361,6362,6352,6353,6354,6355,6358,6359,6360,6363,6364,6365,6366],

  usedTargetExceptions: [[0],[2135],[2135],[2409,2406,2403],[2409,2406,2403],[1919],[1919],[0],[0],[0],[0],[0],[2722,2723],[2722,2723],[2212],[2212],[2212],[3349,3350],[6027,6028,6029,6030,6031,6032,6033],[6028,6029],[5748,5751,5752],[5748,5752],[5751],[5751,5752],[6027,6030],[6027,6031],[6027,6032],[6027,6033],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[1060,3820],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369],[6369]],

  mixerExceptions: [0,724,1055,2089,2090,2091],

  SpecialExceptions: [0,1172,1173],

  metaTitle: ' | WallaBee: Collecting and Trading Card Game on iOS & Android'

}

export default utilities
