import React from 'react'

const placeArray = [
  {
      'name': 'HIGHER EDUCATION',
      'desc': ['College/University, including separate buildings (incl. teaching/research buildings)',
        'NOT: Dormitories, Fraternities, Sororities, Individual classrooms']
      ,
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/4-51e530bf-7a24-43af-8067-02f0c63dd4aa-50.png',
      'id': 1
  },
  {
      'name':'HISTORIC STRUCTURE/SITE',
      'desc': ['Historically relevant structures','Notable battlefields and historically relevant cemeteries/burial grounds', 'NOT: Locations where structures used to exist, but have since been demolished; structures/homes simply possessing heritage designation'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160302-74db191343cc33c1c0fc31226eafae40668c913fd502418495a52d2-50.png',
      'id': 2
  },
  {
      'name':'LIBRARY',
      'desc': ['City/town libraries','University/College libraries','NOT: Book stores (shopping/other)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/5-51e530f2-faa0-4147-8086-039ec63dd4aa-50.png',
      'id': 3
  },
  {
      'name':'MUSEUM/GALLERY',
      'desc': ['Formal museums or galleries with exhibits','Historical homes, villages, farms, etc. treated as museums','Historical ships, etc. treated as museums','NOT: painted murals, art stores (shopping/other), auctioneers (shopping/other)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/6-51e53104-d96c-47ea-82ab-039cc63dd4aa-50.png',
      'id': 4
  },
  {
      'name':'EDUCATION OTHER',
      'desc': ['Language and music classes','Trade schools','Test preparation and tutors','Driving schools','KUMON and similar childhood classes','Established, formal dance schools (Recreation/Other recreational dance studios)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/1-51e52ebf-f27c-47b8-812a-7c00c63dd4aa-50.png?time=1456945160',
      'id': 5
  },
  {
      'name':'PRIMARY EDUCATION',
      'desc': ['Daycare','Nursery School','Elementary School (to grade eight in N/A; approximately 13 y/o)','Middle School (grades 6-8 in some areas of N/A; to approximately 13 y/o)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/2-51e53062-95b4-40de-bea3-0288c63dd4aa-50.png',
      'id': 6
  },
  {
      'name':'SECONDARY EDUCATION',
      'desc': ['High School in N/A, prior to College/University (to approximately 17-18 y/o)','NOT: Individual classrooms'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/3-51e530a7-08b8-476d-9587-7f9ac63dd4aa-50.png',
      'id': 7
  }
]

const PanelItem = (props) => {


  const descriptions = props.desc.map(( desc, i ) => {
    return <li className="panel-block" key={i}>{desc}</li>
  })

  return (
      <ul className="panel">
        <div className="panel-heading">
          <img src={props.img} alt={props.name}/>
          <span>{props.name}</span>
        </div>
        {descriptions}
      </ul>
  )
}

 export default class PlaceSubCategories extends React.Component{
   render() {
     const places = placeArray.map((place,i) => {
       return <PanelItem key={i} {...place}/>
     })

     return(
       <div>
         <div className="text-center">
           <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/1-51e52ec0-1074-4296-ba9f-7c00c63dd4aa-200.png" alt="Educational Places"/>
           <h2>Educational</h2>
         </div>
         {places}
       </div>
     )
   }
 }
