import React, { Component } from 'react'
// NavLink,
import { withRouter } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import LatestItems from './latest-items'
import News from './news'
// import HomeBanner from './home-banner'
// import Stats from './stats'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    login: state.login,
    profile: state.profile
  }
}

//      <Stats/>
class Home extends Component {

  render() {

    return <div id="homepage">

      <MetaTags>
        <title>WallaBee: Collecting and Trading Card Game on iOS & Android</title>
        <link rel="apple-touch-icon" sizes="144x144" href="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png"/>
        <link rel="apple-touch-icon" href="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png"/>
        <link rel="icon" href="https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png"/>
      </MetaTags>
      <section id="banner">

        <div id="banner-bg">
        <img id="banner-space" src="https://wallazee.global.ssl.fastly.net/images/site/night-sky.png" alt="Space"/><br/>
        </div>

        <img id="banner-space" src="https://wallazee.global.ssl.fastly.net/images/site/banner_space.png" alt="WallaBee Space Logo"/><br/>
        <div className="level-item" id="banner-content">
          <div>
            <p>WallaBee is 50% Collecting Game, 50% GeoLocation, and 100% Community.
            Play WallaBee completely free of charge and all of our items are available to everyone from the moment they are released.</p>
            <a href="https://itunes.apple.com/us/app/wallabee-collecting-trading/id1155134198?mt=8" target="_blank" rel="noopener noreferrer"><img src="https://wallazee.global.ssl.fastly.net/images/site/appstorebadge.svg" alt="App Store"/></a>
            <a href="https://play.google.com/store/apps/details?id=com.wallabee&hl=en" target="_blank" rel="noopener noreferrer"><img className="google-play" src="https://wallazee.global.ssl.fastly.net/images/site/badge_googleplay.png" alt="Google Play Store"/></a>
          </div>

        </div>

      </section>

      <News/>

      <LatestItems/>
      <ReactTooltip effect="solid" type="light"/>

    </div>
  }
}

export default withRouter(connect(mapStateToProps)(Home))

//           <HomeBanner />
