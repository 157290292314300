const containers={
  "containers": [
    {
      "item_type_id": "3181",
      "set_id": "165",
      "set_name": "Our 6th Birthday",
      "contents": [ "3175", "3176", "3177", "3178", "3179", "3180" ]
    },
    {
      "item_type_id": "1286",
      "set_id": "10001",
      "set_name": "Special Balloon",
      "contents": [ "1287" ]
    },
    {
      "item_type_id": "50",
      "set_id": "10002",
      "set_name": "Locker",
      "contents": [ "45","51","55","646","650","651","657" ]
    },
    {
      "item_type_id": "227",
      "set_id": "10004",
      "set_name": "Chocolate Bar",
      "contents": [ "237","1099" ]
    },
    {
      "item_type_id": "433",
      "set_id": "10005",
      "set_name": "Candy Bags",
      "contents": [ "227","236" ]
    },
    {
      "item_type_id": "583",
      "set_id": "10006",
      "set_name": "Backpack",
      "contents": [ "573","574","575","578","579","580","581","582","585","586" ]
    },
      {
      "item_type_id": "706",
      "set_id": "10007",
      "set_name": "Lamp",
      "contents": [ "707" ]
    },
    {
      "item_type_id": "862",
      "set_id": "10008",
      "set_name": "Safe",
      "contents": [ "366","860","863","864" ]
    },
    {
      "item_type_id": "1480",
      "set_id": "10009",
      "set_name": "Beer Cooler",
      "contents": [ "66","80","100","323","357","773","1121","1130","1395","1478","1746","2338" ]
    },
    {
      "item_type_id": "1648",
      "set_id": "10010",
      "set_name": "Job Lot",
      "contents": [ "26","63","72","85","147","191","414" ]
    },
    {
      "item_type_id": "1745",
      "set_id": "10011",
      "set_name": "Pot of Gold",
      "contents": [ "824","936","1872" ]
    },
    {
      "item_type_id": "2140",
      "set_id": "10012",
      "set_name": "Magic Portal",
      "contents": [ "2141","2142","2143","2144","2145","2146","2147","2148","2149" ]
    },
    {
      "item_type_id": "2545",
      "set_id": "10013",
      "set_name": "Pouch of Plenty",
      "contents": [ "1039" ]
    },
	  {
      "item_type_id": "2194",
      "set_id": "10003",
      "set_name": "Wishing Well",
      "contents": [ "2091","2090","2089","553","3232","3235","3238","2389","3244","3250","3348"]
    },
    {
      "item_type_id": "2724",
      "set_id": "2",
      "set_name": "A Trip To The Circus",
      "contents": [ "20", "13", "29", "27", "31", "22", "21", "19", "18", "17", "15", "12", "2725"]
    },
    {
      "item_type_id": "2726",
      "set_id": "4",
      "set_name": "Fast Times at WallaBee High School",
      "contents": [ "50", "39", "36", "45", "51", "56", "55", "53", "35", "54", "37", "52", "2727" ]
    },
    {
      "item_type_id": "2728",
      "set_id": "7",
      "set_name": "Date Night",
      "contents": [ "68", "67", "66", "65", "64", "63", "62", "61", "60", "59", "58", "2729" ]
    },
    {
      "item_type_id": "2730",
      "set_id": "3",
      "set_name": "A Night In The Forest",
      "contents": [ "34", "32", "28", "33", "30", "26", "25", "24", "23", "2731" ]
    },
    {
      "item_type_id": "2732",
      "set_id": "8",
      "set_name": "Jurassic Parts",
      "contents": [ "73", "74", "77", "70", "72", "71", "76", "78", "69", "79", "75", "2733" ]
    },
    {
      "item_type_id": "2734",
      "set_id": "5",
      "set_name": "The Final Frontier",
      "contents": [  "46", "47", "38", "41", "42", "44", "43", "49", "40", "48", "2735" ]
    },
    {
      "item_type_id": "2736",
      "set_id": "9",
      "set_name": "License to Forage",
      "contents": [  "88", "86", "83", "82", "85", "80", "84", "81", "87", "89", "90", "91", "2737" ]
    },
    {
      "item_type_id": "2738",
      "set_id": "10",
      "set_name": "A Pirate's Life For Me",
      "contents": [  "98", "101", "95", "99", "97", "96", "100", "94", "92", "93", "102", "2739" ]
    },
    {
      "item_type_id": "2740",
      "set_id": "14",
      "set_name": "When Nature Calls",
      "contents": [ "133","138","132","141","131","127","128","135","126","139","144","140","134","136","137","130","129","143","142", "2741"]
    },
    {
      "item_type_id": "2742",
      "set_id": "15",
      "set_name": "Why So Serious?",
      "contents": [ "117","118","119","120","121","122","2743" ]
    },
    {
      "item_type_id": "2744",
      "set_id": "12",
      "set_name": "Take Me Out To The Ball Game",
      "contents": [ "106","116","109","105","107","108","115","111","113","114","112","110", "2745"]
    },
    {
      "item_type_id": "2746",
      "set_id": "17",
      "set_name": "Eggception",
      "contents": [ "159","165","161","169","164","163","162","170","167","166","160","168","171","172", "2747"]
    },
    {
      "item_type_id": "2748",
      "set_id": "18",
      "set_name": "Shake, Rattle and Rollerskates",
      "contents": [ "181","177","175","185","173","186","176","182","178","179","183","184","180","174","2749"]
    },
    {
      "item_type_id": "2750",
      "set_id": "16",
      "set_name": "Life's a Beach",
      "contents": [ "156","146","145","150","155","147","151","148","153","149","187","154","157","158","152", "2751"]
    },
    {
      "item_type_id": "2752",
      "set_id": "19",
      "set_name": "Foragers Of A Lost Art",
      "contents": [ "191","189","197","190","200","193","201","194","196","198","192","188","199","202","195", "2753"]
    },

    {
      "item_type_id": "2754",
      "set_id": "19",
      "set_name": "Keep Calm and Reign On",
      "contents": [ "241","242","243","244","245","246","247","248","249","2755"]
    },
	  {
      "item_type_id": "2389",
      "set_id": "13",
      "set_name": "WallaGraphics",
      "contents": [ "2725","2727","2729","2731","2733","2735","2737","2739","2741","2743","2745","2747","2749","2751","2753"]
    },
    {
      "item_type_id": "4001",
      "set_id": "182",
      "set_name": "Pouch Creatures 2",
      "contents": [ "4002", "4005", "4008" ]
    },
    {
      "item_type_id": "4002",
      "set_id": "182",
      "set_name": "Pouch Creatures 2",
      "contents": [ "4003" ]
    },
    {
      "item_type_id": "4005",
      "set_id": "182",
      "set_name": "Pouch Creatures 2",
      "contents": [ "4006" ]
    },
    {
      "item_type_id": "4008",
      "set_id": "182",
      "set_name": "Pouch Creatures 2",
      "contents": [ "4009" ]
    },
    {
      "item_type_id": "6039",
      "set_id": "201",
      "set_name": "The Rainforest",
      "contents": [ "6041" ]
    },
    {
      "item_type_id": "5963",
      "set_id": "197",
      "set_name": "A Seasonal Mission",
      "contents": [ "5969" ]
    },
    {
      "item_type_id": "5964",
      "set_id": "197",
      "set_name": "A Seasonal Mission",
      "contents": [ "5970" ]
    },
    {
      "item_type_id": "5966",
      "set_id": "197",
      "set_name": "A Seasonal Mission",
      "contents": [ "5972" ]
    },
    {
      "item_type_id": "5917",
      "set_id": "195",
      "set_name": "A Halloween Mystery",
      "contents": [ "5918" ]
    },
    {
      "item_type_id": "5781",
      "set_id": "192",
      "set_name": "Soap Box Derby",
      "contents": [ "5782" ]
    },
    {
      "item_type_id": "5795",
      "set_id": "193",
      "set_name": "Picnic In The Park",
      "contents": [ "5797","5798" ]
    },
    {
      "item_type_id": "5767",
      "set_id": "191",
      "set_name": "Nesting Dolls",
      "contents": [ "5766" ]
    },
    {
      "item_type_id": "5766",
      "set_id": "191",
      "set_name": "Nesting Dolls",
      "contents": [ "5765" ]
    },
    {
      "item_type_id": "5765",
      "set_id": "191",
      "set_name": "Nesting Dolls",
      "contents": [ "5764" ]
    },
    {
      "item_type_id": "5764",
      "set_id": "191",
      "set_name": "Nesting Dolls",
      "contents": [ "5763" ]
    },
    {
      "item_type_id": "5763",
      "set_id": "191",
      "set_name": "Nesting Dolls",
      "contents": [ "5762" ]
    },
    {
      "item_type_id": "5762",
      "set_id": "191",
      "set_name": "Nesting Dolls",
      "contents": [ "5761" ]
    },
    {
      "item_type_id": "5737",
      "set_id": "190",
      "set_name": "Bowl-O-Walla",
      "contents": [ "5741","5742","5743" ]
    },
    {
      "item_type_id": "5744",
      "set_id": "190",
      "set_name": "Bowl-O-Walla",
      "contents": [ "5745" ]
    },
    {
      "item_type_id": "5750",
      "set_id": "190",
      "set_name": "Bowl-O-Walla",
      "contents": [ "5749","5751" ]
    },
    {
      "item_type_id": "5056",
      "set_id": "189",
      "set_name": "Knitting Knightmare",
      "contents": [ "5033" ]
    },
    {
      "item_type_id": "5056",
      "set_id": "189",
      "set_name": "Knitting Knightmare",
      "contents": [ "5033","5058" ]
    },
    {
      "item_type_id": "5034",
      "set_id": "189",
      "set_name": "Knitting Knightmare",
      "contents": [ "5056" ]
    },
    {
      "item_type_id": "4464",
      "set_id": "184",
      "set_name": "Ye Quest of Benyson the Forager",
      "contents": [ "4465","4466","4467" ]
    },
    {
      "item_type_id": "6187",
      "set_id": "203",
      "set_name": "Mission to Mars",
      "contents": [ "6188" ]
    },
    {
      "item_type_id": "6232",
      "set_id": "204",
      "set_name": "That '70's Set",
      "contents": [ "6226" ]
    },
    {
      "item_type_id": "6226",
      "set_id": "204",
      "set_name": "That '70's Set",
      "contents": [ "6223" ]
    }
]
}

export default containers
