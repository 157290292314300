import actionTypes from './actionTypes'

const defaultState = { forum:[], posts:[], post_data:[], forum_list:[], user_avatar:'', acct_type:'', comment:'', comment_data:[] }

const reducer = (state=defaultState,action) => {

  switch (action.type) {
    case actionTypes.FETCH_FORUM_TOPICS_SUCCESS:
      return { ...state,
        forum_list: action.payload
      }
    case actionTypes.FETCH_SINGLE_FORUM_SUCCESS:
      return { ...state,
        ...action.payload
      }
    case actionTypes.FETCH_SINGLE_THREAD_SUCCESS:
      return { ...state,
        ...action.payload
      }
    case actionTypes.FETCH_USER_DATA:
        return { ...state,
          user_avatar: action.payload.image_url_200,
          acct_type: action.payload.account_type
      }
    case actionTypes.UPDATE_COMMENT:
        return { ...state,
          comment: action.payload
      }
    case actionTypes.POST_COMMENT_SUCCESS:
        return { ...state,
          comment_data: action.payload
      }
    case actionTypes.POST_REPLY_SUCCESS:
          return { ...state,
          comment_data: action.payload
      }
    case actionTypes.CLEAR_COMMENT_STATE:
          return {  comment_data: defaultState
      }
    default:
        return state
  }
}

export default reducer
