import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import containerTable from '../../../util/containers'
import RetiredTable from '../../../util/retired'
import 'whatwg-fetch';

export default class SetDetail extends React.Component{
  constructor(){
    super()
      this.state = {sets:[],items:[],itemsMarketBuy:[], search: '', marketBuyNowLookup: false, toggleWalla: false, togglePlayer: false, containers: [], containerSets: [], containerItems: [], containerItemSource: []}
      this.fetchSets = this.fetchSets.bind(this)
      this.fetchSubSets = this.fetchSubSets.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFilter = this.handleFilter.bind(this)
  }

  handleChange(e){
    this.setState({search: e.target.value})
  }

  handleFilter(e){
    if (e.target.value==0) {
      this.setState({
        toggleWalla: false,
        togglePlayer: false
      })
    }
    if (e.target.value==1) {
      this.setState({
        toggleWalla: true,
        togglePlayer: false
      })
    }
    if (e.target.value==2) {
      this.setState({
        toggleWalla: false,
        togglePlayer: true
      })
    }
  }

  numberWithCommas(x){
    if(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }else{
      return '0'
    }
  }

  fetchSets(){
    const targetID = this.props.match.params.setId

    var setsurl = endpoints.allSets+targetID

    const key = utilities.apiKey;

    fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        items: json.items
      });
      this.setState({
        sets: json
      });
    });
}

fetchSubSets(){
  var setID = this.props.match.params.setId;
  var subSetID = this.props.match.params.subSetId
  const setsurl = endpoints.allSets+setID+"/sub/"+subSetID;
  const key = utilities.apiKey;
  var mySets = []
  var targetSet = null

  fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        items: json.items
      });
      this.setState({
        sets: json
      });
    });
}



fetchContainer(targetID){
  var tempBagArray = []
  var tempSetArray = []
  var tempItemArray = []
  var tempItemBagArray = []

  containerTable.containers.map( item => {
      tempBagArray.push(item.item_type_id)
      tempSetArray.push(item.set_id)
      item.contents.map( container_item => {
          tempItemBagArray.push(item.item_type_id)
          tempItemArray.push(container_item)
      })

  })

      if (tempBagArray.length != 0) {
        this.setState({containers: tempBagArray})
        this.setState({containerSets: tempSetArray})
        this.setState({containerItems: tempItemArray})
        this.setState({containerItemSource: tempItemBagArray})
      }
}

fetchRetired(){
  var tempArray = []

  RetiredTable.Retired.map( item => {
      tempArray.push(item.item_type_id)
  })

  if (tempArray.length != 0) {
    this.setState({Retired: tempArray})
  }

}

renderSets(){

  var RetiredItems = this.state.Retired;
  var libraries = this.state.items;
  var librariesMarket = this.state.itemsMarketBuy;
  var marketBuyNowLookup = this.state.marketBuyNowLookup;
  var search = this.state.search.trim().toLowerCase();
  var toggleWalla = this.state.toggleWalla;
  var togglePlayer = this.state.togglePlayer;


  if(search.length > 0){
    libraries = libraries.filter(function(l){
      return l.name.toLowerCase().match( search );
    });
  }

  if(togglePlayer){
    libraries = libraries.filter(function(l){
      return l.type.match( "player" );
    });
  }

  if(toggleWalla){
    libraries = libraries.filter(function(l){
      return l.type.match( "wallabee" );
    });
  }

  var tempCounter = 1000000;

  return libraries.map( set => {
    var tempTarget=null;

    if (set.name === "?") {
      tempTarget=null;
    } else {
      tempTarget="/tools/item/"+set.item_type_id;
    }
    var setId = this.state.sets.id;
    var colId = this.state.sets.collection_id;

    var howToFind = null;

    var exceptionFlag = utilities.mixExceptions.indexOf(set.item_type_id);

    if (exceptionFlag === -1) { exceptionFlag = 0; }

    var statusFlag = true
    var playerTag = null

    var findArray= [];
    var market_buy_now=set.market_buy_now;

    var containerItems = this.state.containerItems;
    //var bagTest=containerItems.indexOf(String(set.item_type_id))

    //bagTest=bagTest+1;

    if (set.type == "player") {
      playerTag= <span key={tempCounter} className='tag is-primary'>PLAYER</span>
      tempCounter=tempCounter+1;
    }

    if ((setId !== "20")&&(setId !== "13")) {
      if ((set.formulas.length > 0)||(exceptionFlag > 0)) {
        howToFind = <span key={tempCounter} className='tag is-warning'>MIX</span>;
        tempCounter=tempCounter+1;
        findArray.push(howToFind);
        if (set.in_container == 0) {
          statusFlag = false;
        }
      }
    }
    if (marketBuyNowLookup == true) {
      librariesMarket.map( setMarket => {
        if (setMarket.item_type_id==set.item_type_id) {
          market_buy_now=setMarket.market_buy_now;
        }
      });
    }

    if (market_buy_now==1) {
      howToFind = <span key={tempCounter} className='tag black'>MARKET</span>;
      tempCounter=tempCounter+1;
      findArray.push(howToFind);
      statusFlag = false;
    }

    if ((set.stores.length > 0)&&(set.stores[0] !== 0)) {
      howToFind = <span key={tempCounter} className='tag is-info'>STORE</span>;
      tempCounter=tempCounter+1;
      findArray.push(howToFind);
      statusFlag = false;
    }

    if (((set.categories.length > 0)&&(set.categories[0] !== 0))||((set.locations.length > 0)&&(set.locations[0] !== 0))) {
      howToFind = <span key={tempCounter} className='tag is-success'>FORAGE</span>;
      tempCounter=tempCounter+1;
      findArray.push(howToFind);
      statusFlag = false;
    }

    if ((statusFlag)&&(set.name !== "?")&&(setId !== "25")&&(colId !== "25")) {
      if ((setId==141)&&(set.item_type_id!=11)) {
        howToFind = <span key={tempCounter} className='tag purple'>CRACKERS</span>;
        tempCounter=tempCounter+1;
        findArray.push(howToFind);
      } else if (setId==13) {
        howToFind = <span key={tempCounter} className='tag purple'>GRAB BAGS</span>;
        tempCounter=tempCounter+1;
        findArray.push(howToFind);
      } else if (set.in_container>0) {
        howToFind = <span key={tempCounter} className='tag purple'>INTERACTABLES</span>;
        tempCounter=tempCounter+1;
        findArray.push(howToFind);
      } else {
        howToFind = <span key={tempCounter} className='tag orange'>MIXERS</span>;
        tempCounter=tempCounter+1;
        findArray.push(howToFind);
      }
    }

    if (set.has_variants > 0) {
      howToFind = <span key={tempCounter} className='tag pink'>VARIANTS</span>;
      tempCounter=tempCounter+1;
      findArray.push(howToFind);
    }


    var retiredFlag = 0;

    RetiredItems.map( item_type_id =>{
      if (item_type_id==set.item_type_id) {
        retiredFlag = 1
      }
    })

    var nameText = null;
    var apiImage = null;

    if (set.name === "?") {
      nameText = "Undiscovered Item";
      apiImage = "https://wallazee.global.ssl.fastly.net/images/variant/20140304-e1040f438370b85f0fc358d18041f15eb8b8b5c4f5bedca129577f6-100.png"
      howToFind = null
      findArray = []
    } else {
      nameText = set.name;
      apiImage="https://wallazee.global.ssl.fastly.net/images/variant/"+set.image_key+"-100.png";
      //apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+set.item_type_id+"-100.png";
      //        var setDescription = set.description;
      //        var setImg = "https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+set.image_url_+"-200.png";
    }

    if (set.item_type_id==2330) {
      findArray = []
      howToFind = <span key={tempCounter} className='tag pink'>SET COMPLETION</span>;
      tempCounter=tempCounter+1;
      findArray.push(howToFind);
    }


    howToFind = <div>
                {findArray}
                </div>


    if (set.name === "?") {
      return <div className="card" key={set.item_type_id}>
      <div className="card-image">

      <img key={set.item_type_id} src={apiImage} data-tip={set.name} alt={nameText} />
      </div>
      <div className="card-content">
      <div className="media-content">
      <p className="title">{nameText}</p>
      </div>
      <div className="content">
      <center>{playerTag}{howToFind}</center>
      </div>
      </div>
      </div>

    } else {
      if (retiredFlag == 1) {
        var displayImage = <div className="card-image">
              <div className="itemSetDisplay">
              <NavLink  key={set.item_type_id} to={tempTarget} >
              <img key={set.item_type_id} src={apiImage} data-tip={set.name} alt={nameText} />
              <img src="https://wallazee.global.ssl.fastly.net/images/site/retired-overlay.png" className="retired" />
              </NavLink>
              </div>
              </div>
      } else {
        var displayImage = <div className="card-image">
              <NavLink  key={set.item_type_id} to={tempTarget} >
              <img key={set.item_type_id} src={apiImage} data-tip={set.name} alt={nameText} />
              </NavLink>
              </div>
      }
      return <div className="card" key={set.item_type_id}>
      {displayImage}
      <div className="card-content">
      <div className="media-content">
      <NavLink  key={set.item_type_id} to={tempTarget} >
      <p className="title">{nameText}</p>
      </NavLink>
      </div>
      <div className="content">
      <center>{playerTag}{howToFind}</center>
      </div>
      </div>
      </div>
    }
    tempCounter=tempCounter+1;
  }
)
}

componentWillMount() {
  var targetID = this.props.match.params.setId
  var subTargetID = this.props.match.params.subSetId

  if (subTargetID>0) {
    this.fetchSubSets()
  } else {
    this.fetchSets()
  }
  this.fetchRetired()
  this.fetchContainer(targetID);
}

render() {
  if (( ! this.state.sets.id )&&( ! this.state.sets.collection_id)){

    var pageTitle="Loading Page... | WallaBee: Collecting and Trading Card Game on iOS";
    return <center>
    <MetaTags>
    <title>{pageTitle}</title>
    </MetaTags>
    <br/><br/><br/><br/><br/><h1>Loading...</h1><br/><br/><br/><br/></center>
  } else {

    var releaseDate = new Date(this.state.sets.created_at + "+0000").toUTCString()

    var pageTitle=this.state.sets.name + " Set | WallaBee: Collecting and Trading Card Game on iOS";

    var filterChecked0 = true
    var filterChecked1 = false
    var filterChecked2 = false


    if (this.state.toggleWalla == true) {
      filterChecked0 = false
      filterChecked1 = true
      filterChecked2 = false
    }

    if (this.state.togglePlayer == true) {
      filterChecked0 = false
      filterChecked1 = false
      filterChecked2 = true
    }

    var targetGenUrl= "/tools/sets/gen/"+this.state.sets.gen_id

    return <div className="sets item-container" id="set-detail">

      <MetaTags>
        <title>{pageTitle}</title>
      </MetaTags>


      <section className="hero hero-header">
        <div className="container">
          <div className="columns">
            <div className="column is-2">
              <div className="image is-128x128 text-center margin">
                <img src={this.state.sets.image_url_200} alt={this.state.sets.name} />
              </div>
            </div>
            <div className="column is-4">
              <h1 className="title">
                {this.state.sets.name}
              </h1>
              <h2>
                {this.state.sets.description}
              </h2>
              {this.state.sets.gen_id < 100 &&
                <NavLink className="button outdoors" to={targetGenUrl} id="hero-btn">Back to Generation {this.state.sets.gen_id}</NavLink>
              }
              {this.state.sets.gen_id == 100 &&
                <NavLink className="button outdoors" to={targetGenUrl} id="hero-btn">Back to Collectibles</NavLink>
              }
              {this.state.sets.collection_id == 20 &&
                <NavLink className="button outdoors" to="/tools/sets/20/sub" id="hero-btn">Back to Themed Exclusive Sets</NavLink>
              }
              {this.state.sets.collection_id == 25 &&
                <NavLink className="button outdoors" to="/tools/sets/25/sub" id="hero-btn">Back to Themed Unique Sets</NavLink>
              }


            </div>

            <div className="column is-6 flex" id="profile-header-stats">
              { ((this.state.sets.id != 25)&&(this.state.sets.id != 20)&&(this.state.sets.id != 13)) &&
              <div className="header-stats has-text-centered">
                <figure className="image is-96x96 margin">
                  <i className="fa fa-check-circle-o header-icon" aria-hidden="true"></i>
                </figure>
                <p className="stat-val" id="set-completed">{this.numberWithCommas(this.state.sets.total_completed)}</p>
                <p className="stat-key">Total Completed</p>
              </div>
              }
              {this.state.sets.designer &&
              <div className="header-stats has-text-centered">
                <figure className="image is-96x96 margin">
                  <i className="fa fa-paint-brush header-icon" id="paintbrush" aria-hidden="true"></i>
                </figure>
                <p className="stat-val" id="designer">{this.state.sets.designer}</p>
                <p className="stat-key">Designed By</p>
              </div>
              }
              <div className="header-stats has-text-centered">
                <figure className="image is-96x96 margin">
                  <i className="fa fa-clock-o header-icon" aria-hidden="true"></i>
                </figure>
                <p className="stat-val" id="set-date">{releaseDate}</p>
                <p className="stat-key">Date Created</p>
              </div>
            </div>
          </div>
        </div>
      </section>



      <div className="text-center">

        <div className="text-center">

          <input className="banner-search input is-medium" type="text" placeholder="Search Items..." onChange={this.handleChange}/>
    <br/>
    </div>
    <div>
    <div className="flex" id="card-container">{this.renderSets()}</div>
    </div>

    </div>
    </div>
  }
}
}
