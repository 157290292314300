import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import { connect } from 'react-redux'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

class RedeemCode extends Component{
 constructor(){
    super()
    this.state = {infoLoaded: false, ucode: "", uid: 0, codeResult: "", shouldHide: false }
  }

  redeemCode(uid,code){
    if (code !== "")
    {
       const key = utilities.apiKey;
       const redeemCodeEndpoint = endpoints.userProfile+uid+"/redeem/"+code;
       const token = JSON.parse(localStorage.getItem('token'))
       var resultText = <div className="message is-warning">
         <div className="message-header">
           <strong>Processing Please Wait...</strong>
         </div>
         <div className="message-body">
            Creating items this may take a few minutes... Code: {code}
         </div>
       </div>

       this.setState({
         codeResult: resultText
       });

         fetch(redeemCodeEndpoint,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
           .then(response => response.json())
           .then(json => {
           if (json.error) {
             console.log(json.error)
             var resultText = <div className="message is-danger">
               <div className="message-header">
                 <strong>Error</strong>
               </div>
               <div className="message-body">{json.error}  </div>
               </div>
           } else {
             var targetName=json.name
             var targetID=json.item_type_id
             console.log(targetName)
           resultText = <div className="message is-success">
             <div className="message-header">
               <strong>Success!</strong>
             </div>
             <div className="message-body">
               100 {targetName} created and sent to your lockbox.
             </div>
           </div>
         }
               this.setState({
                 itemCreated: json,
                 codeResult: resultText,
                 infoLoaded: true,
                 shouldHide: false
               });
           });
   }
 }


  doCode() {
    const userId = JSON.parse(localStorage.getItem('userId'))
		var code = this.state.ucode
    this.setState({
      infoLoaded: false,
      shouldHide: true
    });

		if (code === "") {
			var resultText = <div className="message is-danger">
        <div className="message-header">
          <strong>Error</strong>
        </div>
        <div className="message-body">
          You must specify a code.
        </div>
      </div>
	        this.setState({
	          codeResult: resultText,
            shouldHide: false
	        });

		} else {
			this.redeemCode(userId,code)
		}
	}

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.code !== this.props.match.params.code) {
      var code = nextProps.match.params.code
      this.state = {infoLoaded: false, ucode:[], uid: 0, codeResult: "" }
      const userId = JSON.parse(localStorage.getItem('userId'))
      this.redeemCode(userId,code);
    }
  }


    componentDidMount() {
      const userId = JSON.parse(localStorage.getItem('userId'))
      var code = this.state.ucode
      this.redeemCode(userId,code);
  	}

  render() {

    var pageTitle = "Redeem Code | WallaBee: Collecting and Trading Card Game on iOS & Android";
    var result = this.state.codeResult;
    var targetPath = "/u/"+ this.props.profile.name +"/redeem";
		var currentPath = this.props.location.pathname

  			if ((this.state.infoLoaded === false)&&(this.state.ucode === null)) {
    			return (
      				<div className="text-center" id="unique-gifting">
        				<MetaTags>
                  <title>{pageTitle}</title>
        				</MetaTags>
        				<h1>Creating Items...</h1>
      				</div>
    			)
        } else {
        return (
              <div id="code-wrapper">
    	  				<div className="text-center" id="redeem-code">
        					<MetaTags>
                    <title>{pageTitle}</title>
        					</MetaTags>

        					<img src="https://wallazee.global.ssl.fastly.net/images/collections/S25-20190218-feea75367d46ff22eb9a3892f32c1ef0f718004f0380c0cfb7d645e-100.png" alt="Redeem a Code!"/>
	        				<h2 className="title is-2">Redeem Code</h2>
    	    				<p>To redeem a code for items in game. Enter your code below and the items will be delivered directly to your lockbox. (We will even lock the items for free!)</p>
                  <p>NOTE: Codes are CASE SENSATIVE!</p>
        					<span>{result}</span>

                  <div>
                  <div className={this.state.shouldHide ? 'hidden' : ''}>
                    <div className="field">
                      <p className="control">
                        <input className="input is-medium text-center" type="text" name="code" placeholder="Enter Code..." onChange={(e)=>this.setState({ucode: e.target.value})}/>
                      </p>
                    </div>
                    <a className="button outdoors" onClick={this.doCode.bind(this)}>Redeem Code</a>
                    </div>
                  </div>
    	  				</div>
              </div>
    				)
      }
	}
}

export default connect(mapStateToProps)(RedeemCode)
