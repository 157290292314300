import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import 'whatwg-fetch';

export default class LockedItemsList extends React.Component{
  constructor(){
    super()
    this.state = {locked:[],filtered:[],totalLocked: 0,userInfo:[],infoLoaded: false, currentPage: 0, totalPages: 0, sortAscending: true, sortBy: "number", sets:[],filterSet: 0, filterMax: 0, filterNum: 0, filterText: "", ignoreUniques: false, subText: null, isPali: false, isBinary: false, timeOut: null}
    this.fetchLocked = this.fetchLocked.bind(this)
    this.fetchProfile = this.fetchProfile.bind(this)
    this.fetchSets = this.fetchSets.bind(this)

  }

  fetchProfile(){
    var personID = this.props.match.params.playerID;
    const key = utilities.apiKey;
    const profileurl = endpoints.userProfile+personID;

    fetch(profileurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(jsonPerson => {
      this.setState({
        userInfo: jsonPerson
      });
    });
  }

  fetchLocked(){
    var personID = this.props.match.params.playerID;
    const key = utilities.apiKey;
    const lockedurl = endpoints.userProfile+personID+"/lockeditems";

    var lockedPaging = null;
    var totalPages = 0;
    var lockedItems = []
    var page = 0;
    var counter = 2;
    var flatItems = []
    var arrayFlag = 0

    fetch(lockedurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      lockedPaging = json.paging
      totalPages = lockedPaging.total_pages;
      this.setState({
        totalPages: totalPages,
        totalLocked: lockedPaging.total_results
      })
      if (lockedPaging.total_results == 0) {
        this.setState({infoLoaded: true});
      }
    })
    .then(json => {
      for(page = 1; page <= totalPages; page++) {
        var nextpageurl = lockedurl + "?page=" + page
        fetch(nextpageurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
        .then(response => response.json())
        .then(json => {
          this.setState({currentPage: counter})
          counter++
          lockedItems.push(json.items)

          if (lockedItems.length==totalPages) {
            this.setState({infoLoaded: true});
            lockedItems.map( pageArray => {
              pageArray.map ( itemData => {
                flatItems.push(itemData)
              })
              arrayFlag++
            })

            if (this.state.sortAscending) {
              flatItems.sort(
                function (a,b) { return (a.number > b.number ? 1 : a.number < b.number ? -1 : 0); }
              )
            } else {
              flatItems.sort(
                function (a,b) { return (a.number < b.number ? 1 : a.number > b.number ? -1 : 0); }
              )
            }

            this.setState({
              locked: flatItems,
              filtered: flatItems
            })
          }
        });
      }
    })
  }


  fetchSets(){
    const setsurl = endpoints.oldAllSets;
    const key = utilities.apiKey;

    fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        sets: json.sets
      });
    });
  }


  filterSet(event){
    var setID = event.target.value
    var isPali = this.state.isPali
    var isBinary = this.state.isBinary
    var maxNum = this.state.filterMax
    var targetNum = this.state.filterNum
    var freeText = this.state.filterText
    var ignoreUniques = this.state.ignoreUniques
    this.setState({filterSet: setID})
    this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)
  }

  filterNum(event){
    var targetNum = event.target.value
    var isPali = this.state.isPali
    var isBinary = this.state.isBinary
    var setID = this.state.filterSet
    var freeText = this.state.filterText
    var maxNum = this.state.filterMax
    var ignoreUniques = this.state.ignoreUniques
    this.setState({filterNum: targetNum})
    this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)
  }

  filterMax(event){
    var maxNum = event.target.value
    var isPali = this.state.isPali
    var isBinary = this.state.isBinary
    var targetNum = this.state.filterNum
    var setID = this.state.filterSet
    var freeText = this.state.filterText
    var ignoreUniques = this.state.ignoreUniques
    this.setState({filterMax: maxNum})
    this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)
  }

  filterUniques(event){
    var ignoreUniques = this.state.ignoreUniques
    var isBinary = this.state.isBinary
    var isPali = this.state.isPali
    var targetNum = this.state.filterNum
    var setID = this.state.filterSet
    var maxNum = this.state.filterMax
    var freeText = this.state.filterText

    if (ignoreUniques) {
      ignoreUniques = false
      this.setState({ignoreUniques: false})
    } else {
      ignoreUniques = true
      this.setState({ignoreUniques: true})
    }
    this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)
  }

  filterPalindromes(event){
    var ignoreUniques = this.state.ignoreUniques
    var isPali = this.state.isPali
    var isBinary = this.state.isBinary
    var targetNum = this.state.filterNum
    var setID = this.state.filterSet
    var maxNum = this.state.filterMax
    var freeText = this.state.filterText

    if (isPali) {
      isPali = false
      this.setState({isPali: false})
    } else {
      isPali = true
      this.setState({isPali: true})
    }
    this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)
  }

  filterBinary(event){
    var ignoreUniques = this.state.ignoreUniques
    var isPali = this.state.isPali
    var isBinary = this.state.isBinary
    var targetNum = this.state.filterNum
    var setID = this.state.filterSet
    var maxNum = this.state.filterMax
    var freeText = this.state.filterText

    if (isBinary) {
      isBinary = false
      this.setState({isBinary: false})
    } else {
      isBinary = true
      this.setState({isBinary: true})
    }
    this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)
  }

  filterText(event){
    var freeText = event.target.value
    var maxNum = this.state.filterMax
    var isPali = this.state.isPali
    var isBinary = this.state.isBinary
    var targetNum = this.state.filterNum
    var setID = this.state.filterSet
    var ignoreUniques = this.state.ignoreUniques

    if (this.state.timeOut) {
      clearTimeout(this.state.timeOut);
    }

    this.state.timeOut = setTimeout(()=> {

      this.setState({timeOut: null});
      this.setState({filterText: freeText})
      this.doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText)

    }, 300);

  }

  doFilter(setID,maxNum,targetNum,ignoreUniques,isPali,isBinary,freeText){
    /*	console.log("SET:"+setID+" MAX:"+maxNum+" NUM:"+targetNum+" UNQ:"+ignoreUniques) */
    var flatItems = this.state.locked
    var filterCount = 0
    var paliCount = 0
    var binaryCount = 0
    var textCount = 0
    var filteredItems = []
    var allFlag = false
    var paliItems = []
    var binaryItems = []
    var textItems = []

    if (freeText != "") {
      flatItems.map( item => {
        var tempNum = item.number.toString()
        var tempName = item.name.toLowerCase()

        var numFlag = tempNum.indexOf(freeText)
        var nameFlag = tempName.toLowerCase().indexOf(freeText.toLowerCase())


        if ((numFlag > -1)||(nameFlag > -1)) {
          textItems.push(item)
          textCount++
        }
      })
      flatItems = textItems
    }

    if (isBinary) {
      flatItems.map( item => {
        var tempNum = item.number.toString()
        var arrayNum=tempNum.split("")
        var badFlag = false
        arrayNum.map ( number => {
          switch (number) {
            case "0":
            break
            case "1":
            break
            default:
            badFlag = true
            break
          }
        })

        if (badFlag != true) {
          binaryItems.push(item)
          binaryCount++
        }
      })
      flatItems = binaryItems
    }

    if (isPali) {
      flatItems.map( item => {
        var tempNum = item.number.toString()
        var reverseNum=tempNum.split("").reverse().join("");
        if (reverseNum == tempNum) {
          paliItems.push(item)
          paliCount++
        }
      })
      flatItems = paliItems
    }

    if (setID>0) {
      if (maxNum>0) {
        flatItems.map( item => {
          if ((setID == item.set_id)&&(maxNum >= item.number)) {
            filteredItems.push(item)
            filterCount++
          }
        })
      } else if (targetNum >0) {
        flatItems.map( item => {
          if ((setID == item.set_id)&&(targetNum == item.number)) {
            filteredItems.push(item)
            filterCount++
          }
        })

      } else {
        flatItems.map( item => {
          if (setID == item.set_id) {
            filteredItems.push(item)
            filterCount++
          }
        })
      }
    } else {
      if (ignoreUniques) {
        if (maxNum>0) {
          flatItems.map( item => {
            if ((maxNum >= item.number)&&(item.set_id != 25)) {
              filteredItems.push(item)
              filterCount++
            }
          })
        } else if (targetNum > 0) {
          flatItems.map( item => {
            if ((targetNum == item.number)&&(item.set_id != 25)) {
              filteredItems.push(item)
              filterCount++
            }
          })

        } else {
          flatItems.map( item => {
            if (item.set_id != 25) {
              filteredItems.push(item)
              filterCount++
            }
          })
        }
      } else {
        if (maxNum>0) {
          flatItems.map( item => {
            if (maxNum >= item.number) {
              filteredItems.push(item)
              filterCount++
            }
          })
        } else if (targetNum > 0) {
          flatItems.map( item => {
            if (targetNum == item.number) {
              filteredItems.push(item)
              filterCount++
            }
          })

        } else {
          filteredItems = flatItems
          allFlag = true
        }


      }
    }

    if (filterCount > 0) {
      var subText = filterCount + " of "
      this.setState({subText: subText})
    } else {
      if (allFlag) {
        if ((isBinary == true)&&(isPali == false)&&(freeText == "")) {
          var subText = binaryCount + " of "
          this.setState({subText: subText})
        } else if ((isBinary == false)&&(isPali == true)&&(freeText == "")) {
          var subText = paliCount + " of "
          this.setState({subText: subText})
        } else if ((isBinary == false)&&(isPali == false)&&(freeText != "")) {
          var subText = textCount + " of "
          this.setState({subText: subText})
        } else if ((isBinary == true)&&(isPali == true)&&(freeText == "")) {
          var subText = paliCount + " of "
          this.setState({subText: subText})
        }  else if ((isBinary == true)&&(isPali == false)&&(freeText != "")) {
          var subText = binaryCount + " of "
          this.setState({subText: subText})
        }  else if ((isBinary == false)&&(isPali == true)&&(freeText != "")) {
          var subText = paliCount + " of "
          this.setState({subText: subText})
        }  else if ((isBinary == true)&&(isPali == true)&&(freeText != "")) {
          var subText = paliCount + " of "
          this.setState({subText: subText})
        } else {
          this.setState({subText: null})
        }
      } else {
        var subText = "0 of "
        this.setState({subText: subText})
      }
    }

    this.setState({filtered: filteredItems})
    return true
  }


  sortAgain(){
    var flatItems = this.state.filtered

    if (this.state.sortAscending) {
      this.setState({sortAscending: false})
      flatItems.sort(
        function (a,b) { return (a.number < b.number ? 1 : a.number > b.number ? -1 : 0); }
      )
    } else {
      this.setState({sortAscending: true})
      flatItems.sort(
        function (a,b) { return (a.number > b.number ? 1 : a.number < b.number ? -1 : 0); }
      )
    }

    this.setState({filtered: flatItems})
  }


  sortBy(event){
    var sortBy = event.target.value

    var flatItems = this.state.filtered


    switch (sortBy) {

      case "numberUp":
      flatItems.sort(
        function (a,b) { return (a.number > b.number ? 1 : a.number < b.number ? -1 : 0); }
      )
      break;

      case "numberDown":
      flatItems.sort(
        function (a,b) { return (a.number < b.number ? 1 : a.number > b.number ? -1 : 0); }
      )
      break;

      case "nameUp":
      flatItems.sort(function(a, b){
        var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
        var numA=a.number, numB=b.number;
        if (nameA < nameB)
        return -1;
        if (nameA > nameB)
        return 1;
        if ((nameA == nameB)&&(numA > numB))
        return 1;
        if ((nameA == nameB)&&(numA < numB))
        return -1;

        return 0;
      });

      break;
      case "nameDown":
      flatItems.sort(function(a, b){
        var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase();
        var numA=a.number, numB=b.number;
        if (nameA < nameB) //sort string ascending
        return 1;
        if (nameA > nameB)
        return -1;
        if ((nameA == nameB)&&(numA > numB))
        return 1;
        if ((nameA == nameB)&&(numA < numB))
        return -1;
        return 0;
      });

      break;

      case "ageDown":
      flatItems.sort(function(a,b){
        return new Date(b.created_at) - new Date(a.created_at);
      });
      break;
      case "ageUp":
      flatItems.sort(function(a,b){
        return new Date(a.created_at) - new Date(b.created_at);
      });
      break;


      case "rbdd":

      break;

      case "rbtd":

      break;

      case "rbqd":

      break;

    }
    this.setState({filtered: flatItems})
  }



  componentDidMount() {
    this.fetchLocked()
    this.fetchProfile()
    this.fetchSets()
  }

  render() {



    if (this.state.infoLoaded == false) {
      var pageTitle="Loading Page... | WallaBee: Collecting and Trading Card Game on iOS";
      return <div>


        <div id="loading-container">
          <img id="starburst" src="https://wallazee.global.ssl.fastly.net/images/site/starburst.png"/>
          <h2 className="title is-2">Loading<br/>{this.state.currentPage} of {this.state.totalPages} Pages</h2>
          <div id="loading-progress">
            <progress className="progress is-primary" value={this.state.currentPage} max={this.state.totalPages}/>
          </div>
        </div>

      </div>

        } else {

      var setList = []
      setList.push(<option key="0" value="0">All Items</option>)
      setList.push(<option key="13" value="13">Wallagraphic Items</option>)
      setList.push(<option key="20" value="20">Exclusive Items</option>)
      setList.push(<option key="25" value="25">Unique Items</option>)

      this.state.sets.map( singleSet => {
        setList.push(<option key={singleSet.id} value={singleSet.id}>{singleSet.name}</option>)
      })

      var subText = this.state.subText
      var itemTable = []
      var rowColor = "white"
      var row1 = "#f4d381"
      var row2 = "#ffffff"

      var pageTitle=this.state.userInfo.name+"'s Lock List | WallaBee: Collecting and Trading Card Game on iOS";

      this.state.filtered.map( itemData => {

        var targetURL = "/tools/item/"+itemData.item_type_id+"/num/"+itemData.number

        var variantCheck = itemData.variant_id;
        var variantText = null

        if (variantCheck>0) { variantText=<span> ({itemData.variant_name})</span> }

        itemTable.push(
          <tr key={itemData.id}>
            <td>{itemData.number}</td>
            <td><NavLink  key={itemData.id} to={targetURL}>{itemData.name}{variantText}</NavLink></td>
            <td>###</td>
            <td>###</td>
            <td>###</td>
          </tr>
      )
      })


      return <div id="lockedlist-container">
        <MetaTags>
          <title>{pageTitle}</title>
        </MetaTags>
        <div className="container">
          <div className="columns is-mobile is-multiline">
            <div className="column is-desktop is-full-mobile">

              <div className="panel">
                <div className="panel-heading">Options</div>

                <div className="locklist-options">

                  <div className="panel-block">
                    {/*--LIMIT TO ITEMS FROM--*/}
                    <div className="field">
                      <label className="label">
                        Limit to Items from:
                      </label>
                      <div className="control">
                        <span className="select">
                          <select name="set" onChange={this.filterSet.bind(this)}>
                            {setList}
                          </select>
                        </span>
                      </div>
                    </div>
                    {/*--END LIMIT TO ITEMS FROM--*/}
                  </div>

                  <div className="panel-block">
                    {/*--LIMIT TO ITEMS NUMBERED--*/}
                    <label className="label">
                      Limit to Items Numbered:
                    </label>
                    <div className="field is-horizontal">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input className="input" type="text" name="num" size="5" placeholder="Number..." onChange={this.filterNum.bind(this)} />
                          </div>
                          <span>or</span>
                          <div className="field">

                            <div className="control">

                              <div className="select">
                                <select name="maxnum"  onChange={this.filterMax.bind(this)}>
                                  <option value="0">All Numbers</option>
                                  <option value="49">Below 50</option>
                                  <option value="99">Below 100</option>
                                  <option value="999">Below 1000</option>
                                  <option value="9999">Below 10000</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*--END LIMIT TO ITEMS NUMBERED--*/}
                  </div>


                  <div className="panel-block">
                    {/*--SORT BY--*/}
                    <div className="field is-horizontal">
                      <div className="is-normal">
                        <label className="label is-normal">Sort By:</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <div className="select">
                              <select name="sortBy"  onChange={this.sortBy.bind(this)}>
                                <option value="numberUp">Number (Ascending)</option>
                                <option value="numberDown">Number (Descending)</option>
                                <option value="nameUp">Name (A-Z)</option>
                                <option value="nameDown">Name (Z-A)</option>
                                <option value="ageUp">Age (Oldest)</option>
                                <option value="ageDown">Age (Newest)</option>
                              </select>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*--END SORT BY--*/}
                  </div>

                  <div className="panel-block">
                    {/*--IGNORE UNIQUES--*/}
                    <div className="field">
                      <p className="control">
                        <label className="checkbox">
                          <input type="checkbox" name="uniques" onChange={this.filterUniques.bind(this)} />
                          Ignore Uniques
                        </label>
                      </p>
                    </div>
                    {/*--END IGNORE UNIQUES--*/}


                    {/*PALINDROMES*/}
                    <div className="field">
                      <p className="control">
                        <label className="checkbox">
                          <input type="checkbox" name="palindrome" onChange={this.filterPalindromes.bind(this)} />
                          Limit to Items that are Palindromes
                        </label>
                      </p>
                    </div>
                    {/*END PALINDROMES*/}


                    {/*--BINARY--*/}

                    <div className="field">
                      <p className="control">
                        <label className="checkbox">
                          <input type="checkbox" name="binary"  onChange={this.filterBinary.bind(this)} />
                          Limit to Items that are Binary
                        </label>
                      </p>
                    </div>

                    {/*--END BINARY--*/}
                  </div>


                  <div className="panel-block">
                    {/*--SEARCH NAME/#--*/}

                    <div className="field">
                      <label className="label">Search Name or Number Pattern:</label>
                      <p className="control">
                        <input className="input" type="text" name="freeText" size="20" placeholder="Name or #...(ie: all item that contain 00)" onChange={this.filterText.bind(this)} />
                      </p>
                    </div>

                    {/*--END SEARCH NAME/#--*/}
                  </div>

                </div>

              </div>
            </div>


            <div className="column">
              <div className="panel" id="lockeditem-container">
                <div className="panel-heading">Locked Items</div>

                <div className="table-container">
                  <h2 className="text-center title is-5" id="total-locked">{subText}{this.state.totalLocked} Items Total</h2>
                  <table className="table is-bordered is-striped" id="lockList">
                    <tbody>
                      <tr><th>#</th><th>Name</th><th>RB DD</th><th>RB TD</th><th>RB QD</th></tr>
                      {itemTable}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    }
  }
}
