import React from 'react'
import { connect } from 'react-redux'
import { fetchUserAchievementsSuccess } from '../actions'
import MetaTags from 'react-meta-tags'
import utilities from '../../../util/utilities';
import 'whatwg-fetch';
import { withRouter } from 'react-router-dom'


const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchUserAchievementsSuccess: () => dispatch(fetchUserAchievementsSuccess()),
  }
}

class ProfileAchievements extends React.Component{
  renderUserAchievements () {

    return this.props.profile.achievements.map( (achievement,i) => (

        <div key={i} className="card">
          <div className="card-image">

            {
              (() => {if(achievement.awarded){
                return <a href={achievement.badge_large} target="_blank"><img src={achievement.badge_small} alt={achievement.description_earned} title={achievement.description_earned}/></a>
              } else {
                return <img src={achievement.badge_small_null} alt={achievement.name} />
              }
              })()
            }

          </div>

          <div className="card-content">
            <strong className="achievement-name">{achievement.name}</strong>
            <div className="progress-bar">
              {
                (Math.round(achievement.progression[0].value/achievement.progression[0].target * 100) <= 33) && <progress className="progress is-danger" value={Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)} max="100" title={`${achievement.progression[0].value}/${achievement.progression[0].target}`}> : {Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)}
                  </progress>

                }
                  
                  { ( Math.round(achievement.progression[0].value/achievement.progression[0].target * 100) <= 66 && Math.round(achievement.progression[0].value/achievement.progression[0].target * 100) >= 34 ) && <progress className="progress is-warning" value={Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)} max="100" title={`${achievement.progression[0].value}/${achievement.progression[0].target}`}> : {Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)} </progress>
                    }

                    { (Math.round(achievement.progression[0].value/achievement.progression[0].target * 100) <= 99 && Math.round(achievement.progression[0].value/achievement.progression[0].target * 100) >= 67) && <progress className="progress is-info" value={Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)} max="100" title={`${achievement.progression[0].value}/${achievement.progression[0].target}`}> : {Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)}</progress>
                      }

                      { (Math.round(achievement.progression[0].value/achievement.progression[0].target * 100) === 100) && <progress className="progress is-success" value={Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)} max="100" title={`${achievement.progression[0].value}/${achievement.progression[0].target}`}> : {Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)}</progress> }

                        <h4>{Math.round(achievement.progression[0].value/achievement.progression[0].target * 100)}%</h4>
                      </div>

                        <div className="achievement-info">
                          <p className="description">{achievement.description}</p>
                        </div>
                      </div>
                      </div>

    ))}

  render() {

    	var pageTitle=this.props.profile.name + "'s Achievements" + utilities.metaTitle;

    return (
      <div className="flex" id="achievements">

        <MetaTags>
          <title>{pageTitle}</title>
        </MetaTags>

        {this.renderUserAchievements()}

      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileAchievements))
