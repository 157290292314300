import React, { Component } from 'react'
import { ReactTitle } from 'react-meta-tags'
import { NavLink, Switch, Route } from 'react-router-dom'
import AllSets from './sets/AllSets'
import ItemSearch from './item-search/item-search'
import ItemSearchResults from './item-search/SearchResults'
import ProfileSearch from './profile/profile-search'
import SetDetail from './sets/SetDetail'
import ItemDetail from './sets/ItemDetail'
import ItemNumberSearch from './sets/ItemNumberSearch'
import ItemHistoryPage from './sets/ItemHistory'
import PlayerProfile from './profile/PlayerProfile'
import PlaceDetail from './location/Place'
export default class Tools extends Component {
  constructor(){
    super()
    this.state = {query:[], target: "item", search: false}

  }

   searchQuery(){
	  	var searchQuery = this.state.query;

	  	var targetQuery = this.state.target;

  		if (searchQuery != null) {

      var targetURL = null;

			if (targetQuery === "item") {
		  		targetURL = '/tools/search/' + searchQuery;
		  	} else {
		  		targetURL = '/tools/profile/' + searchQuery;
		  	}
  			this.props.history.push(targetURL);
  		}
  }

	toggleSearch(event) {
		var targetSearch = event.target.value
		console.log(targetSearch)
		this.setState({target: targetSearch})
	}

  render() {
    return <div id="tools">

      <ReactTitle title="ItemBee | WallaBee: Collecting and Trading Card Game on iOS & Android"/>

      <header className="hero is-warning is-bold">
        <section className="container">
          <div id="itembee-header">

            <div id="itembee-logo">
              <img width="93px" src="https://wallazee.global.ssl.fastly.net/images/variant/20130718-80d26f63e1dfdcf8015e47e656a752e1a049dd2e8eac225a8796e3e-200.png" alt="ItemBee Logo" />
            </div>

            <div id="itembee-desc">
              <h1 className="title">ItemBee</h1>
              <h2>
                ItemBee is the <b>completely free</b> and official, web based companion to WallaBee for iOS and Android, developed by <NavLink to="/tools/profile/mizak/bio">Mizak</NavLink> and <NavLink to="/tools/profile/judypop/bio">judypop</NavLink>.
              </h2>
            </div>

          </div>
        </section>

        <section className="tabs is-centered is-boxed">
          <ul>
            <li>
              <NavLink className="link" to='/tools/sets'>
                <span className="icon"><i className="fa fa-circle-o" aria-hidden="true"></i></span>
                <span>Browse Sets</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="link" to='/tools/search/'>
                <span className="icon"><i className="fa fa-search" aria-hidden="true"></i></span>
                <span>Item Search</span>
              </NavLink>
            </li>
            <li>
              <NavLink className="link" to='/tools/profile'>
                <span className="icon"><i className="fa fa-user-circle-o" aria-hidden="true"></i></span>
                <span>Profile Search</span>
              </NavLink>
            </li>

          </ul>
        </section>

      </header>
      <section id="itembee-info-container">
        <Switch>
        <Route exact path='/tools/sets' component={AllSets} />
        <Route exact path='/tools/sets/gen/:genId' component={AllSets} />
        <Route exact path='/tools/sets/:setId/sub/' component={AllSets} />
        <Route exact path={'/tools/sets/:setId'} component={SetDetail} />
        <Route exact path={'/tools/sets/:setId/sub/:subSetId'} component={SetDetail} />
          <Route exact path={'/tools/item/:itemId'} component={ItemDetail} />
          <Route exact path={'/tools/item/:itemId/num/:itemNumber'} component={ItemNumberSearch} />
          <Route exact path={'/tools/item/history/:itemId'} component={ItemHistoryPage} />
          <Route exact path='/tools/search' component={ItemSearch} />
          <Route exact path='/tools/search/:query' component={ItemSearchResults} />
          <Route exact path='/tools/profile' component={ProfileSearch} />
          <Route path='/tools/profile/:playerID' component={PlayerProfile} />
          <Route path='/tools/place/:placeID' component={PlaceDetail} />
          <Route component={AllSets} />

        </Switch>
      </section>

    </div>

      }
}
