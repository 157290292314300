import React, { Component } from 'react'
import endpoints from '../../util/endpoints'
import utilities from '../../util/utilities'
import ReactTooltip from 'react-tooltip'
import $ from 'jquery'
import Pusher from 'pusher-js'
import 'whatwg-fetch'

export default class LatestItems extends Component {
  constructor(){
    super()
    this.state = { items: '' }
    this.fetchTest = this.fetchTest.bind(this)
  }

  fetchTest(){

    const itemsurl = endpoints.latestItems;
    const key = utilities.apiKey;

    fetch(itemsurl,{method:'GET', headers: {'Content-Type': 'application/json','X-WallaBee-API-Key': key } })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        this.setState({
          items: data
        });
      })
      .catch((e) => {
        console.log(e);
      });

  }

  renderItems(){
    return this.state.items.map( item => {

      return <div> <img key={item.item_type_id} src={item.image_url_200} alt={item.name}/>

      </div>
      }
     )
  }

  componentWillMount() {

    this.fetchTest()
  }

  render() {

    var pusher = new Pusher('0cf4a65c1bda056361d9');
    var channel = pusher.subscribe('wallabee');
    channel.bind('new_item', function(data) {
      $("#count-" + data.item_type_id ).text( data.number )
    })

    if ( ! this.state.items ){ return null; }

    var latestItemsTop = [];
    var latestItemsBottom = [];
    var targetURL = ''

    for (var i = 0; i < 4; i++ ){
    	targetURL = "/tools/item/" + this.state.items[i].item_type_id
	    latestItemsTop.push( <div key={i} className="latest-item">
        <h3>#
          <span id={'count-' + this.state.items[i].item_type_id}>{this.state.items[i].current_number}</span>
        </h3>

        <a href={targetURL} key={i}>
          <img key={i} src={this.state.items[i].image_url_200} alt={this.state.items[i].name}/>
        </a>

      </div>);

    }

      for (var j = 4; j < 8; j++ ){
    	   targetURL = "/tools/item/" + this.state.items[j].item_type_id
         latestItemsBottom.push(<div key={j}  className="latest-item">
           <h3>#
             <span id={'count-' + this.state.items[j].item_type_id}>{this.state.items[j].current_number}</span>
           </h3>
           <a href={targetURL} key={j}><img key={j} src={this.state.items[j].image_url_200}  alt={this.state.items[j].name}/>
           </a>
         </div>
           );

    }

    return <div id="latest-items">

      <h2>Latest Items</h2>

      <div className="shelf">
        {latestItemsTop}

        <div className="shelf-bottom"></div>
      </div>

      <div className="shelf">

        {latestItemsBottom}

        <div className="shelf-bottom"></div>
      </div>


      <ReactTooltip effect="solid" type="light" scrollHide={true}/>
    </div>

  }
}
