import React, { Component } from 'react'

class Modal extends Component{
  render() {
     if (this.props.isOpen === false)
       return null

     let modalStyle = {
       position: 'absolute',
       left: '50%',
       transform: 'translate(-50%, -50%)',
       zIndex: '9999',
       width: '764px',
       maxWidth: '100%',
       padding: '0 1em'
     }


     if (this.props.style) {
       for (let key in this.props.style) {
         modalStyle[key] = this.props.style[key]
       }
     }

     let backdropStyle = {
       position: 'absolute',
       width: '100%',
       height: '100%',
       top: '0px',
       left: '0px',
       zIndex: '9998',
       background: 'rgba(0, 0, 0, 0.7)'
     }

     if (this.props.backdropStyle) {
       for (let key in this.props.backdropStyle) {
         backdropStyle[key] = this.props.backdropStyle[key]
       }
     }

     return (
       <div className={this.props.containerClassName}>
         <div className={this.props.className} style={modalStyle}>
           {this.props.children}
         </div>
         {!this.props.noBackdrop &&
           <div className={this.props.backdropClassName} style={backdropStyle}
             onClick={e => this.close(e)}/>}
       </div>

     )
   }

   close(e) {
     e.preventDefault()

     if (this.props.onClose) {
       this.props.onClose()
     }
   }
 }

 export default Modal
