import React from 'react'
import { Link } from 'react-router-dom'
import { ReactTitle } from 'react-meta-tags'

export default class Terms extends React.Component {
  render() {
    return (
      <div className="container" id="terms">

        <ReactTitle title="Terms of Service | WallaBee: Collecting and Trading Card Game on iOS & Android"/>

        <h1>Terms of Service</h1>

        <p>Last Updated: February 27, 2019</p>
      
        <p>INTRODUCTION; AGREEMENT TO THIS TERMS OF SERVICE</p>
      
        <p>Welcome to the FREEZE TAG, Inc ("We", "Our") mobile device software application (the "Munzee Software," “Eventzee Software,” “WallaBee Software,” or other “Freeze Tag Software”), websites, feeds and applications for third-party Web sites and services, and any other data used or collected, mobile, web services, or applications owned, controlled, or offered by FREEZE TAG, Inc. (collectively, the "FREEZE TAG Services"). Users and other third-parties who download, access, use and/or subscribe to the FREEZE TAG Services or use FREEZE TAG&apos;s application program interface (collectively or individually "You(r)" or "Users") must do so under the following terms and conditions of use.</p>
    
        <p>BEFORE USING ANY FREEZE TAG SERVICES, PLEASE READ CAREFULLY THE FOLLOWING TERMS AND CONDITIONS OF SERVICE. BY ACCESSING, BROWSING AND/OR USING THE FREEZE TAG SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY ALL CONSENTS AND DISCLOSURES SET FORTH IN THE FREEZE TAG REGISTRATION PROCESS (AND HEREBY INCORPORATED HEREIN BY THIS REFERENCE), ALL OF THE FOLLOWING TERMS AND CONDITIONS, INCLUDING ANY FUTURE MODIFICATIONS TO THIS TERMS OF SERVICE, AND ALL Player Code of Conduct (COLLECTIVELY, THE "AGREEMENT"). IF YOU DO NOT AGREE TO THIS AGREEMENT, THEN PLEASE CEASE USING THE FREEZE TAG SERVICES IMMEDIATELY.</p>
    
        <p>THE AGREEMENT CONSTITUTES A BINDING CONTRACT BETWEEN YOU AND FREEZE TAG, INC.  You may receive a copy of this Agreement by emailing us at: <a href="mailto:support@wallabeegame.com">support@wallabeegame.com</a>, Subject: Terms of Service Agreement.</p>
    
        <p>ALL THE FREEZE TAG SERVICES ARE AVAILABLE ONLY FOR INDIVIDUALS AGED 13 YEARS OR OLDER, AND SOME OF THE FREEZE TAG SERVICES ARE AVAILABLE ONLY FOR PERSONS 18 YEARS OR OLDER. IF YOU ARE 13 OR OLDER BUT UNDER THE AGE OF 18, THEN YOU AGREE TO REVIEW THESE TERMS AND CONDITIONS WITH YOUR PARENT OR GUARDIAN TO MAKE SURE THAT BOTH YOU AND YOUR PARENT OR GUARDIAN UNDERSTAND AND AGREE TO THIS AGREEMENT. YOU AGREE TO HAVE YOUR PARENT OR GUARDIAN REVIEW AND ACCEPT THIS AGREEMENT ON YOUR BEHALF THROUGH THE FREEZE TAG REGISTRATION PROCESS. IF YOU ARE A PARENT OR GUARDIAN ENTERING INTO THIS AGREEMENT FOR THE BENEFIT OF A CHILD OVER 13, THEN YOU AGREE TO AND ACCEPT FULL RESPONSIBILITY FOR THAT CHILD&apos;S USE OF THE FREEZE TAG SERVICE, INCLUDING ALL LEGAL LIABILITY THAT HE OR SHE MAY INCUR.</p>
          
        <p><b>1. IMPORTANT DISCLAIMERS</b></p>

        <p>THE FREEZE TAG SERVICES ARE INTENDED ONLY AS LOCATION-BASED SERVICES FOR FINDING FREEZE TAG GAME OBJECTS AND SHOULD NOT BE USED OR RELIED ON AS AN EMERGENCY LOCATOR SYSTEM, USED WHILE DRIVING OR OPERATING VEHICLES, OR USED IN CONNECTION WITH ANY HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE, OR ANY OTHER APPLICATION IN WHICH THE FAILURE OR INACCURACY OF THAT APPLICATION OR THE FREEZE TAG SERVICES COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR PROPERTY DAMAGE. THE FREEZE TAG SERVICES CONTAIN INFORMATION ABOUT HIDDEN OBJECTS, PHOTOGRAPHS, AND RELATED ACTIVITIES PUBLISHED TO THE FREEZE TAG SERVICES BY USERS LIKE YOU. FREEZE TAG DOES NOT GUARANTEE THE ACCURACY OR TIMELINESS OF THE INFORMATION AVAILABLE IN THE FREEZE TAG SERVICES. YOU ARE SOLELY RESPONSIBLE FOR VERIFYING THE ACCURACY OF ANY INFORMATION AVAILABLE IN THE FREEZE TAG SERVICES. FREEZE TAG DISCLAIMS RESPONSIBILITY FOR YOUR RELIANCE ON INFORMATION IN THE FREEZE TAG SERVICES IN SECTION 14 BELOW.</p>
          
        <p>YOU ACKNOWLEDGE AND AGREE THAT FREEZE TAG  HAS OFFERED THE FREEZE TAG SERVICES AND ENTERED INTO THIS AGREEMENT IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH BELOW. YOU FURTHER ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THIS AGREEMENT REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND FREEZE TAG, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THIS AGREEMENT FORM AN ESSENTIAL BASIS OF THE CONTRACT BETWEEN YOU AND FREEZE TAG, INC.. FREEZE TAG WOULD NOT BE ABLE TO PROVIDE THE FREEZE TAG SERVICES TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS AND DISCLAIMERS.</p>
    
        <p><b>2. PRIVACY NOTICE; Player Code of Conduct TO INDIVIDUAL FEATURES AND SERVICES</b></p>

        <p>FREEZE TAG&apos;s Privacy Policy can be located at <a href="https://www.freezetag.com/privacy">https://www.freezetag.com/privacy</a> and is hereby incorporated into this Agreement by reference. Please read this notice carefully for disclosures relating to the collection, use, and disclosure of Your personal information and real-time location information.</p>

        <p>When using any of the FREEZE TAG Services, You will be subject to any additional posted player code of conduct, Player Code of Conduct or rules applicable to certain features, which may be posted from time to time (the "Player Code of Conduct"). For example, the FREEZE TAG Services may offer You opportunities to review certain events and venues, post event announcements, send messages to other Users, and to enter contests and sweepstakes. All Player Code of Conduct are hereby incorporated by reference into this Agreement.</p>

        <p><b>3. MODIFICATION OF THIS AGREEMENT</b></p>

        <p>We reserve the right, at our discretion, to change, modify, add, or remove portions of this Agreement or any Player Code of Conduct at any time. Please check this Agreement and all Player Code of Conduct periodically for changes. Your continued use of the FREEZE TAG Services after the posting of any modifications or changes constitutes Your binding acceptance of such changes. Please note that additional and/or different conditions and terms of use may apply to services or products provided through one or more of our partners, advertisers, or business associates, and You should refer to those before using such services or products. For any material changes to this Agreement or any Player Code of Conduct, You acknowledge and agree that any such amended or modified terms shall automatically be effective thirty (30) days after they are initially posted on FREEZE TAG Services.</p>

        <p><b>4. OWNERSHIP; PROPRIETARY RIGHTS</b></p>

        <p>The FREEZE TAG Services are owned and operated by FREEZE TAG Inc. The FREEZE TAG Software, content, visual interfaces, information, graphics, design, compilation, computer code, products, software, services, including, but not limited to, the mobile device applications, used and stored data, and all other elements of the FREEZE TAG Services (collectively, the "Materials") are protected by United States copyright, trade dress, patent, and trademark laws, international laws and conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. All Materials contained in the FREEZE TAG Services are the property of FREEZE TAG Inc. or its subsidiaries or affiliated companies and/or third-party licensors. All trademarks, service marks, and trade names displayed on the FREEZE TAG Services are proprietary to FREEZE TAG or its affiliates and/or third-party licensors. Except as expressly authorized by FREEZE TAG, You agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Materials.</p>

        <p><b>5. ACCOUNT INFORMATION</b></p>

        <p>All the FREEZE TAG Services are unavailable to persons under the age of 13 or to any Users suspended or removed from the FREEZE TAG Services by FREEZE TAG. In addition, some FREEZE TAG Services are not available to persons under the age of 18. You agree that the information you provide to FREEZE TAG upon registration and at all other times will be true, accurate, current and complete. You also agree that You will ensure that this information is kept accurate and up to date at all times.</p>

        <p><b>6. PASSWORD</b></p>

        <p>When You register for the FREEZE TAG Services You may be asked to provide a password. We recommend passwords that use a combination of upper and lower case letters, numbers and symbols. As You will be responsible for all activities that occur under Your password, You should keep Your password strictly confidential at all times. YOU MUST NOTIFY FREEZE TAG IMMEDIATELY OF ANY UNAUTHORIZED USE OF YOUR PASSWORD OR IF YOU BELIEVE THAT YOUR PASSWORD IS NO LONGER CONFIDENTIAL AND KNOWN ONLY TO YOU. We reserve the right to suspend Your account and/or require You to alter Your password if we believe for any reason that Your password is no longer secure. YOU MAY NOT SHARE YOUR FREEZE TAG ACCOUNT PASSWORD WITH ANY OTHER PERSON FOR ANY REASON. We are not liable for any loss or damage arising from Your failure to comply with the above requirements.</p>
        
        <p><b>7. USAGE RULES; PROHIBITED CONDUCT & USES YOU UNDERSTAND AND HEREBY ACKNOWLEDGE AND AGREE THAT YOU MAY NOT AND WARRANT THAT YOU WILL NOT:</b></p>

        <p>7.1 Use the FREEZE TAG Services or any location information displayed within the FREEZE TAG Services to "stalk", harass, abuse, defame, threaten or defraud other Users, or collect, attempt to collect, or store location or personal information about other users without their consent;</p>

        <p>7.2 Use the FREEZE TAG Services if You are under the age of 13 years old, or 18 years old where applicable;</p>
        
        <p>7.3 Submit offensive or pornographic materials or images to any FREEZE TAG Services app or use these images on Your personal profile page;</p>
    
        <p>7.4 Use the FREEZE TAG Services for any illegal purpose, or in violation of any local, state, national, or international law, including, without limitation, laws governing intellectual property and other proprietary rights, data protection and privacy, rights of publicity, and import or export control;</p>
    
        <p>7.5 Make unsolicited offers, advertisements, proposals, or send junk mail, to other Users of the FREEZE TAG Services. This includes, but is not limited to, unsolicited advertising, promotional materials or other solicitation material, bulk mailing of commercial advertising, chainmail, informational announcements, charity requests, and petitions for signatures;</p>
    
        <p>7.6 Impersonate any person or entity, falsely claim an affiliation with any person or entity, or access the FREEZE TAG Services accounts of other Users;</p>
    
        <p>7.7 Share FREEZE TAG -issued passwords or access to Your mobile device while the FREEZE TAG services is running and accessible with any third party or encourage any other user to do so;</p>
        
        <p>7.8 Misrepresent the source, identity or content of information transmitted via the FREEZE TAG Services;</p>
    
        <p>7.9 Remove, circumvent, disable, damage or otherwise interfere with security-related features of the FREEZE TAG Services, features that prevent or restrict use or copying of any content accessible through the FREEZE TAG Services, or features that enforce limitations on use of the FREEZE TAG Services;</p>
    
        <p>7.10 Intentionally interfere with or damage operation of the FREEZE TAG Services or any user&apos;s enjoyment of them, by any means, including uploading or otherwise disseminating viruses, worms, or other malicious code;</p>
    
        <p>7.11 Post, store, send, transmit, or disseminate any information or material which a reasonable person could deem to be objectionable, libelous, offensive, indecent, pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially or ethnically offensive, or otherwise inappropriate, regardless of whether this material or its dissemination is unlawful;</p>
    
        <p>7.12 Post, store, send, transmit, or disseminate any information or material which infringes any patents, trademarks, trade secrets, copyrights, or any other proprietary or intellectual property rights;</p>
    
        <p>7.13 Use the FREEZE TAG Services with any products, systems, or applications installed or otherwise connected to or in communication with vehicles, or otherwise capable of vehicle navigation, positioning, dispatch, real time route guidance, fleet management, or similar applications; or</p>
    
        <p>7.14 Use the FREEZE TAG Services in connection with hazardous environments requiring fail-safe performance or any application in which the failure or inaccuracy of that application or the FREEZE TAG Services could lead directly to death, personal injury, or severe physical or property damage.</p>
    
        <p>7.15 Attempt to gain unauthorized access to the FREEZE TAG Services, or any part of it, other accounts, computer systems or networks connected to the FREEZE TAG Services, or any part of it, through hacking, password mining or any other means or interfere or attempt to interfere with the proper working of the FREEZE TAG Services or any activities conducted on the FREEZE TAG Service;</p>
    
        <p>7.16 Use any robot, spider, scraper or other automated means to access the FREEZE TAG Services for any purpose without our express written permission or bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the FREEZE TAG Services or modify the FREEZE TAG Services in any manner or form, nor to use modified versions of the FREEZE TAG Services, including (without limitation) for the purpose of obtaining unauthorized access to the FREEZE TAG Services; or</p>
    
        <p>7.17 Sell or transfer or allow another person to access Your account password, profile, or FREEZE TAG Services account.</p>
    
        <p><b>8. USAGE; REFUSAL OR SUSPENSION OF SERVICE</b></p>

        <p>8.1 FREEZE TAG RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO HAVE OUR SYSTEMS MONITOR ANY USER&apos;S REGISTRATION AS WELL AS ANY USER&apos;S USE OF OR ACCESS TO THE LOCATION INFORMATION AND PROFILES OF OTHER USERS. ACCORDINGLY, FREEZE TAG ALSO RESERVES THE RIGHT TO DISABLE ANY USER&apos;S USE OF OR ACCESS TO THE FREEZE TAG SERVICES AND THE LOCATION INFORMATION OR PROFILES OF OTHER USERS, FOR ANY REASON AND WITHOUT ANY NOTICE.</p>

        <p>8.2 You alone are responsible for Your involvement with other Users. FREEZE TAG reserves the right, but has no obligation, to monitor disagreements between You and other Users.</p>

        <p>8.3 FREEZE TAG does not control the content of User accounts and profiles and does not have any obligation to monitor such content for any purpose. You acknowledge that You are solely responsible for all content and material that You provide to the FREEZE TAG Services.</p>

        <p>PLEASE NOTE: FREEZE TAG RESERVES THE RIGHT TO REFUSE ACCESS TO ANY USER, FOR ANY REASON AND WITHOUT ANY NOTICE.</p>

        <p><b>9. USER SUBMISSIONS</b></p>

        <p>9.1 The FREEZE TAG Services allow the submission of content and materials (such as pictures, reviews, ratings, ideas, or notes) by You and other Users ("User Submissions"), and the hosting, sharing and/or publishing of such User Submissions. You shall be solely responsible for Your own User Submissions and the consequences of posting or publishing them. In connection with User Submissions, You affirm, represent, and warrant that: (i) You own, or have the necessary licenses, rights, consents, and permissions to use, and authorize FREEZE TAG to use, all intellectual property and any other proprietary rights in and to any and all User Submissions to enable inclusion and use of the User Submissions in the manner contemplated by the FREEZE TAG Services and this Agreement; and (ii) You have the written consent, release, and/or permission of each and every identifiable individual person in the User Submission to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the User Submissions in the manner contemplated by the FREEZE TAG Services and this Agreement. For clarity, You shall retain all of Your ownership rights in Your User Submissions.</p>

        <p>9.2 By submitting User Submissions to FREEZE TAG Services, You hereby grant FREEZE TAG Inc. a worldwide, non-exclusive, fully paid-up, royalty-free, irrevocable, perpetual, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, publicly perform, publicly display, digitally perform, and otherwise exploit, for any purpose whatsoever, without compensation to You or any other provider, User Submissions in connection with the FREEZE TAG Services and FREEZE TAG Inc.&apos;s (and its successor&apos;s) business, including without limitation for promoting and redistributing part or all of the FREEZE TAG Services (and derivative works thereof) in any media formats now known or hereafter developed, and through any media channels. You also hereby grant each user of the FREEZE TAG Services a non-exclusive license to access Your User Submissions through the FREEZE TAG Services, and to use, reproduce, distribute, prepare derivative works of, display and perform such User Submissions as permitted through the functionality of the FREEZE TAG Services and under this Agreement.</p>

        <p>9.3 You understand that when using the FREEZE TAG Services You will be exposed to User Submissions from a variety of sources, and that FREEZE TAG is not responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such User Submissions. You further understand and acknowledge that You may be exposed to User Submissions that are inaccurate, offensive, indecent or objectionable, and You agree to waive, and hereby do waive, any legal or equitable rights or remedies You have or may have against FREEZE TAG with respect thereto.</p>

        <p>9.4 FREEZE TAG assumes no responsibility whatsoever in connection with or arising from User Submissions. You understand that whether or not such User Submissions are published, FREEZE TAG does not guarantee any confidentiality with respect to any User Submissions. FREEZE TAG assumes no responsibility for actively monitoring User Submissions for inappropriate content. If at any time FREEZE TAG chooses, in its sole discretion, to monitor User Submissions, FREEZE TAG nonetheless assumes no responsibility for the content of the User Submissions, no obligation to modify or remove any inappropriate User Submissions, and no responsibility for the conduct of the User submitting User Submissions. Further, FREEZE TAG does not endorse and has no control over the content of User Submissions submitted by other Users. FREEZE TAG makes no warranties, express or implied, as to the content of User Submissions or the accuracy and reliability of any User Submissions. Nonetheless, FREEZE TAG reserves the right to prevent You from submitting User Submissions and to edit, restrict or remove User Submissions for any reason at any time.</p>
    
        <p>9.5 All user submissions are the sole property of FREEZE TAG, including any data created, collected or stored during said time. User submissions remain the sole property of FREEZE TAG even in the event of refusal or suspension of service by FREEZE TAG.</p>
    
        <p><b>10. THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS</b></p>

        <p>10.1 The FREEZE TAG Services may include links to other web sites or services solely as a convenience to Users. FREEZE TAG does not endorse any such linked sites or the information, material, products or services contained on other linked sites or accessible through other linked sites. Furthermore, FREEZE TAG makes no express or implied warranties with regard to the information, material, products or services that are contained on or accessible through linked sites. Access and use of linked sites, including information, material, products and services on linked sites or available through linked sites is solely at Your own risk.</p>

        <p>10.2 Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the FREEZE TAG Services are solely between You and such advertiser. You agree that FREEZE TAG shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the FREEZE TAG Services.</p>
    
        <p>10.3 Parties other than FREEZE TAG may provide services or sell products via the FREEZE TAG Services. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any of these businesses or the content of their product and service offerings. FREEZE TAG does not assume any responsibility or liability for the actions, product, and content of all these and any other third parties. You should carefully review their privacy statements and other terms and conditions of use. 10.4 In addition, even if FREEZE TAG handles the billing, payment processing and/or fulfillment for the third-party service or product, some products and services are provided under direct license from the third-party and therefore the use of such products and services are governed by such third-party license terms. In such case, You will be subject to such license terms and agree that any matters relating to Your use of such product or service, including any customer support or maintenance, will be between You and such vendor, and FREEZE TAG will have no liability with respect thereto.</p>
    
        <p><b>11. THIRD-PARTY USERS</b></p>

        <p>11.1 LICENSE GRANT, RESTRICTIONS AND TERMS. Upon approval by FREEZE TAG, You will be granted limited access to certain elements of FREEZE TAG&apos;s application program interface as detailed in FREEZE TAG&apos;s Privacy Policy located at <a href="https://www.FREEZE TAG.com/privacy">https://www.FREEZE TAG.com/privacy</a> (“FREEZE TAG API”). FREEZE TAG reserves the right to change the FREEZE TAG API at any time. 1. License Grant. FREEZE TAG hereby grants You a non-exclusive, non-transferable, freely revocable license to use FREEZE TAG API. At all times, FREEZE TAG is the owner of all copyright, trademark and intellectual property rights associated with the FREEZE TAG API.</p>

        <p>11.2 Restrictions. You may use the Munzee API for the sole purpose to aid in the creation and development of mobile applications and software. Any use of the Munzee API for any other purpose is subject to immediate termination. This license grant is only to You and cannot be assigned to or shared with any other entity. Any distribution of the Munzee API is subject to immediate termination and legal action.</p>

        <p>11.3 Licensing Fee. Currently, FREEZE TAG will not charge You a fee to access the Munzee API but may do so in the future. FREEZE TAG will give You a thirty (30) day notice of the fees it will charge You and You agree to pay the assigned fee. If FREEZE TAG implements a fee, You shall provide FREEZE TAG a credit card to be kept on file that will be billed the licensing fee monthly.</p>
    
        <p>11.4 Termination. For the reasons set forth in Paragraphs 11.2 and 13 and for any other reason, FREEZE TAG may immediately terminate Your license to access the Munzee API. Upon termination, you agree to immediately return any such information, if it is in Your control or possession, concerning the Munzee API.</p>
    
        <p>11.5 Terms. You agree to be bound by all other terms set forth in this Agreement and agree that You have read and consent to the Privacy Policy located at <a href="https://www.freezetag.com/privacy">https://www.freezetag.com/privacy</a>.</p>
    
        <p><b>12. END USER LICENSES</b></p>

        <p>12.1 LICENSE GRANT, RESTRICTIONS AND UPGRADES. To use the WallaBee Software You must have a mobile device that is compatible with the FREEZE TAG Services. FREEZE TAG does not warrant that the FREEZE TAG Services will be compatible with Your mobile device. 1. License Grant. FREEZE TAG hereby grants You a non-exclusive, non-transferable, revocable license to use a compiled code copy of the FREEZE TAG Software as follows: You may use the WallaBee Software for one Freeze Tag  Services subscription account (a "Subscription Account" is comprised of the shared resources accessible by a single login ID) on one mobile device owned or leased solely by You, for Your personal use.</p>

        <p>12.2 Restrictions. You may not: (i) modify, disassemble, decompile or reverse engineer the WallaBee Software, except to the extent that such restriction is expressly prohibited by law; (ii) rent, lease, loan, resell, sublicense, distribute or otherwise transfer the Munzee  Software to any third party or use the WallaBee Software to provide time sharing or similar services for any third party; (iii) make any copies of the WallaBee Software; (iv) remove, circumvent, disable, damage or otherwise interfere with security-related features of the Munzee  Software, features that prevent or restrict use or copying of any content accessible through the WallaBee Software, or features that enforce limitations on use of the WallaBee Software; or (v) delete the copyright and other proprietary rights notices on the WallaBee Software.</p>
    
        <p>12.3 Software Upgrades. You acknowledge that FREEZE TAG may from time to time issue upgraded versions of the FREEZE TAG Software, and may automatically electronically upgrade the version of the FREEZE TAG Software that You are using on Your mobile device. You consent to such automatic upgrading on Your mobile device, and agree that the terms and conditions of this Agreement will apply to all such upgrades.</p>
    
        <p>12.4 Open Source. With respect to any open source or third-party code that may be incorporated in the Freeze Tag  Software, such open source code is covered by the applicable open source or third-party EULA, if any, authorizing use of such code.</p>
    
        <p>12.5 Rights Reserved. The foregoing license grant under this Agreement is not a sale of the FREEZE TAG Software or any copy thereof and FREEZE TAG or its third party partners or suppliers retain all right, title, and interest in the FREEZE TAG Software (and any copy thereof). Any attempt by You to transfer any of the rights, duties or obligations hereunder, except as expressly provided for in this Agreement, is void. FREEZE TAG reserves all rights not expressly granted under this Agreement.</p>
    
        <p>12.6 GOVERNMENT END USERS. If this WallaBee Software is being acquired on behalf of the United States Government, then the following provision applies. Use, duplication, or disclosure of the WallaBee Software by the U.S. Government is subject to restrictions set forth in this Agreement and as provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR 52.227-19, or FAR 52.227-14 (ALT III), as applicable.</p>
    
        <p>12.7 EXPORT CONTROL. The Freeze Tag Software originates in the United States, and is subject to United States export laws and regulations. The FREEZE TAG Software may not be exported or re-exported to certain countries or those persons or entities prohibited from receiving exports from the United States. In addition, the FREEZE TAG Software may be subject to the import and export laws of other countries. You agree to comply with all United States and foreign laws related to use of the FREEZE TAG Software and the FREEZE TAG Services.</p>
    
        <p><b>13. VIOLATIONS; TERMINATION</b></p>

        <p>You agree that FREEZE TAG, may terminate any account or subscription (or any part thereof) You have with the FREEZE TAG Services or use of the FREEZE TAG Services or FREEZE TAG API and remove and discard all or any part of Your account or any User Submission, at any time. You further agree that FREEZE TAG may terminate any part of the FREEZE TAG Services if (1) the partner with whom we offered the service to You has terminated its relationship with FREEZE TAG or ceased to offer the service to You, (2) in FREEZE TAG&apos;s opinion, the service is no longer commercially viable, or (3) we are required to do so by law. You agree that any termination of Your access to the FREEZE TAG Services or FREEZE TAG API or any account You may have or portion thereof may be effected without prior notice, and You agree that FREEZE TAG will not be liable to You or any third-party for any such termination. FREEZE TAG does not permit copyright infringing activities on the FREEZE TAG Services, and reserves the right to terminate access to the FREEZE TAG Services, and remove all content submitted, by any persons who are found to be repeat infringers. Any suspected fraudulent, abusive or illegal activity that may be grounds for termination of Your use of the FREEZE TAG Services may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies FREEZE TAG may have at law or in equity.</p>

        <p><b>14. DISCLAIMERS; NO WARRANTIES</b></p>

        <p>THE FREEZE TAG SERVICES, MUNZEE API AND ANY THIRD-PARTY SOFTWARE, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE FREEZE TAG SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" AND WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. FREEZE TAG, AND ITS SUPPLIERS AND PARTNERS (INCLUDING WITHOUT LIMITATION FREEZE TAG&apos;S THIRD-PARTY WIRELESS CARRIER PARTNERS), DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS.</p>

        <p>FREEZE TAG AND ITS SUPPLIERS AND PARTNERS (INCLUDING WITHOUT LIMITATION FREEZE TAG&apos;S THIRD-PARTY WIRELESS CARRIER PARTNERS) DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE FREEZE TAG SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE FREEZE TAG SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
    
        <p>FREEZE TAG, AND ITS SUPPLIERS AND PARTNERS (INCLUDING WITHOUT LIMITATION FREEZE TAG&apos;S THIRD-PARTY WIRELESS CARRIER PARTNERS) DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF ANY LOCATION INFORMATION OR THE FREEZE TAG SERVICES IN TERMS OF SECURITY, SAFETY, CORRECTNESS, ACCURACY, RELIABILITY, AVAILABILITY, OR OTHERWISE. YOU (AND NOT FREEZE TAG OR ITS SUPPLIERS OR PARTNERS) ASSUME THE ENTIRE COST OF ANY NECESSARY SERVICING, REPAIR, OR CORRECTION RELATED TO THE FREEZE TAG SERVICES. YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIAL OR DATA THROUGH THE USE OF THE FREEZE TAG SERVICES AT YOUR OWN DISCRETION AND RISK.</p>
    
        <p>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>
    
        <p><b>15. INDEMNIFICATION; HOLD HARMLESS</b></p>

        <p>You agree to indemnify, defend, and hold FREEZE TAG, its affiliated companies, employees, shareholders, board members, and its suppliers and partners (including, without limitation, FREEZE TAG&apos;s wireless carrier partners) harmless from any and all claims, suits, actions, losses, costs, damages, and any other liabilities, including attorneys&apos; fees, arising out of or related to (a) Your use or misuse of any location information or the FREEZE TAG Services or Munzee API generally, (b) any violation of the rights of any other person or entity by You, (c) any breach or violation by You of this Agreement, or (d) Your use of the FREEZE TAG Services to meet another User in-person or to locate and attend any offline place or event. FREEZE TAG reserves the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify us, and You agree to cooperate with and provide any necessary information for our defense of these claims.</p>

        <p><b>16. LIMITATION OF LIABILITY AND DAMAGES</b></p>

        <p>YOU ACKNOWLEDGE AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, WITHOUT LIMITATION, NEGLIGENCE, WILL FREEZE TAG OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, SHAREHOLDERS, BOARD MEMBERS, AGENTS, OR THIRD-PARTY PARTNERS (INCLUDING, WITHOUT LIMITATION, FREEZE TAG&apos;S WIRELESS CARRIER PARTNERS) OR SUPPLIERS BE LIABLE TO YOU FOR ANY SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, RELIANCE, CONSEQUENTIAL, OR EXEMPLARY DAMAGES RELATED TO OR RESULTING FROM: (A) THE USE, DISCLOSURE, DISPLAY, OR MAINTENANCE OF YOUR LOCATION INFORMATION; (B) YOUR USE OR INABILITY TO USE THE FREEZE TAG SERVICES OR FREEZE TAG API; (C) THE FREEZE TAG SERVICES OR MUNZEE API GENERALLY OR THE FREEZE TAG SOFTWARE OR SYSTEMS THAT MAKE THE FREEZE TAG SERVICES AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH FREEZE TAG OR ANY OTHER USER OF THE FREEZE TAG SERVICES OR MUNZEE API, EVEN IF FREEZE TAG OR A FREEZE TAG AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION WILL SURVIVE ANY TERMINATION OR EXPIRATION OF THIS AGREEMENT AND WILL APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</p>

        <p>IN NO EVENT SHALL FREEZE TAG&apos;S (OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, SHAREHOLDERS, BOARD MEMBERS, AGENTS, SUPPLIERS, OR THIRD-PARTY PARTNERS INCLUDING, WITHOUT LIMITATION, FREEZE TAG&apos;S WIRELESS CARRIER PARTNERS&apos;) TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE FREEZE TAG SERVICES OR MUNZEE API; (WHETHER IN CONTRACT, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE), WARRANTY, OR OTHERWISE EXCEED THE AMOUNTS PAID BY YOU FOR ACCESSING THE FREEZE TAG SERVICES DURING THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DATE OF YOUR CLAIM OR ONE THOUSAND DOLLARS, WHICHEVER IS GREATER.</p>
    
        <p>THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF ANY SERVICES SOLD OR PROVIDED BY THIRD PARTIES OTHER THAN FREEZE TAG AND RECEIVED THROUGH OR ADVERTISED ON THE FREEZE TAG SERVICES OR RECEIVED THROUGH ANY LINKS PROVIDED ON THE FREEZE TAG SERVICES.</p>
    
        <p>APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, YOU AGREE THAT FREEZE TAG&apos;S LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.</p>
    
        <p><b>17. DIGITAL MILLENNIUM COPYRIGHT ACT COMPLIANCE</b></p>

        <p>Digital Millennium Copyright Act Compliance. It is FREEZE TAG&apos;s policy to respond to notices of alleged infringement that comply with the Digital Millennium Copyright Act. In addition, FREEZE TAG will promptly terminate without notice the accounts of Users that are determined by FREEZE TAG to be "repeat infringers." A repeat infringer is a User who has been notified by FREEZE TAG of infringing activity violations more than twice and/or who has had a User Submission removed from the FREEZE TAG Services more than twice.</p>

        <p>If You are a copyright owner or an agent thereof, and You believe that any content hosted on any FREEZE TAG Services infringes Your copyrights, then You may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by providing FREEZE TAG&apos;s Designated Copyright Agent with the following information in writing:</p>
    
        <p>17.1 A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</p>
    
        <p>17.2 Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the applicable FREEZE TAG Services are covered by a single notification, a representative list of such works on the applicable FREEZE TAG Services;</p>
    
        <p>17.3 Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit FREEZE TAG to locate the material;</p>
    
        <p>17.4 Information reasonably sufficient to permit FREEZE TAG to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted;</p>
    
        <p>17.5 A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law (for example, "I am under the good faith belief that the use of the copyrighted content that is identified herein is not authorized by the copyright owner, its agent, or the law."); and</p>
        
        <p>17.6 A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed (for example, "I swear, under penalty of perjury, that the information in this notification is accurate and that I am the copyright owner, or authorized to act on behalf of the copyright owner, of the copyright(s) that is allegedly infringed by the aforementioned content."). FREEZE TAG&apos;s Designated Copyright Agent to receive notifications of claimed infringement can be reached as follows: Attention: Copyright Agent at <a href="mailto:support@freezetag.com">support@freezetag.com</a>. For clarity, only DMCA notices should go to the FREEZE TAG Designated Copyright Agent. Any other feedback, comments, requests for technical support or other communications should be directed to FREEZE TAG customer service through <a href="mailto:support@FREEZETAG.com">support@FREEZETAG.com</a>. You acknowledge that if You fail to comply with all of the requirements of this section, Your DMCA notice may not be valid. Please note that under Section 512(f) of the DMCA, any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability.</p>
    
        <p><b>18. MISCELLANEOUS</b></p>

        <p>18.1 Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the State of Texas, without giving effect to any principles of conflicts of law.</p>

        <p>18.2 Jurisdiction. You agree that any action at law or in equity arising out of or relating to this Agreement or the FREEZE TAG Services shall be filed only in the state or federal courts in and for Collin County, Texas and You hereby consent and submit exclusively to the personal jurisdiction of such courts for the purposes of litigating any such action.</p>
    
        <p>18.3 Severability, Waiver. If any provision of this Agreement shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and shall not affect the validity and enforceability of any remaining provisions. A provision of this Agreement may be waived only by a written instrument executed by the party entitled to the benefit of such provision. The failure of any party at any time to require performance of any provision of this Agreement shall in no manner affect such party&apos;s right at a later time to enforce the same. A waiver of any breach of any provision of this Agreement shall not be construed as a continuing waiver of other breaches of the same or other provisions of this Agreement.</p>
    
        <p>18.4 Notices. You agree that FREEZE TAG may provide You with notices, including those regarding changes to this Agreement, by email, regular mail or postings on the FREEZE TAG Services. Notice will be deemed given twenty-four hours after email is sent, unless FREEZE TAG is notified that the email address is invalid. Notices posted on the FREEZE TAG Services are deemed given and binding 30 days following the initial posting.</p>
    
        <p>18.5 Assignment. This Agreement, and any rights and licenses granted hereunder, may not be transferred or assigned by You, but may be assigned or transferred by FREEZE TAG without restriction.</p>
    
        <p>18.6 Survival. Any and all provisions related to or regarding limitation of liability, disclaimers, and indemnification and, specifically, Sections 1, 4, 7, 8, 9, and 13 through 17 and the Exhibits of this Agreement hereby survive any termination of this Agreement or any termination of Your use of or subscription to the FREEZE TAG Services.</p>
    
        <p>18.7 Headings; Entire Agreement. The heading references herein are for convenience purposes only, do not constitute a part of this Agreement and shall not be deemed to limit or affect any of the provisions hereof. This is the entire agreement between us relating to the subject matter herein and shall not be modified except in writing, signed by both parties, or by a change to this Agreement made by FREEZE TAG as set forth in Section 3 above.</p>
    
        <p>18.8 Claims. YOU AND FREEZE TAG AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE FREEZE TAG SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
    
        <p>18.9 Disclosures. The services hereunder are offered by FREEZE TAG Inc. and can be contacted at <a href="www.freezetag.com">www.freezetag.com</a>.</p>
    
        <p><b>19. EVENTZEE EDU CLAUSE FOR EDUCATIONAL INSTITUTIONS</b></p>

        <p>If You or Your educational institute is participating in the Eventzee EDU scavenger hunt program certain sections of the FREEZE TAG Terms of Service do not apply. The safety of students&apos; personal information is extremely important to our company, so the following are addendums when playing Eventzee through the EDU program.</p>

        <p>19.1  Eventzee EDU can be played by anyone of any age.</p>

        <p>19.2 Eventzee EDU student accounts are anonymously created by approved educational professionals. These accounts do not require any personal information such as names or email addresses.</p>

        <p>19.3 No one but the assigned educational professionals have access to the photos taken during Eventzee EDU photo scavenger hunts.</p>

        <p>19.4 The data from the Eventzee EDU program is not shared with anyone outside of the client&apos;s school, district, campus, etc. This information is not publicly available.</p>

        <p>19.5 Eventzee EDU admins are approved educational professionals. These admins take sole responsibility for any information they share.</p>

        <p>19.6 Eventzee EDU photo scavenger hunt submissions are added to a gallery, but it is the responsibility of the approved educational professional whether or not they share the gallery.</p>
    
        <p>19.7 As a secondary source of protection, SSL (Secure Socket Layer) ensures all Eventzee traffic from within the Eventzee application or Eventzee website to the servers is completely secure. Any photos, clue descriptions, or other sensitive information will not be accessible from outside parties.</p>

        <p>I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.</p>

      </div>
    )
  }
}
