import actionTypes from './actionTypes'

const defaultState = { user: '', password: '', userId: null, token:'' }

const reducer = (state=defaultState,action) => {

  switch (action.type) {
    case actionTypes.UPDATE_USERNAME:
      return { ...state,
        username: action.payload
      }
    case actionTypes.UPDATE_PASSWORD:
      return { ...state,
        password: action.payload
      }
    case actionTypes.LOGIN_SUCCESS:
      return { ...state,
        userId: action.payload.userId,
        token: action.payload.token,
        user: action.payload.username
      }
    default:
      return state
  }
}

export default reducer
