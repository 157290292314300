import React from 'react'
import { connect } from 'react-redux'
import { fetchSetDetail, clearingSetItemsState } from './actions'
import { Link, withRouter } from 'react-router-dom'

const mapStateToProps = (state) => {
  return {
    sets: state.sets,
    profile: state.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetDetail: (userID, setID) => dispatch(fetchSetDetail(userID, setID)),
    clearingSetItemsState: () => dispatch(clearingSetItemsState())
  }
}

class UserSetDetail extends React.Component{
  constructor(){
    super()
    this.state = { search: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e){
    this.setState({search: e.target.value})
  }

  componentWillMount(){
    this.props.fetchSetDetail(this.props.profile.id, this.props.match.params.id)
  }

  componentWillUnmount(){
    this.props.clearingSetItemsState()
  }

  renderSets () {

    var items = this.props.sets.items,
           search = this.state.search.trim().toLowerCase();

       if(search.length > 0){

           items = items.filter(function(l){
               return l.name.toLowerCase().match( search );
           });
       }

    return items.map((setitem,i) => (

       <div key={ i } className={"card set-item prestige_"+setitem.prestige_color}>
         <div className="card-image">
           { setitem.name != "?" ? (
             <Link to={'/tools/item/' + setitem.item_type_id}>
               { setitem.number > 0 ? <img src={setitem.image_url_200} alt={setitem.name} /> : <img className="item-unfound" src={"https://wallazee.global.ssl.fastly.net/images/dynamic/items/" + setitem.item_type_id + "-200.png"} alt="Haven't found this item yet :(" />}
             </Link>
           ) :
             (
               <img src="https://wallazee.global.ssl.fastly.net/images/variant/20140304-e1040f438370b85f0fc358d18041f15eb8b8b5c4f5bedca129577f6-100.png" alt="Unreleased Item" />
             )
           }
         </div>

         <div className="card-content">

           {setitem.number > 0 ? <div className="flex set-detail"><strong className="set-finished">#{setitem.number}</strong><h5>{setitem.name}</h5></div> : <h1>?</h1> }

         </div>
       </div>
        )
  )}

  render() {

    return (
      <div>
        <div className="text-center">
          <input className="banner-search input is-medium" type="text" placeholder="Search Items..." onChange={this.handleChange}/>
          <div>
            <Link className="button outdoors" to={'/u/' + this.props.profile.name + '/sets'}>Back To Your Sets</Link>
          </div>
        </div>
        <div className="flex" id="set-detail">

          {this.renderSets()}

        </div>
      </div>

    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSetDetail))
