import React, { Component } from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { ReactTitle } from 'react-meta-tags'
import PlaceEdu from './places/place-edu'
import PlaceFood from './places/place-food'
import PlaceOutdoors from './places/place-outdoors'
import PlaceRec from './places/place-rec'
import PlaceService from './places/place-service'
import PlaceShopping from './places/place-shopping'
import PlaceSport from './places/place-sport'
import PlaceTravel from './places/place-travel'

export default class Places extends Component {

  render() {

    return (
      <div className="container" id="places">

        <ReactTitle title="Places Guide | WallaBee: Collecting and Trading Card Game on iOS & Android"/>

        <h1 className="text-center">Places Guide</h1>
        <a name="places-guide"></a>

        <div id="place-btn-group">

          <NavLink className="button edu" to="/places/education">Education</NavLink>
          <NavLink className="button food" to="/places/food">Food & Drink</NavLink>
          <NavLink className="button rec" to="/places/recreation">Recreation</NavLink>
          <NavLink className="button services" to="/places/services">Services</NavLink>
          <NavLink className="button sports" to="/places/sports">Sports</NavLink>
          <NavLink className="button outdoors" to="/places/outdoors">Outdoors</NavLink>
          <NavLink className="button travel" to="/places/travel">Travel</NavLink>
          <NavLink className="button shopping" to="/places/shopping">Shopping</NavLink>

        </div>
        <div className="places-wrapper">
          <Switch>
            <Route path={'/places/education'} component={PlaceEdu} />
            <Route path={'/places/food'} component={PlaceFood} />
            <Route path={'/places/recreation'} component={PlaceRec} />
            <Route path={'/places/services'} component={PlaceService} />
            <Route path={'/places/sports'} component={PlaceSport} />
            <Route path={'/places/outdoors'} component={PlaceOutdoors} />
            <Route path={'/places/travel'} component={PlaceTravel} />
            <Route path={'/places/shopping'} component={PlaceShopping} />
          </Switch>
        </div>
        <div className="panel">
          <h2 className="panel-heading">Guidelines</h2>
          <div className="panel-block"><p>Our places database is an ever growing collection of important locations around the world. At the moment, our usage is fairly simple, in that you can forage at one of eight different categories. To this end, and for the purposes of keeping a fair playing field within WallaBee, we have established a number of guidelines for the creation of places. All places are subject to review, by BeeKeepers, who will verify the accuracy of a created place and match it against our guidelines to determine if a place is allowed to appear within WallaBee.</p>

            <p>The guidelines presented below are part of a living document, which means that it will be modified over time. We have finalized a general <a href="#places-guide">Places Guide</a> for how places get approved. This is not an exhaustive list, as there are many grey areas not covered, but following this guide will usually conclude with a verified location. If a location is rejected, you will have the opportunity to appeal to our staff, who have the final say on all place disputes. Remember: The decision from staff will be final.</p>

            <h3><strong>Our core concept is:</strong></h3>
            <p><em>"A place must be a genuine significant physical location with a primary purpose that is accessible to more than just yourself, your family, or your friends. It must have the correct category for its primary purpose and should only be created in one category."</em></p>

            <p>Everything else should flow from this core concept. It is open to multiple interpretations, depending on the place, so we have tried to create some more general guidelines below:</p>

            <h3><strong>Collectives:</strong></h3>
            <p>If there are multiple types of place in one area, they should be grouped into a collective.</p>
            <p>Examples: Departments in a school or university should fall under a collective place for the entire establishment. Similarly, wards in a hospital or GPs in a surgery should be grouped into a singular hospital or medical practice. When deciding on whether to create a new place within an existing collective, it is simplest to look at the categories. When looking at a hospital, you wouldn't add individual wards as you would end up with lots of 'medical' places; those should be grouped into a single place. However, a coffee shop within the hospital can sit separately as it is not the same as the core medical category. There is one exception to this; due to their size, Theme Parks and Zoos can contain the 'Ride / Attraction' category for each major attraction (similar to how a Mall can contain multiple different shops).</p>

            <h3><strong>Private Locations:</strong></h3> <p>For privacy concerns, amongst other reasons, private homes will not be approved. <br/> The only exception to this is with home businesses, but these must be for genuine businesses that conduct meetings with people outside of the household in that location. An easy guide is whether the business lists the address on their website; if they don't, then we might not think of it as a public business and it may not be approved without further verification.</p>
          </div>
        </div>


      </div>
    )
  }
}
