import React from 'react'
import Panel from '../../../components/panel'

var placeArray = [

  {
      'name': 'ASIAN',
      'desc': ['Asian restaurants: e.g., Chinese, Korean, Sushi, Indian, Thai, Vietnamese, etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/8-51e53291-6da4-4a44-9ce9-038bc63dd4aa-50.png',
      'id': 1
  },
  {
      'name':'BAKERY',
      'desc': ['Donut shops', 'Bakeries/boulangeries'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160503-06b666715034f92d3eeb6686fffb24215f8cb705733bcccfe59523e-50.png',
      'id': 2
  },
  {
      'name':'BURGER',
      'desc': ['Primarily burger restaurants, e.g., McDonald’s, Arby’s, Wendy’s, etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/9-51e532dc-94e0-4544-b588-070fc63dd4aa-50.png',
      'id': 3
  },
  {
      'name':'CHICKEN',
      'desc': ['Primarily chicken restaurants, e.g., KFC, Popeye’s, Bojangles, Chick-fil-A, etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/10-51e532e9-a530-4003-a1da-09aac63dd4aa-50.png',
      'id': 4
  },
  {
      'name':'DESSERT',
      'desc': ['Dessert restaurants','Chocolate shops','Ice cream/gelato shops (e.g., Dairy Queen)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/12-51e53310-e654-4fa9-9250-095fc63dd4aa-50.png',
      'id': 5
  },
  {
      'name':'FOOD & DRINK STORE',
      'desc': ['Grocery stores','Bulk stores','Farmer’s markets with a fixed structure','Liquor stores','Convenience stores (e.g., 7-Eleven), including at gas stations where separate'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-6b58541f47513a57117fce99d6164037409f3730a366fcef39e2f5f-50.png',
      'id': 6
  },
  {
      'name':'PIZZA',
      'desc': ['Primarily pizza restaurants (takeaway and sit-in), e.g., Pizza Hut, etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/13-51e5331e-87c0-4e5b-a093-09abc63dd4aa-50.png',
      'id': 7
  },
  {
      'name':'PUB/BAR',
      'desc': ['Primary focus is on serving alcohol, not food','Breweries/wineries/distilleries','NOT: chain restaurants (e.g., Chili’s, which simply happen to serve alcohol (restaurant)), Veterans of Foreign Wars (VFW)/Legions (recreation/other), liquor stores (food/drink store), etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/19-51e5340e-7e78-45b3-a5a8-0c9bc63dd4aa-50.png',
      'id': 8
  },
  {
      'name':'RESTAURANT',
      'desc': ['Primarily sit-down restaurants that do not fit into other categories','NOT: cafeteria; food courts'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/14-51e53334-e620-4246-8672-095bc63dd4aa-50.png',
      'id': 9
  },
  {
      'name':'TAKEAWAY',
      'desc': ['Sandwich shops (e.g., Subway, Jimmy John’s, etc.)','Shawarma shops','Smoothie shops','Taco and Burrito shops (e.g., Taco Bell)','Food court restaurants not fitting above categories','Note: Food type (e.g., Asian) supersedes takeaway category'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/17-51e533da-c224-4319-b69b-0c5bc63dd4aa-50.png',
      'id': 10
  },
  {
      'name':'TEA & COFFEE',
      'desc': ['Tea and Coffee (e.g., Starbucks, Tim Hortons)','Bubble tea, Boba tee','NOT: Small, unbranded coffee shops in hospitals/schools, etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/11-51e532f9-81f4-469b-a540-09ccc63dd4aa-50.png',
      'id': 11
  },
  {
      'name':'VEGETARIAN',
      'desc': ['Strictly vegetarian restaurants','NOT: vegetable vendors (food/drink stores)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/18-51e533f1-5ac8-4ac7-bfc1-0c95c63dd4aa-50.png',
      'id': 12
  }
  ]

export default class PlaceFood extends React.Component {
    render(){
      const places = placeArray.map((place,i) => {
        return <Panel key={i} {...place}/>
      })
      return(
        <div>
          <div className="text-center">
            <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/7-51e531bb-7380-41e0-bd72-05ccc63dd4aa-200.png" alt="Food & Drink"/>
            <h2>Food & Drink</h2>
          </div>
          {places}
        </div>
    )
  }
}
