import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

export default class Rules extends Component {
  render() {
    const token = JSON.parse(localStorage.getItem('token'))
    var loggedInUser = JSON.parse(localStorage.getItem('username'));

    var theMessage = "Thank you for purchasing a WallaBee NFT! If you are new here, welcome! Please download our app and create an account to get started, then come back here and login to your new account. Once you are logged in, enter the code from your NFT into the following page to receive 100 of your Build-A-Unique NFT added to your account. Once redeemed, the items will appear in your LockBox, accessible in-game from your pouch!";

    if (token) {
      return (
        <div className="container" id="rules">
          <MetaTags>
            <title>NFT | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
          </MetaTags>

          <div className="text-center">
            <h1>Welcome to WallaBee!</h1>
          </div>
          <img src='https://wallazee.global.ssl.fastly.net/images/site/NFT-collection-banner-website.png' />
          <ul className="panel">
            <div className="panel-heading"><strong>Congrats on your Purchase of an NFT!</strong></div>
            <li className="panel-block">
              {theMessage}
              <span className="text-center">
                <NavLink className="button edu badge is-badge-small is-badge-danger flex" to={'/u/' + loggedInUser + '/redeem'}>Redeem Code Page</NavLink>
              </span>
            </li>
          </ul>

        </div>
      )
    }
      else
    {
      return (
        <div className="container" id="rules">
          <MetaTags>
            <title>NFT | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
          </MetaTags>

          <div className="text-center">
            <h1>Welcome to WallaBee!</h1>
          </div>
          <img src='https://wallazee.global.ssl.fastly.net/images/site/NFT-collection-banner-website.png' />

          <ul className="panel">
            <div className="panel-heading"><strong>Congrats on your Purchase of an NFT!</strong></div>
            <li className="panel-block">
            {theMessage}
              <span className="text-center">
                <NavLink className="button outdoors flex" to="/login">Login</NavLink>
              </span>
            </li>
          </ul>

        </div>
      )
    }
  }
}
