import React, { Component } from 'react'
import MetaTags from 'react-meta-tags';

export default class Faq extends Component {

  render() {

    return <div className="container" id="faq">

      <MetaTags>
        <title>WallaBee: Collecting and Trading Card Game on iOS & Android</title>
      </MetaTags>

      <div className="text-center"> <h1>Support</h1>
        <p>If you experience any problems with WallaBee, have some suggestions, or just have some general queries, please contact us directly via <a href="mailto:support@wallabeegame.com">support@wallabeegame.com</a>. We aim to get back to all enquiries within a few hours.</p>
        <p>Please do not raise support issues in the forums within the app or by messaging our staff directly as that may lead to your query being missed or delayed.</p>

        <a className="button is-primary" target="_blank"  href="https://wallabee.zendesk.com/hc/en-us">Support Website</a>
    </div>
      <div className="panel">
        <h1 className="panel-heading">FAQ</h1>
        <div className="panel-block">
          <div className="columns" id="faq-questions">
            <div className="column is-half">
              <ul>
                <li><a href="#faq1">&bull; Why isn&apos;t there a subcategory for ___?</a></li>
                <li><a href="#faq2">&bull; What do all these abbreviations stand for?</a></li>
                <li><a href="#faq3">&bull; How do I get items to complete my sets?</a></li>
                <li><a href="#faq4">&bull; What does RB mean?</a></li>
                <li><a href="#faq5">&bull; Why can&apos;t I recycle an item I just picked up?</a></li>
                <li><a href="#faq6">&bull; What does it mean when someone says they mixed something?</a></li>
                <li><a href="#faq7">&bull; What&apos;s the difference between the Store and the Market?</a></li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li><a href="#faq8">&bull; Why do some players have more pouch space than I do? </a></li>
                <li><a href="#faq9">&bull; What is a variant?</a></li>
                <li><a href="#faq11">&bull; How do I get Christmas Party Hats?<br/>&bull; What is Cracking a Cracker??</a></li>
                <li><a href="#faq12">&bull; Why are people mixing things with Wishing Wells?<br/>&bull; How do I get miniest mystery boxes or mystery bags?</a></li>
                <li><a href="#faq13">&bull; Why won&apos;t Job Lots mix with Wishing Wells?</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <a name="faq1"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: Why isn&apos;t there a subcategory for ____ ?
        </div>
        <div className="panel-block">
          <p className="faq-answer">Place categories and subcategories are chosen based on the most common locations you might interact with. We are always interested in hearing ideas for new subcategories, as long as they fit within our place guidelines.
          </p>
        </div>
      </div>
      <a name="faq2"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: I keep seeing strange abbreviations... What do they mean?
        </div>

        <div className="panel-block">
          <div className="columns">

            <div className="column is-half">

              <div className="faq-answer">
                The community has created some shorthand for different aspects of the game;
                Some involve types of items:
                <ul>
                  <li><strong>QD</strong> = Quad Digit Item</li>
                  <li><strong>TD</strong> = Triple Digit Item</li>
                  <li><strong>DD</strong> = Double Digit Item</li>
                  <li><strong>SD</strong> = Single Digit Item </li>
                </ul>
                <br/>
                While others refer to gameplay:<br/><br/>
                <ul>
                  <li><strong>Drops</strong> = New Item Release</li>
                  <li><strong>Ninja</strong> = Hidden User</li>
                  <li><strong>Number Collector</strong> = Player who collects a certain number or series of numbers</li>
                  <li><strong>Mix</strong> = Mixing two or more items together to create a new type of item</li>
                  <li><strong>Mega Mix</strong> = Mixing more than two items together to create a new type of item</li>
                  <li><strong>Cross Mix</strong> = Mixing two items from different sets to create a new type of item</li>
                </ul>
              </div>
            </div>

            <div className="column">
              <figure className="has-text-centered">
                <img className="faq-item" src="https://wallazee.global.ssl.fastly.net/images/site/wallabee_singledigititem-1.png" alt="Single Digit Item"/>
                <figcaption><small>Single Digit Item</small></figcaption>
              </figure>
            </div>
          </div>

        </div>
      </div>

      <a name="faq3"></a>
      <div className="panel">
        <div className="panel-heading">
          Q:How do I get items to complete my sets?
        </div>
        <div className="panel-block">
          <p className="faq-answer">There are three main ways to get items:</p>


          <div className="faq-items">
            <div><p>Foraging them from nearby locations</p><img className="faq-item" src="https://wallazee.global.ssl.fastly.net/images/site/wallabee_forage.png" alt="Foraging from nearby locations"/></div>

            <div><p>Buying them from the in-game stores</p> <img className="faq-item" src="https://wallazee.global.ssl.fastly.net/images/site/wallabee_wallastore.png" alt="Buy items from in-game stores"/></div>

            <div><p>Mix items together to make another item</p><img className="faq-item" src="https://wallazee.global.ssl.fastly.net/images/site/wallabee_mixing.png" alt="Mix items!"/></div>
          </div>
        </div>
      </div>

      <a name="faq4"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: I saw someone say RB... What does that mean?
        </div>
        <div className="panel-block">
          <p className="faq-answer">
            RB is an abrreviation for <a href="https://itembrowser.com/rarebee.php" target="_blank" rel="noopener noreferrer">RareBee</a>, which is a ranking system developed by those at ItemBrowser to show how rare an item is. When Item A is mixed with Item B to create Item C, both Item A & Item B are destroyed from the game forever. This makes Item A & Item B rarer than Item C. You can see a list of RareBees by visiting <a href="https://itembrowser.com/" target="_blank" rel="noopener noreferrer">Itembrowser.com</a>.</p>
        </div>
      </div>

      <a name="faq5"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: Why can&apos;t I recycle an item I just picked up?
        </div>
        <div className="panel-block">
          <p className="faq-answer">
            While we understand you may want to clear out unwanted items near you, there is a one minute buffer between picking up an item and being able to recycle it. We have this in place to help prevent unnecessary recycling of other players&apos; "stashes."
          </p><br/>
          <figure className="text-center">  <img className="faq-item" src="https://wallazee.global.ssl.fastly.net/images/site/wallabee_recycle.png" alt="Recycling items"/></figure>
        </div>
      </div>

      <a name="faq6"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: What does it mean when someone says they mixed something?
        </div>
        <div className="panel-block">
          <p className="faq-answer">
            In WallaBee, the word mix is used in multiple ways. You can mix two items together to created a new one or you can receive an item from the mixerpool (mixing two mixers together can generate older items in the mixerpool). Receiving an item from the mixerpool is usually referred to as mixing an item, as it was generated from mixers.
          </p>
        </div>
      </div>

      <a name="faq7"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: What&apos;s the difference between the Store and the Market?
        </div>
        <div className="panel-block">
          <p className="faq-answer">The in-game stores generate items randomly. When purchased, they will issue the next number to be generated for that item type. The Market is a player-based area of the game, which allows players to set a certain price for an item they want to get rid of.
          </p><br/>
          <figure className="text-center"><img className="faq-item" src="https://wallazee.global.ssl.fastly.net/images/site/faq-6.png" alt="You can sell your items in the Market"/></figure>
        </div>
      </div>

      <a name="faq8"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: Why do some players have more pouch space than I do?
        </div>
        <div className="panel-block">
          <p className="faq-answer">We offer upgrades through the use of our <a href="https://store.munzee.com/collections/wallabee" target="_blank" rel="noopener noreferrer">online store</a>, which allow players to buy Honeycombs, Locks, and Pouch Upgrades. Some players have invested money into the game in order to upgrade the amount of items their pouch can hold.</p>
        </div>
      </div>

      <a name="faq9"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: What is a variant?
        </div>
        <div className="panel-block">
          <p className="faq-answer">A variant is different piece of artwork for the same item. There are numerous items that have multiple appearances. For example, when mixing the Easter Hen (Ingredient 1) with an egg from the Eggception set (Ingredient 2), the resulting Easter Chick will be in the egg color that was used for Ingredient 2.
          </p>

          <div className="level is-mobile" id="faq-10">
            <div className="level-item"> <img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/1325-200.png" alt="Easter Hen"/></div>
            <span className="level-item">+</span>
            <div className="level-item">
              <img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/159-200.png" alt="Aquamarine Egg"/>
            </div>

            <span className="level-item">=</span>
            <div className="level-item">

              <img src="https://wallazee.global.ssl.fastly.net/images/site/aquamarinechick.png" alt="Aquamarine Chick"/>
            </div>
          </div>

        </div>
      </div>

      <a name="faq11"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: How do I get Christmas Party Hats? What is Cracking a Cracker??
        </div>
        <div className="panel-block">
          <p className="faq-answer">There is a special game mechanic centered around Christmas Crackers and Christmas Party Hats from the <a href="/tools/sets/141">Cracking Under Pressure</a> set. Much like a real cracker it involves two people to "crack" the cracker and thus get the reward inside. The way this is handled in game is between two players trading a <a href="/tools/item/11">Christmas Cracker</a> in game. Player A sends a trade for a Cracker in Player B&apos;s Pouch using Item A in Player A&apos;s pouch. When Player B accepts the trade offer from Player A the Item offered by Player A (Item A) is traded to Player B as expected. What happens next is the special mechanic, The Cracker is destroyed and randomly either Player A or Player B will receive a Random Party Hat in their pouch.
          </p>
        </div>
      </div>

      <a name="faq12"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: Why are people mixing things with Wishing Wells?<br/>How do I get miniest mystery boxes or mystery bags?
        </div>
        <div className="panel-block">
          <p className="faq-answer">
            There is a special game mechanic with adding items or "wishes" to the <a href="/tools/item/2194">Wishing Well</a>. Wishing Wells can be mixed with any <b>WallaBee issued item</b> that has a number 10000 or over. The exceptions are Job Lot, Aftershave, and Perfume. Once you add 50 items or "wishes" to a Wishing Well you can Redeem the wishes for one of the following random 8 items: <a href="/tools/item/553">Mystery Box</a>, <a href="/tools/item/2389">Mystery Bag</a>, <a href="/tools/item/3232">Miniest Mystery Box (BZ)</a>, <a href="/tools/item/3235">Miniest Mystery Box (SL)</a>, <a href="/tools/item/3238">Miniest Mystery Box (GD)</a>, <a href="/tools/item/2091">Bronze Mixer</a>, <a href="/tools/item/2090">Silver Mixer</a>, and <a href="/tools/item/2089">Gold Mixer</a>,.
          </p>
        </div>
      </div>

      <a name="faq13"></a>
      <div className="panel">
        <div className="panel-heading">
          Q: Why won&apos;t Job Lots mix with Wishing Wells?
        </div>
        <div className="panel-block">
          <p className="faq-answer">
            <a href="/tools/item/1648">Job Lots</a> won&apos;t mix with <a href="/tools/item/2194">Wishing Wells</a> due to the fact they also have a special game mechanic. Job Lots can mix with <b>any item (including personal uniques)</b> that has a number of 2000 or over. The exceptions are Aftershave, Perfume, and Wishing Wells. When a Job Lot is mixed with a valid item it will produce a random item using the next available number.

          </p>
        </div>
      </div>


      </div>
  }
}
