import React from 'react'
import { connect } from 'react-redux'
import { fetchUserPouchSuccess } from '../actions'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchUserPouchSuccess: () => dispatch(fetchUserPouchSuccess()),
  }
}

class ProfileFriends extends React.Component{
  constructor(){
    super()
    this.state = { search: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e){
    this.setState({search: e.target.value})
  }

  renderUserPouch () {

    var libraries = this.props.profile.items,
           search = this.state.search.trim().toLowerCase();

       if(search.length > 0){

           libraries = libraries.filter(function(l){
             if (isNaN(search)) {
               return l.name.toLowerCase().match( search );
             } else {
               return l.number.toString().match( search );
             }
           });
       }

    return libraries.map( item => (
      <div key={item.id} className={"card prestige_"+item.prestige_color}>
          <div className="card-image">
            <Link to={"/tools/item/history/" + item.id}>
              <img src={item.image_url_200}  alt={item.name}/>
            </Link>
          </div>

          <div className="card-content">

            <div className="achievement-info">
              <h5>#{item.number}</h5>
              <Link to={"/tools/item/history/" + item.id}>
                <h5>{item.name} {item.status=='LOCKED' && <i className='fa fa-lock' aria-hidden='false'></i> }</h5>
              </Link>

            </div>
          </div>
        </div>

    ))}

  render() {

    var pageTitle=this.props.profile.name + "'s Pouch | WallaBee: Collecting and Trading Card Game on iOS";

    return (
      <div>
        <div className="text-center">
          <input className="banner-search input is-medium" type="text" placeholder="Search Your Items..." onChange={this.handleChange}/>
        </div>

        <div className="flex" id="friends">
          <MetaTags>
            <title>{pageTitle}</title>
          </MetaTags>

          {this.renderUserPouch()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFriends)
