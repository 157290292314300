import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import { connect } from 'react-redux'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

class UniqueGifting extends Component{
 constructor(){
    super()
    this.state = {uniques:[], infoLoaded: false, itemMap:[], itemVarMap:[], itemNameMap:[], uname:[], uid: 0, giftResult: "", itemCreated:[],itemRandomMap:[],totalUniques: 0}
    this.fetchGiftable = this.fetchGiftable.bind(this)
  }

 	fetchGiftable(){
    const key = utilities.apiKey;
		const giftableUniques = endpoints.uniques;
	  const token = JSON.parse(localStorage.getItem('token'))

		fetch(giftableUniques,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
    		.then(response => response.json())
    	  	.then(json => {
    	  		var itemCounter = 0
				var itemMap = []
				var itemVarMap = []
				var itemNameMap = []
				var itemRandomMap = []

				json.map( unique => {
					if (unique.variant_id > 0) {
						itemRandomMap[unique.id] = unique.variant_id
						if (unique.variant_id === 1) {
							itemMap[itemCounter]=unique.id
							itemVarMap[itemCounter]=0
							itemNameMap[itemCounter]=unique.item_name + " - RANDOM"
							itemCounter++
							itemMap[itemCounter]=unique.id
							itemVarMap[itemCounter]=unique.variant_id
							itemNameMap[itemCounter]=unique.item_name + " - " + unique.variant_name
						} else {
							itemMap[itemCounter]=unique.id
							itemVarMap[itemCounter]=unique.variant_id
							itemNameMap[itemCounter]=unique.item_name + " - " + unique.variant_name
						}
					} else {
						itemRandomMap[unique.id] = 0
						itemMap[itemCounter]=unique.id
						itemVarMap[itemCounter]=0
						itemNameMap[itemCounter]=unique.item_name
					}
					itemCounter++
				})
				this.setState({
					uniques: json,
					totalUniques: itemCounter,
					itemMap: itemMap,
					itemVarMap: itemVarMap,
					itemNameMap: itemNameMap,
					itemRandomMap: itemRandomMap,
					infoLoaded: true
				})
    	})
	}


 giftItem(id,vid,user,itemName){
  const key = utilities.apiKey;
	const giftableUniques = endpoints.uniques+"/send";
	const token = JSON.parse(localStorage.getItem('token'))


	if (vid > 0) {
		var gift = { 'item_type_id': id, 'variant_id': vid, 'username': user }
	} else {
		gift = { 'item_type_id': id, 'username': user }
	}

	var formBody = [];
	for (var property in gift) {
	  var encodedKey = encodeURIComponent(property);
	  var encodedValue = encodeURIComponent(gift[property]);
	  formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");
    fetch(giftableUniques,{method:'POST',
    headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token,  'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded'},
    body: formBody })
      .then(response => response.json())
      .then(json => {
   		if (json.error) {
			console.log(json.error)
			var resultText = <div className='travel' style={{'color':'white', 'padding':'5px'}}>Error: {json.error}</div>
		} else {
			var targetNum=json[0].number
			console.log(targetNum)
			resultText = <div className="message is-success">
        <div className="message-header">
          <strong>Success!</strong>
        </div>
        <div className="message-body">
          {itemName} #{targetNum} created and sent to {user}
        </div>
      </div>
		}
	        this.setState({
	          itemCreated: json,
	          giftResult: resultText
	        });
      });
  }

	componentDidMount() {
		this.fetchGiftable()
	}

	buildGiftList() {

		var uniques = this.state.uniques;
		var outputHTML = []
    	var itemCounter = 0

		uniques.map( unique => {
			if (unique.variant_id > 0) {
				if (unique.variant_id === 1) {
					outputHTML.push (<option key={itemCounter} value={itemCounter}>{unique.item_name} - RANDOM</option>)
					itemCounter++
					outputHTML.push (<option key={itemCounter} value={itemCounter}>{unique.item_name} - {unique.variant_name}</option>)
				} else {
					outputHTML.push (<option key={itemCounter} value={itemCounter}>{unique.item_name} - {unique.variant_name}</option>)
				}
			} else {
				outputHTML.push (<option key={itemCounter} value={itemCounter}>{unique.item_name}</option>)
			}
			itemCounter++
		})

		return <select name='unique' id='unique' onChange={this.targetUnique.bind(this)}>{outputHTML}</select>
	}


	targetUnique(event) {
		var target = event.target.value
		this.setState({uid: target})
	}


	doGift() {
		var itemMap = this.state.itemMap
		var itemVarMap = this.state.itemVarMap
		var itemNameMap = this.state.itemNameMap
		var itemRandomMap = this.state.itemRandomMap
		var uid = this.state.uid
		var uname = this.state.uname

		var targetItem = itemMap[uid]
		var targetVar = itemVarMap[uid]
		var targetName = itemNameMap[uid]
		var targetNum = 0

		if ((itemRandomMap[targetItem] > 0)&&(targetVar === 0)) {
			var maxVar = itemRandomMap[targetItem]
			var randomUid = ((Math.floor(Math.random() * maxVar)+1)+uid);
			targetVar = itemVarMap[randomUid]
			targetName = itemNameMap[randomUid]

		}


	 	console.log("User: " + uname + ", ID: " + targetItem + ", VAR: " + targetVar)

		if (uname === "") {
			var resultText = <div className="message is-danger">
        <div className="message-header">
          <strong>Error</strong>
        </div>
        <div className="message-body">
          You must specify a user.
        </div>
      </div>
	        this.setState({
	          giftResult: resultText
	        });

		} else {
			this.giftItem(targetItem,targetVar,uname,targetName)
		}
	}

  render() {

    var pageTitle = this.props.profile.name + "'s Uniques | WallaBee: Collecting and Trading Card Game on iOS & Android";
		var targetPath = "/u/"+ this.props.profile.name +"/giftunique";
		var currentPath = this.props.location.pathname

		if (targetPath === currentPath) {
  			if (this.state.infoLoaded === false) {
    			return (
      				<div className="text-center" id="unique-gifting">
        				<MetaTags>
                  <title>{pageTitle}</title>
        				</MetaTags>
        				<h1>Loading Items...</h1>
      				</div>
    			)

			} else {
				var giftable=this.buildGiftList();
				var result=this.state.giftResult
				var count=this.state.totalUniques

				if (count> 0) {
	    			return (
              <div id="unique-wrapper">
    	  				<div className="text-center" id="unique-gifting">
        					<MetaTags>
                    <title>{pageTitle}</title>
        					</MetaTags>

        					<img src="https://wallazee.global.ssl.fastly.net/images/collections/S25-20190218-feea75367d46ff22eb9a3892f32c1ef0f718004f0380c0cfb7d645e-100.png" alt="Gift a Unique!"/>
	        				<h2 className="title is-2">Gift a Unique Item</h2>
    	    				<p>To gift a unique item, simply enter a username below and select the item you wish to gift. The item will automatically appear in the recipients pouch.</p>
        					<p>{result}</p>


                  <form id="gift">
                    <div className="field">
                      <p className="control">
                        <input className="input is-medium text-center" type="text" name="username" placeholder="Enter Username..." onChange={(e)=>this.setState({uname: e.target.value})}/>
                      </p>
                    </div>
                    <div className="field text-center">
                      <p className="text-center">
                        <span className="select is-medium">
                          {giftable}
                        </span>
                      </p>
                    </div>
                    <a className="button outdoors" onClick={this.doGift.bind(this)}>Gift Unique</a>
                  </form>

    	  				</div>
              </div>
    				)
    			} else {
	    			return (
    	  				<div className="text-center" id="unique-gifting">
        					<MetaTags>
                    <title>{pageTitle}</title>
        					</MetaTags>

        					<img src='https://wallazee.global.ssl.fastly.net/images/collections/-51641053-c4f8-4ced-9014-7d59c63dd4aa-100.png' alt='No Uniques to Gift'/>
	        				<h1>Gift a Unique Item</h1>
    	    				<p>You currently do not own any Uniques that you are able to gift.</p>
    	  				</div>
    				)

    			}
			}
		}
  }
}

export default connect(mapStateToProps)(UniqueGifting)
