import React from 'react'
import MetaTags from 'react-meta-tags'

class Uniques extends React.Component {

  render() {

    // if (!localStorage.getItem('token')) {
    //
    //   var loginBtn = <NavLink className="button outdoors" to="/login">Login</NavLink>
    //
    // } else {
    //
    //   var loggedInUser = JSON.parse(localStorage.getItem('username'));
    //
    //   var uniquesBtn = <NavLink className="button outdoors" to={'/u/' + loggedInUser + '/giftunique'} onClick={fetchProfile()}>Go to Your Uniques</NavLink>
    // }

    return (
    <div className="container" id="uniques">
      <MetaTags>
        <title>Uniques | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
      </MetaTags>

      <div className="message is-info">
        <div className="message-header">
          <strong>Looking to Gift Your Unique?</strong>
        </div>
        <div className="message-body">
          For those looking to gift their Unique Item, login to your profile and hit the Unique Gifting tab!<br/>
          {/* {loginBtn}
          {uniquesBtn} */}
        </div>
      </div><br/>
      <h1 className="text-center">Unique Items</h1>

      <ul className="panel">

        <strong className="panel-heading">What are Uniques?</strong>

        <li className="panel-block">WallaBee first introduced Unique Items in November 2012 when we helped one of our players commemorate their wedding anniversary. Since then, Unique Items have grown into a popular option for players looking to celebrate a special occasion, collect lower numbers, or add to their profile and collection.

        In 2019, Unique Items were overhauled to fit WallaBee&apos;s growth. Players can now highlight their items in "Themed Unique" subsets!

        Each Unique is handcrafted by Andy, based on a collaboration between the player&apos;s original vision and Andy&apos;s artistic style. Players aren&apos;t the only ones who get to create Unique Items, as WallaBee staff have issued a fair amount of Uniques into the game for various reasons.

        Build-A-Unique Items have allowed players to create their own Unique at a lower cost! These items are categorized under "Themed Unique Items," as they are still Unique Items.
        </li>
      </ul>

      <p className="text-center">Here are the common definitions for Uniques you will find in-game:</p>

      <ul className="panel">
        <strong className="panel-heading">Player Uniques</strong>

				<li className="panel-block">Defined as an extra item, outside of normal set items, obtainable through other means. They are not always available for all players.</li>
				<li className="panel-block">Players are periodically given the opportunity to commission an item, which is maintained solely by that player (or a group of players). This includes complete control of distribution as they see fit, as long as it fits within WallaBee terms and conditions.</li>
				<li className="panel-block">Some Player Uniques are created by WallaBee Staff for special events, moments, or competitions, such as the WallaBacker, Birthday Cake(s), and Wolwocks House Cup.</li>
				<li className="panel-block">Player Uniques are distributed how the owner sees fit.</li>
			</ul>

      <p className="text-center">Players commission Unique items for many reasons, but we&apos;d like to highlight some of our favorite reasons:</p>

      <ul className="panel">
        <h2 className="panel-heading">Special Occassions</h2>
        <li className="panel-block">A Unique item is the perfect way to celebrate a special occassion from anniversaries and engagements to birthdays and graduations. We can surprise your loved one by making the item magically appear at a specific time and place when they forage, or give you the option to gift it directly to them.
          <div className="text-center">
            <a href='/tools/item/1786'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20150502-b33f017548e72defe715940d984f163582c001e82b5776784b8103f-200.png" alt="Hugs"/></a>
            <a href='/tools/item/2044'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160414-fd01a5814d4dc88869b87826f0510690c3507d3bc3e4894e5e4b702-200.png" alt="ToysRGood&apos;s Karate Kid"/></a>
            <a href='/tools/item/2077'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160414-82b520460776675e597d5a0e5d80d7ae3fc916e5de317df49ba1770-200.png" alt="Always and Forever..."/></a>
          </div>
        </li>
      </ul>

      <ul className="panel">
        <h2 className="panel-heading">Local Area</h2>
        <li className="panel-block">Perhaps you want to show off a landmark in your local area? A unique item can be used to highlight a specific place that is special to you and we&apos;ll even use it as the artwork for the place if you wish!
          <div className="text-center">
            <a href='/tools/item/1945'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160414-a97212f00b9ebaa1cac033864998531959877af19126685c93f446e-200.png" alt="Bran Castle"/></a>
            <a href='/tools/item/2125'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160602-4d403f6c1bcbbc9c245a71be56199fd122643eec761428e999087ef-200.png" alt="Bayon Temple"/></a>
            <a href='/tools/item/2084'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160415-2a4004ebb4dd463a0912a863b1d93f6b0daac98721e105ef7c98d75-200.png" alt="Solvang"/></a>
          </div>
        </li>
      </ul>

      <ul className="panel">
        <h2 className="panel-heading">
        Friends</h2>
        <li className="panel-block">
          WallaBee is an incredibly social game so it&apos;s great to see people using Unique items as a way to say "thank you" to their fellow players. We&apos;re happy to work with you to think of new ways to distribute your items; in the past, we&apos;ve allowed Unique item owners to recycle (including recycling to specific categories), or make the item forageable in a certain town or area.
          <div className="text-center">
            <a href='/tools/item/2127'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160705-9027bc07420dd78674752063c597b20ebfca6391df47ff20e67d4d2-200.png" alt="The Number Protector"/></a>
            <a href='/tools/item/2034'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160414-c21c799654c35765852e2fe2f833ffda02987d1eed0854360abf1ff-200.png" alt="Abby Grace"/></a>
            <a href='/tools/item/2037'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160414-37ba21c3f065d8ec222204477a7da1c454a723b280522351ab7ec1a-200.png" alt="Wild Child"/></a>
          </div>
        </li>
      </ul>

      <ul className="panel">
        <h2 className="panel-heading">
        Trades</h2>
        <li className="panel-block">
          <p>Unique items are a great way to help you get that specific number you&apos;re collecting as they are highly collectible. What numbers do you collect?</p>
          <div className="text-center">
            <a href='/tools/item/2035'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20160414-b1ac7311f36c6745a87ec4112df60edfccca779871677b0d0ad91d9-200.png" alt="Coffee with Gigi"/></a>
            <a href='/tools/item/1790'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20150511-c5500fbac50f64e5ef983c9b94b44ce0082f82e2b2ea2dd49c5368a-200.png" alt="Best Friend Kitty"/></a>
            <a href='/tools/item/1005'><img src="https://wallazee.global.ssl.fastly.net/images/variant/20140417-6df6ecd87de5610e21598d1ac620c72afba2cb03b3ead028cec2b77-200.png" alt="Willie Nelson"/></a>
          </div>
        </li></ul>

      <article className="message is-danger text-center">
        <div className="message-header">
          <strong>Unique Commissions: Closed</strong>

        </div>
        <div className="message-body">

	To commission a Unique of your own, please read through the details available on <a href="https://www.wallabeeblog.com/2018/12/12/commission-information/">the blog</a>.


        </div>
      </article>
      <br/>
    </div>
    )
  }
}

export default Uniques
