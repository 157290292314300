import React from 'react';
import MetaTags from 'react-meta-tags'

export default class NameSearch extends React.Component{
  constructor(){
    super()
    this.state = {targetName:[], searchForItem: false}

  }

   searchItem(){
	  	var nameSearch=this.state.targetName;
  		if (nameSearch != null) {
	  		var targetURL = '/tools/search/'+nameSearch;
  			this.props.history.push(targetURL);
  		}
  }

  render() {
    return <div className="container" id="search">

      <MetaTags>
        <title>Search Items - WallaBee | WallaBee: Collecting and Trading Card Game on iOS</title>
      </MetaTags>

      <div className="text-center">
        <form id="search">
          <input className="banner-search input is-medium" type="text" placeholder="Search for Items..." onChange={(e) => this.setState({ targetName: e.target.value})}/><br/>
          <button className="button outdoors" id="nameSearch" onClick={this.searchItem.bind(this)}>Search</button><br/><br/>
        </form>

      </div>

    </div>
  }
}
