import React from 'react';
import endpoints from '../../../util/endpoints'
import utilities from '../../../util/utilities'
import MetaTags from 'react-meta-tags'
import { NavLink } from 'react-router-dom'
import { Link, Switch, Route } from 'react-router-dom'
import 'whatwg-fetch'

export default class Sets extends React.Component{
  constructor(){
    super()
    this.state = {sets:[],filtered:[],generations:[],targetSet:0}
    this.fetchGenerations = this.fetchGenerations.bind(this)
    this.fetchSubs = this.fetchSubs.bind(this)
    this.renderGenerations = this.renderGenerations.bind(this)
  }

  fetchGenerations(){

    const setsurl = endpoints.allSets;
    const key = utilities.apiKey;

    fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
      .then(response => response.json())
      .then(json => {
          this.setState({
            generations: json
          });
      });
  }


  renderGenerations(){
    return this.state.generations.map( gen => {
    	var tempTarget="/tools/sets/gen/"+gen.gen_id;
      return <div className="genrow" key={gen.gen_id}>
          <Link  key={gen.gen_id} to={tempTarget} >
            <img key={"a"+gen.gen_id} src={gen.primary_set_icon} alt={gen.primary_set_name} />
            {gen.secondary_set_icon != null &&
              <img key={"b"+gen.gen_id} src={gen.secondary_set_icon} alt={gen.secondary_set_name} />
            }
            {gen.gen_id == 100 &&
              <div>Collectibles</div>
            }
            {gen.gen_id < 100 &&
              <div>Generation {gen.gen_id}</div>
            }
          </Link>
        </div>

        } )
    }

    fetchSets(genID){
      const setsurl = endpoints.allSets+"?gen_id="+genID;
      const key = utilities.apiKey;
  	  var mySets = []

      if (genID<100) {
      fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
        .then(response => response.json())
        .then(json => {
  		json.sets.map( set => {
  			return mySets.push(set)
  		})

          this.setState({
            sets: mySets,
            filtered: mySets
          });
        });
      } else {
        mySets.push({"id": 20,"hasSubs": 0,"name": "Exclusive Items", "description": "Formerly known as \"WallaBee Uniques\"","image_url_100": "https://wallazee.global.ssl.fastly.net/images/collections/S20-20190218-277560a15e079801929980f9a91c6bd65780fa5342c624b6f6347dc-100.png"})
        mySets.push({"id": 20,"hasSubs": 1,"name": "Themed Exclusive Items", "description": "Exclusive Items Grouped By Themed Sets","image_url_100": "https://wallazee.global.ssl.fastly.net/images/variant/20190228-12a13b5efdec7cf6efb5e8cf855d94afbd91f38dd9afd091482fa2b-100.png"})
        mySets.push({"id": 13,"hasSubs": 0,"name": "Wallagraphic Items", "description": "These are rare cards found in Grab Bags.","image_url_100": "https://wallazee.global.ssl.fastly.net/images/collections/S13-20190218-3e9dbf87abae3c0922ed524aa03ccce3e8034ef0ac560b92e46192f-100.png"})
        mySets.push({"id": 25,"hasSubs": 0,"name": "Unique Items", "description": "These items are commissioned by players like you. Thank you!","image_url_100": "https://wallazee.global.ssl.fastly.net/images/collections/S25-20190218-feea75367d46ff22eb9a3892f32c1ef0f718004f0380c0cfb7d645e-100.png"})
        mySets.push({"id": 25,"hasSubs": 1,"name": "Themed Unique Items", "description": "Unique Items Grouped By Themed Sets","image_url_100": "https://wallazee.global.ssl.fastly.net/images/variant/20190228-3515e8455a19d682d3410e436294a6c6a8bf04276856e33913fe25a-100.png"})
        this.setState({
          sets: mySets,
          filtered: mySets
        });
      }
    }

    fetchSubs(setID){
      const setsurl = endpoints.allSets+setID+"/sub";
      const key = utilities.apiKey;
  	  var mySets = []
      var targetSet = null

      fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
        .then(response => response.json())
        .then(json => {
          targetSet = json.set_id
  		json.subsets.map( set => {
  			return mySets.push(set)
  		})
          this.setState({
            sets: mySets,
            filtered: mySets,
            targetSet: targetSet
          });
        });
    }


  		filterSet(event){
  			var query = event.target.value
  			this.doFilter(query)
  		}


  		doFilter(query){

  			var fullSets = this.state.sets
  			var filteredSet = []

  			if (query === "") {
  				filteredSet=fullSets
  			} else {
  			    fullSets.map( set => {
  			      var lcName=set.name.toLowerCase()
  			    	var lcQuery=query.toLowerCase()
      				if (lcName.indexOf(lcQuery) > -1) {
      					filteredSet.push(set)
      				}
  				})
  			}
  			this.setState({filtered: filteredSet})
  		}

    renderSets(){
      var counter=0
      return this.state.filtered.map( set => {
        if (set.hasSubs==1) {
          var tempTarget="/tools/sets/"+set.id+"/sub";
        } else {
          if (set.subset_id>0) {
            var tempTarget="/tools/sets/"+this.state.targetSet+"/sub/"+set.subset_id;
          } else {
            var tempTarget="/tools/sets/"+set.id;
          }
        }

        var cardKey="card"+counter
        counter++

        return <div className="card" key={cardKey}>
          <div className="card-image">

            <Link  key={set.id} to={tempTarget} >
              <img key={set.id} src={set.image_url_100} alt={set.name} />
            </Link>
          </div>
          <div className="card-content">
            <div className="media-content">
              <Link  key={set.id} to={tempTarget} >
                <p className="title">{set.name}</p>
              </Link>
              </div>

              <div className="content block-with-text">
                {set.description}
              </div>
            </div>
          </div>

          } )
      }

        componentWillMount() {
          var genID = this.props.match.params.genId
          var setID = this.props.match.params.setId
          if ((genID>0)||(setID>0)) {
            if (genID>0) {
              this.fetchSets(genID)
            }
            if (setID>0) {
              this.fetchSubs(setID)
            }
          } else {
            this.fetchGenerations()
          }
        }
        componentWillReceiveProps(nextProps) {
          var genID = nextProps.match.params.genId
          var setID = nextProps.match.params.setId
          this.setState({sets:[],filtered:[],generations:[],targetSet:0});


          if ((genID)||(setID)) {
            if (genID>0) {
              if ((nextProps.match.params.genId !== this.props.match.params.genId)) {
                var nextGen = nextProps.match.params.genId
                this.fetchSets(nextGen);
              }
            }
            if (setID>0) {
              if ((nextProps.match.params.setId !== this.props.match.params.setId)) {
                var nextSet = nextProps.match.params.setId
                this.fetchSubs(nextSet);
              }
            }
          } else {
            this.fetchGenerations()
          }
        }

        render() {
          if ( ! this.state.sets ){ return null; }

            return <div className="text-center">


              <div className="container sets" id="sets-all">

                <MetaTags>
                  <title>Explore the Sets | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
                </MetaTags>

                <div className="text-center">
                {this.props.match.params.genId < 100 &&
                  <div>
                  <h1>Generation {this.props.match.params.genId}</h1>
                  <NavLink className="button outdoors" to="/tools/sets/" id="hero-btn">Back to Generations</NavLink>
                  </div>
                }
                {this.props.match.params.genId == 100 &&
                  <div>
                  <h1>Collectibles</h1>
                  <NavLink className="button outdoors" to="/tools/sets/" id="hero-btn">Back to Generations</NavLink>
                  </div>
                }
                {this.props.match.params.setId == 20 &&
                  <div>
                  <h1>Themed Exclusive Items</h1>
                  <NavLink className="button outdoors" to="/tools/sets/gen/100" id="hero-btn">Back to Collectibles</NavLink>
                  </div>
                }
                {this.props.match.params.setId == 25 &&
                  <div>
                  <h1>Themed Unique Items</h1>
                  <NavLink className="button outdoors" to="/tools/sets/gen/100" id="hero-btn">Back to Collectibles</NavLink>
                  </div>
                }
                  <div className="flex" id="card-container">
                    <Switch>
                      <Route path={'/tools/sets/:id'}  />
                    </Switch>
                    {this.props.match.params.genId > 0 &&
                      this.renderSets()
                    }
                    {this.props.match.params.setId > 0 &&
                      this.renderSets()
                    }
                    {this.props.match.params.genId == null &&
                      <div id="genlist">
                      {this.renderGenerations()}
                      </div>
                    }

                  </div>
                  <br/><br/>
                </div>

              </div>
            </div>
        }
        }
