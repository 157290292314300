import React from 'react'
import Panel from '../../../components/panel'

  var placeArray =  [
  {
      'name': 'CINEMA',
      'desc': ['Movie theatre, including repertory and new release'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/30-51e5391c-d28c-43ff-9481-1954c63dd4aa-50.png',
      'id': 1
  },
  {
      'name':'NIGHTCLUB',
      'desc': ['Primarily dance clubs, can also be jazz clubs and other music clubs'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/34-51e539ce-f254-4c30-a58c-1a34c63dd4aa-50.png',
      'id': 2
  },
  {
      'name':'OTHER',
      'desc': ['Recreational dance studios (Education/Other for formal dance schools)','Lodges, Masons, Legions, VFWs and similar clubs','Mini golf','Bowling','Arcades','Race track/karting','Casino, betting shops, gambling parlours','University/college recreation centres','Community/Recreation centres','Convention centres','Scout/guide halls','NOT: Radio/TV stations (Service/office); fairs, as they are "events"'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/35-51e539f2-550c-4f4a-bca0-1a34c63dd4aa-50.png',
      'id': 3
  },
  {
      'name':'PUB/BAR',
      'desc': ['Primary focus is on serving alcohol, not food','Breweries/wineries/distilleries','NOT: chain restaurants (e.g., Chili’s, which simply happen to serve alcohol (restaurant)), Veterans of Foreign Wars (VFW)/Legions (recreation/other), liquor stores (food/drink store), etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/19-51e5340e-7e78-45b3-a5a8-0c9bc63dd4aa-50.png',
      'id': 4
  },
  {
      'name':'RIDE/ATTRACTION',
      'desc': ['Individual rides at a theme park','Individual attractions at a zoo, of significant size'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/73-52132519-b018-46ab-9bcb-38dec63dd4aa-50.png',
      'id': 5
  },
  {
      'name':'STADIUM',
      'desc': ['Large-scale sports stadia/venues','NOT: High school stadia, unless of unusual size (outdoor sports); private boxes, etc. in a stadium'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160301-2fdeaa596d24acd2066896b3331fe77428a938dba5395852b62634f-50.png',
      'id': 6
  },
  {
      'name':'SWIMMING POOL/WATER PARK',
      'desc': ['Swimming pools and water parks','College/University pools and natatoria','NOT: Planned community/subdivision pools, hotel pools'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/64-51e56091-ada8-42ea-b612-7e44c63dd4aa-50.png',
      'id': 7
  },
  {
      'name':'THEATRE/CONCERT HALL',
      'desc': ['Concert spaces (e.g., opera houses, live music venues, etc.)','Theatres, including community theatres','NOT: Cinemas (Cinema)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/31-51e53930-4cac-4f3b-8ddd-1999c63dd4aa-50.png',
      'id': 8
  },
  {
      'name':'THEME PARK',
      'desc': ['Overarching pin for entire theme park (e.g., Disneyworld)','NOT: each individual attraction within the park (recreation/ride-attraction)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/32-51e53941-ab20-47a0-a5b0-1928c63dd4aa-50.png',
      'id': 9
  },
  {
      'name':'ZOO',
      'desc': ['Overarching pin for entire zoo (e.g., San Diego Zoo)','NOT: each individual attraction within the zoo (recreation/ride-attraction)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/33-51e5395e-8358-44a1-92ed-1998c63dd4aa-50.png',
      'id': 10
  }
  ]


export default class PlaceRec extends React.Component {
      render(){
        const places = placeArray.map((place,i) => {
          return <Panel key={i} {...place}/>
        })

    return (
      <div>
        <div className="text-center">
          <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/29-51e53908-21d4-4e70-b40d-1928c63dd4aa-200.png" alt="Recrational Places"/>
          <h2>Recreation</h2>
        </div>
        {places}
      </div>

  )}
}
