import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import endpoints from '../../util/endpoints'
import utilities from '../../util/utilities'
import 'whatwg-fetch'


class AddThread extends Component {
  constructor(props){
    super(props)
    this.state = {
      title: '',
      body: ''
    }
  }

  updateTitle = (event) => {
    this.setState({
      title: event.target.value
    })
  }

  updateBody = (event) => {
    this.setState({
      body: event.target.value
    })
  }

  submitThread = (event) => {
    event.preventDefault()

    const { title, body } = this.state

    let forumid = this.props.match.params.id

    const forumsUrl = endpoints.forums + '/' + forumid
    const token = JSON.parse(localStorage.getItem('token'))

    const key = utilities.apiKey;

    let threadBody = new FormData();

    threadBody.append('title', title)
    threadBody.append('text', body)

    fetch(forumsUrl,{method: 'POST', body: threadBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data)

        if (data.success) {
          this.props.history.replace('/forums/' + forumid + '/0/' + data.post_id + '/0')
          window.location.reload()
        } else {
          alert('Error')
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render(){

    let forumname


      switch (this.props.match.params.id) {
        case '1':   forumname = 'Official Business'
        break;
        case '11':  forumname = 'NewBees Hive'
        break;
        case '2':   forumname = 'Item Directory'
        break;
        case '3':   forumname = 'The Trading Post'
        break;
        case '6':   forumname = 'Auction House'
        break;
        case '4':   forumname = 'WallaBee Chat'
        break;
        case '12':  forumname = 'Party Room'
        break;
        case '10':  forumname = 'Phoenixes of Jabbaclaw'
        break;
        case '7':   forumname = 'Unicorns of Slithering'
        break;
        case '9':   forumname = 'Yetis of Snugglepuff'
        break;
        case '8':   forumname = 'Dragons of Colbourndor'
        break;
        case '5':   forumname = 'Bug Reporting'
        break;
        case '13':   forumname = 'WallaBoo! Trading'
        break;

        default:    return null
      }

    return(
      <div id="forums">
        <header className="hero is-dark is-bold">
          <section className="container">
            <div id="itembee-header" className="forums-header">

              <div id="itembee-logo">
                <img width="93px" src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/1474-200.png" alt="WallaBee Forums" />
              </div>

              <div id="itembee-desc">
                <h1 className="title">WallaBee Forums</h1>
                <h2>
                  Welcome to the forums... please play nice! :)
                </h2>

                <Link className="button outdoors" to="/forums" style={{'marginLeft':'0'}}>Forums Home</Link>

              </div>
            </div>
          </section>
        </header>

        <section id="forums-container">
          <div className="container">

            <div className="text-center">
              <a className="button outdoors" href={'/forums/' + this.props.match.params.id + '/0'}>Back to {forumname}</a><br/><br/>
            </div>

            <div className="panel">
              <header className="panel-heading">
                Add Thread to {forumname}
              </header>

              <div className="panel-block">
                <form onSubmit={this.submitThread}>
                  <div className="field">
                    <label className="label">Title</label>
                    <div className="control">
                      <input className="input" type="text" placeholder="Enter title here..." onChange={this.updateTitle}/>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Message</label>
                    <div className="control">
                      <textarea className="textarea" type="input" placeholder="Enter message here..." onChange={this.updateBody}/>
                    </div>
                  </div>
                  <div className="control text-center">
                    <button className="button is-primary forum-btn" type="submit">Submit</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default AddThread
