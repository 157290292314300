import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink, Link } from 'react-router-dom'
import endpoints from '../../../util/endpoints'
import utilities from '../../../util/utilities'
import Modal from '../../../components/modal'
import OOAKTable from '../../../util/oneofakinds'
import RetiredTable from '../../../util/retired'
import 'whatwg-fetch'

export default class ItemDetail extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      singleItem:[],
      mixInfo:[],
      sets:[],
      stores:[],
      categories:[],
      storeInfo:[],
      forageInfo:[],
      locationInfo:[],
      tempPlace:[],
      targetNumber:[],
      foundFormulas:[],
      statusDD:[],
      statusTD:[],
      statusQD:[],
      searchForNumber: false,
      keyCounter: 0,
      itemReady: false,
      storeReady: false,
      statusReady: false,
      categoryReady: false,
      placeReady: false,
      marketReady: false,
      setReady: false,
      mixReady: false,
      targetID: 0,
      setID: 0,
      subSetID: 0,
      marketBuyNow: 0,
      isModalOpen:false,
      variantFound: false,
      variants: [],
      owners: [],
      ownersFound: false,
      ownersPics: [],
      OOAK: [],
      Retired: [],
      foundContainer: false,
      foundItemSource: false
      }
    this.fetchItem = this.fetchItem.bind(this)
    this.fetchCategories = this.fetchCategories.bind(this)
    this.fetchSets = this.fetchSets.bind(this)
    this.fetchStores = this.fetchStores.bind(this)
    this.fetchStatus = this.fetchStatus.bind(this)
    this.fetchFormulas = this.fetchFormulas.bind(this)
    this.fetchOwners = this.fetchOwners.bind(this)
    this.fetchMarketBuy = this.fetchMarketBuy.bind(this)
  }

  numberWithCommas(x){
    if(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }else{
      return '0'
    }
  }

  fetchItem(targetID){
    /* const targetID = this.props.match.params.itemId */
    const itemurl = endpoints.singleItem+targetID;
    const key = utilities.apiKey;

    var targetSet = 0
    var targetSubSet = 0

    fetch(itemurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        singleItem: json
      });
      this.setState({
        mixInfo: json.formulas
      });
      this.setState({
        setID: json.set_id
      });
      this.setState({
        subSetID: json.subset_id
      });


      this.fetchMarketBuy(targetID);
      this.setState({
        storeInfo: json.stores
      });
      this.setState({
        forageInfo: json.categories
      });
      this.setState({
        locationInfo: json.locations
      });
      this.setState({
        variants: json.variants
      });
      this.setState({
        itemReady: true
      });

      var variantCounter = 0

      json.variants.map ( variant => {
        variantCounter++
      })
      if (variantCounter>0) {
        /* hide toasty variants */
        /* if (json.item_type_id!=1761) { */
          this.setState({
            variantFound: true
          });
        /* } */
      }
      var sourceCounter = 0

      json.found_in.map ( container => {
        sourceCounter++
      })
      if (sourceCounter>0) {
        this.setState({foundItemSource: true})
      }


      var containerCounter = 0

      if (json.contains_items) {
        json.contains_items.map ( container => {
          containerCounter++
        })
        if (containerCounter>0) {
          this.setState({foundContainer: true})
        }
      }

    });
    this.fetchMarketBuy(targetID);
    this.fetchSets();
  }

  fetchStores() {
    const storeurl = endpoints.stores;
    const key = utilities.apiKey;

    var storeData = [];

    fetch(storeurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      storeData = json.stores
      storeData.push({"id": 999, "name": "Market", "header": "https://wallazee.global.ssl.fastly.net/images/dynamic/items/1039-200.png"})
    this.setState({
        stores: storeData
      });
    });

    this.setState({
      storeReady: true
    });

  }

  fetchPlace(targetID){
    const placeurl = endpoints.places+targetID;
    const key = utilities.apiKey;

    fetch(placeurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        tempPlace: json
      });
    });
  }

  fetchStatus(targetID) {
    /* const targetID = this.props.match.params.itemId */

    const DDurl = endpoints.singleItem+targetID+"/status/?min=1&max=99";
    const TDurl = endpoints.singleItem+targetID+"/status/";
    const QDurl = endpoints.singleItem+targetID+"/status/?min=1&max=9999";
    const key = utilities.apiKey;

    var ddData = [];
    var tdData = [];
    var qdData = [];

    fetch(DDurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      ddData = json.status
      this.setState({
        statusDD: ddData
      });
    });

    fetch(TDurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      tdData = json.status
      this.setState({
        statusTD: tdData
      });
    });

    fetch(QDurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      qdData = json.status
      this.setState({
        statusQD: qdData
      });
    });

    this.setState({
      statusReady: true
    });


  }

  fetchCategories() {
    const caturl = endpoints.categories;
    const key = utilities.apiKey;

    var categoryData = [];

    fetch(caturl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      json.categories.map( placeData => {
        categoryData.push(placeData);
        placeData.subcategories.map( subPlaceData => {
          categoryData.push(subPlaceData);
        })
      })
      this.setState({
        categories: categoryData
      });
    });

    this.setState({
      categoryReady: true
    });
  }


  fetchSets(){

    var targetSet=this.state.setID
    var targetSubSet=this.state.subSetID


    if (targetSubSet>0) {
      var setsurl = endpoints.allSets+targetSet+"/sub/"+targetSubSet;
    } else {
      var setsurl = endpoints.allSets+targetSet;
    }
    const key = utilities.apiKey;


    var setData = [];

    fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      setData = json

      this.setState({
        sets: setData
      });
    });

    this.setState({
      setReady: true
    });

  }

  fetchMarketBuy(targetID){
    var setID = this.state.setID
    var setsurl = endpoints.allSets+setID;
    var itemReady = this.state.itemReady
    const key = utilities.apiKey;

    var itemData = [];
    var market_buy_now = 0;

    if (setID>0) {
    fetch(setsurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      itemData = json.items
      itemData.map( set => {
        if (set.item_type_id==targetID) {
          if (set.market_buy_now==1) {
            market_buy_now=parseInt(set.store_cost);
          }
        }
      });
      this.setState({
        marketBuyNow: market_buy_now
      });

      this.setState({
        marketReady: true
      });
    });

  }


  }


  fetchOwners(targetID){
    const ownerURL = endpoints.olduniqueset+"/owners";
    const key = utilities.apiKey;

    fetch(ownerURL,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        owners: json
      });
      this.setState({
        ownersFound: true
      });
    })
  }

  fetchOOAK(){
    var tempArray = []

    OOAKTable.OneOfAKind.map( item => {
        tempArray.push(item.item_type_id)
    })

    if (tempArray.length != 0) {
      this.setState({OOAK: tempArray})
    }

  }

  fetchRetired(){
    var tempArray = []

    RetiredTable.Retired.map( item => {
        tempArray.push(item.item_type_id)
    })

    if (tempArray.length != 0) {
      this.setState({Retired: tempArray})
    }

  }

  fetchContainer(targetID){
    /*
    var tempBagArray = []
    var tempSetArray = []
    var tempItemArray = []
    var tempItemBagArray = []
    var bagFlag = false
    var itemFlag = false



    containerTable.containers.map( item => {
        tempBagArray.push(item.item_type_id)
        tempSetArray.push(item.set_id)
        if (item.item_type_id==targetID) {
          this.setState({foundContainer: true})
        }
        item.contents.map( container_item => {
            tempItemBagArray.push(item.item_type_id)
            tempItemArray.push(container_item)
            if (container_item==targetID) {
              this.setState({foundItemSource: true})
            }
        })

    })

    if (tempBagArray.length != 0) {
      this.setState({containers: tempBagArray})
      this.setState({containerSets: tempSetArray})
      this.setState({containerItems: tempItemArray})
      this.setState({containerItemSource: tempItemBagArray})
    }
*/


  }


  renderOwner(targetID){

    var ownersMap = this.state.owners
    var OOAKItems = this.state.OOAK
    var outputHTML= [];
    var ProfilePic = null;
    var tempHeader = null;

    ownersMap.map((ownersMapData,i) => {
      var tempTarget = "/tools/profile/"+i+"/bio"
      var tempkey=targetID+i
      if (ownersMapData.item_type_id == targetID) {
        ownersMapData.owners.map((user,i) =>{
          if (user==0) {
            var tempkey = targetID+user
            var tempTarget = "/tools/profile/16435/bio"
            var tempPic = "https://wallazee.global.ssl.fastly.net/images/site/logo.png"
            tempHeader = <span key={tempkey}><Link key={i} to={tempTarget}><img key={i} src={tempPic} className="icon-place" alt="WallaAdmin" height="100" width="100" /></Link></span>
          } else {
            var tempTarget = "/tools/profile/"+user+"/bio"
            var tempPic = "https://wallazee.global.ssl.fastly.net/images/dynamic/user/"+user+"-200.png"
/*            var tempPic = "https://wallazee.global.ssl.fastly.net/images/profile.png"*/
            tempHeader = <span key={tempkey}><Link key={i} to={tempTarget}><img key={i} src={tempPic} className="icon-place" alt={user} height="100" width="100"/></Link></span>
          }
          outputHTML.push(tempHeader);

        })

      }
    })

      OOAKItems.map( item_type_id =>{
        if (item_type_id==targetID) {
          var OOAKText = <span><br/><strong>This Unique is either One of A Kind or Staff Unique.<br/>Thus it is unlikely the Owner will trade it.</strong></span>
          outputHTML.push(OOAKText);
        }
      })

    if (outputHTML.length>0) {
      return <span id='Owners'>{outputHTML}</span>
    } else {
      return null
    }

  }

  renderImage(targetID){

    var RetiredItems = this.state.Retired
    var outputHTML= [];
    var retiredFlag = 0;
    var apiImage = null;

      RetiredItems.map( item_type_id =>{
        if (item_type_id==targetID) {
          retiredFlag = 1
        }
      })

      apiImage="https://wallazee.global.ssl.fastly.net/images/variant/20140304-e1040f438370b85f0fc358d18041f15eb8b8b5c4f5bedca129577f6-200.png";
      if (this.state.singleItem.item_type_id > 0) {
        // apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+this.state.singleItem.item_type_id+"-200.png";
        apiImage=this.state.singleItem.image_url_200;

      }

    if (retiredFlag == 1) {
      return <div className="image margin itemdisplay">
                <img src={apiImage} alt={this.state.singleItem.name}/>
                <img src="https://wallazee.global.ssl.fastly.net/images/site/retired-overlay.png" className="retired"  />
            </div>
    } else {
      return <div className="image margin itemdisplay">
                <img src={apiImage} alt={this.state.singleItem.name}/>
            </div>
    }
  }


  fetchFormulas(targetID){
    const formulaurl = endpoints.formulas;
    const key = utilities.apiKey;

    var currentID = null;
    var currentFormula = [];
    /* var targetID = this.props.match.params.itemId; */
    var foundFormulas = [];
    var flag = 0;

    fetch(formulaurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {

      Object.values(json).map( formulaMapData => {


        currentID = formulaMapData.item_type;
        currentFormula = formulaMapData.formula.split('+');
        flag = 0;
        currentFormula.map( currentFormulaData => {
          if ((currentFormulaData == targetID)&&(flag==0)) {
            flag = 1;
            foundFormulas.push(currentID)
          }
        })
      })

      if (targetID=="1172") {
        flag = 1;
        foundFormulas.push("1172")
      }

      if (targetID=="1173") {
        flag = 1;
        foundFormulas.push("1173")
      }

      this.setState({
        foundFormulas: foundFormulas
      });
    });
    this.setState({
      mixReady: true
    });

  }


  renderVariants() {
    var outputHTML = []
    var tempImage = null
    var tempImgTag = null
    let counter = 0
    this.state.variants.map ( variant => {
      counter++
      tempImage = variant.image
      tempImgTag = <img src={tempImage} alt={variant.name} key={counter} width='100' height='100' />
        outputHTML.push(tempImgTag);
    })
    if (outputHTML.length != 0) {
      return <div id='variant' key='variantData'>{outputHTML}</div>
    } else {
      return null;
    }
  }

  renderWandIcon() {
    var outputHTML = []
    var tempHTML = null
    if (this.state.singleItem.works_with_wand > 0) {
      tempHTML = <img src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/3231-200.png' />
      outputHTML.push(tempHTML);
    }
    if (outputHTML.length != 0) {
      return <div id='wand' key='wandIcon'><h4>Change Variants With:</h4><div>{outputHTML}</div></div>
    } else {
      return null;
    }
  }


  renderMix(){
    var outputHTML= [];
    var counter = 0;
    var mixflag = 0;
    var bigSlash = <span style={{fontSize: 500 + '%'}}>/</span>;

    this.state.mixInfo.map( mixData => {
      if (mixflag == 1) { outputHTML.push(bigSlash); }
      mixData.map( singleMixData => {
        if (utilities.mixerExceptions.indexOf(singleMixData)<0) {
          mixflag = 1;
          counter++;
          var tempTarget="/tools/item/"+singleMixData;
          var apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+singleMixData+"-200.png";
          var tempHeader = <Link key={counter} to={tempTarget}><img src={apiImage} alt=""/></Link>;
          outputHTML.push(tempHeader);
        }
      })
    } )

    if (outputHTML.length != 0) {
      return <div id='Mix' key='MixData'><h4>Created With:</h4><div>{outputHTML}</div></div>
    } else {
      return null;
    }

  }

  renderSpecialMix(){
    var outputHTML= [];
    var bigSlash = <span style={{fontSize: 500 + '%'}}>/</span>;
    var PerfumeImg = "https://wallazee.global.ssl.fastly.net/images/dynamic/items/1172-200.png";
    var AfterShaveImg = "https://wallazee.global.ssl.fastly.net/images/dynamic/items/1173-200.png"

    var Perfume = this.state.singleItem.perfume_message
    var AfterShave = this.state.singleItem.aftershave_message
    var tempHeader = null

    if ((Perfume==true)&&(AfterShave==false)){
      tempHeader = <Link key="p1" to="/tools/item/1172"><img src={PerfumeImg} alt=""/></Link>;
    }
    if ((Perfume==false)&&(AfterShave==true)){
      tempHeader = <Link key="a1" to="/tools/item/1173"><img src={AfterShaveImg} alt=""/></Link>;
    }
    if ((Perfume==true)&&(AfterShave==true)){
      tempHeader = <div key="perf"><Link key="p1" to="/tools/item/1172"><img src={PerfumeImg} alt=""/></Link><span style={{fontSize: 500 + '%'}}>/</span><Link key="a1" to="/tools/item/1173"><img src={AfterShaveImg} alt=""/></Link></div>;
    }

    outputHTML.push(tempHeader);

    if (outputHTML.length != 0) {
      return <div id='Mix' key='MixData'><h4>Special Mix with:</h4><div>{outputHTML}</div></div>
    } else {
      return null;
    }

  }

  renderMixException(){
    var outputHTML= [];
    var counter = 0;
    var mixflag = 0;
    var bigSlash = <span style={{fontSize: 500 + '%'}}>/</span>;

    utilities.mixExceptions.map( (mixData,key) => {
      if (mixData == this.state.singleItem.item_type_id) {
        if (mixflag == 1) { outputHTML.push(bigSlash); }
        utilities.formulaExceptions[key].map( singleMixData => {
          counter++;
          mixflag = 1;
          var tempTarget="/tools/item/"+singleMixData;
          var apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+singleMixData+"-200.png";
          var tempHeader = <Link key={counter} to={tempTarget}><img src={apiImage} alt="" /></Link>;
          outputHTML.push(tempHeader);
        })
      }
    })

    return <div id='Mix' key='MixData'><h4>Created With:</h4><div>{outputHTML}</div></div>

  }

  renderStores(){
    var storeMap = this.state.stores;
    var storeInfo = this.state.storeInfo;
    var marketBuyNow = this.state.marketBuyNow;
    if ((marketBuyNow > 0)&&(!storeInfo.includes(999))) {
      storeInfo.push(999)
    }
    var outputHTML= [];
      storeInfo.map( storeData => {
      storeMap.map( storeMapData => {
        if (storeMapData) {
          if (storeData == storeMapData.id) {
            var tempHeader = <span key={storeMapData.id}>
            <img src={storeMapData.header} alt={storeMapData.name} className="store-banner"/>
            <br/>
            </span>;
            outputHTML.push(tempHeader);
          }
        }
      })

    })
    return <span id='Stores'>{outputHTML}</span>

  }

  renderContainers(targetID){

    var outputHTML= [];
    this.state.singleItem.contains_items.map( (itemData,key) => {
                var tempItem = itemData
                var tempTarget="/tools/item/"+tempItem;
                var apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+tempItem+"-50.png";
                var tempHeader = <Link key={itemData} to={tempTarget}><img src={apiImage} alt=""/></Link>;
                outputHTML.push(tempHeader);
    })

    return <span id='Container'>{outputHTML}</span>

  }

  renderSource(targetID){


    var outputHTML= [];
    this.state.singleItem.found_in.map( (itemData,key) => {
          var tempItem = itemData;
          var tempTarget="/tools/item/"+tempItem;
          var apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+tempItem+"-100.png";
          var tempHeader = <Link key={key} to={tempTarget}><img src={apiImage} alt=""/></Link>;
          outputHTML.push(tempHeader);
    })

    return <span id='FoundIn'>{outputHTML}</span>

  }


  renderStatus(){
    var tempHeader = null;
    var outputHTML= [];

    var ddData = this.state.statusDD
    var tdData = this.state.statusTD
    var qdData = this.state.statusQD

    if (this.state.singleItem.total_issued>0) {
      if ((this.state.singleItem.item_type_id==553)||(this.state.singleItem.item_type_id==11)) {
        var ddMixed=(99-(ddData.mixed)-(ddData.dropped)-(ddData.lockbox)-(ddData.saved)-(ddData.pouch)-(ddData.mixing_pool))
        var mixText="Opened"
      } else {
        var ddMixed=ddData.mixed
        var mixText="Mixed"
      }

      tempHeader =
      <section className="rarebeeStats_section container" key="DDStats">
      <div className="panel">
      <div className="panel-heading">
      1-99 Stats
      </div>
      <div className="panel-block">
      <div className="flex">
      <div className="level-item text-center">
      <div className="stats_icon stats_icon-mixed">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-mixed-1.svg" alt=""/>
      <p className="title">{ddMixed}</p>

      </div>
      <p className="heading">{mixText}</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-dropped">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-dropped-1.svg" alt=""/>

      <p className="title">{ddData.dropped}</p>
      </div>
      <p className="heading">Dropped</p>
      </div>


      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-lockbox">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-lockbox-1.svg" alt=""/>
      <p className="title">{ddData.lockbox}</p>
      </div>
      <p className="heading">Lockbox</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-saved">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-saved-1.svg" alt=""/>
      <p className="title">{ddData.saved}</p>
      </div>
      <p className="heading">Saved</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-pouch">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-pouch-pink.svg" alt=""/>

      <p className="title">{ddData.pouch}</p>
      </div>
      <p className="heading">Pouch</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-mixerpool">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-mixerpool-green-1.svg" alt=""/>

      <p className="title">{ddData.mixing_pool}</p>
      </div>
      <p className="heading">Mixer Pool</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-recyclecenter">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee_recyclecenter-black-1.svg" alt=""/>

      <p className="title">{ddData.recycle_center}</p>
      </div>
      <p className="heading">Recycle Center</p>
      </div>

      </div>
      </div>
      </div>
      </section>;
      outputHTML.push(tempHeader);

    }

    if (this.state.singleItem.total_issued>99) {
      if ((this.state.singleItem.item_type_id==553)||(this.state.singleItem.item_type_id==11)) {
        var tdMixed=(999-(tdData.mixed)-(tdData.dropped)-(tdData.lockbox)-(tdData.saved)-(tdData.pouch)-(tdData.mixing_pool))
        var mixText="Opened"
      } else {
        var tdMixed=tdData.mixed
        var mixText="Mixed"
      }

      tempHeader = <section className="rarebeeStats_section container" key="TDStats">
      <div className="panel">
      <div className="panel-heading">
      1-999 Stats
      </div>
      <div className="panel-block">
      <div className="flex">
      <div className="level-item text-center">
      <div className="stats_icon stats_icon-mixed">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-mixed-1.svg" alt=""/>
      <p className="title">{tdMixed}</p>

      </div>
      <p className="heading">{mixText}</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-dropped">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-dropped-1.svg" alt=""/>

      <p className="title">{tdData.dropped}</p>
      </div>
      <p className="heading">Dropped</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-lockbox">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-lockbox-1.svg" alt=""/>
      <p className="title">{tdData.lockbox}</p>
      </div>
      <p className="heading">Lockbox</p>
      </div>


      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-saved">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-saved-1.svg" alt=""/>

      <p className="title">{tdData.saved}</p>
      </div>
      <p className="heading">Saved</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-pouch">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-pouch-pink.svg" alt=""/>

      <p className="title">{tdData.pouch}</p>
      </div>
      <p className="heading">Pouch</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-mixerpool">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-mixerpool-green-1.svg" alt=""/>

      <p className="title">{tdData.mixing_pool}</p>
      </div>
      <p className="heading">Mixer Pool</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-recyclecenter">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee_recyclecenter-black-1.svg" alt=""/>

      <p className="title">{tdData.recycle_center}</p>
      </div>
      <p className="heading">Recycle Center</p>
      </div>

      </div>
      </div>
      </div>
      </section>;
      outputHTML.push(tempHeader);

    }

    if (this.state.singleItem.total_issued>999) {
      if ((this.state.singleItem.item_type_id==553)||(this.state.singleItem.item_type_id==11)) {
        var qdMixed=(9999-(qdData.mixed)-(qdData.dropped)-(qdData.lockbox)-(qdData.saved)-(qdData.pouch)-(qdData.mixing_pool))
        var mixText="Opened"
      } else {
        var qdMixed=qdData.mixed
        var mixText="Mixed"
      }
      tempHeader = <section className="rarebeeStats_section container" key="QDStats">
      <div className="panel">
      <div className="panel-heading">
      1-9999 Stats
      </div>
      <div className="panel-block">
      <div className="flex">
      <div className="level-item text-center">
      <div className="stats_icon stats_icon-mixed">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-mixed-1.svg" alt=""/>
      <p className="title">{qdMixed}</p>

      </div>
      <p className="heading">{mixText}</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-dropped">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-dropped-1.svg" alt=""/>

      <p className="title">{qdData.dropped}</p>
      </div>
      <p className="heading">Dropped</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-lockbox">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-lockbox-1.svg" alt=""/>
      <p className="title">{qdData.lockbox}</p>
      </div>
      <p className="heading">Lockbox</p>
      </div>


      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-saved">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-saved-1.svg" alt=""/>

      <p className="title">{qdData.saved}</p>
      </div>
      <p className="heading">Saved</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-pouch">
      <img src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-pouch-pink.svg" alt=""/>

      <p className="title">{qdData.pouch}</p>
      </div>
      <p className="heading">Pouch</p>
      </div>
      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-mixerpool">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee-mixerpool-green-1.svg" alt=""/>

      <p className="title">{qdData.mixing_pool}</p>
      </div>
      <p className="heading">Mixer Pool</p>
      </div>

      <div className="level-item has-text-centered">
      <div className="stats_icon stats_icon-recyclecenter">
      <img  src="https://wallazee.global.ssl.fastly.net/images/site/icon_wallabee_recyclecenter-black-1.svg" alt=""/>

      <p className="title">{qdData.recycle_center}</p>
      </div>
      <p className="heading">Recycle Center</p>
      </div>

      </div>
      </div>
      </div>
      </section>;
      outputHTML.push(tempHeader);

    }


    return <span id='Stats'>{outputHTML}</span>

  }

  renderFormulas(){
    var formulaMap = this.state.foundFormulas;
    var outputHTML= [];
    var apiImage = null;
    var tempTarget = null;
    var tempHeader = null;
    var lastItem = 0;

    formulaMap.map( formulaMapData => {
      if ((utilities.mixerExceptions.indexOf(formulaMapData)<0)||((formulaMapData==2089)&&(this.state.singleItem.item_type_id!=2089))) {
        if (formulaMapData != lastItem) {
          tempTarget="/tools/item/"+formulaMapData;
          apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+formulaMapData+"-100.png";
          tempHeader = <Link key={formulaMapData} to={tempTarget}><img key={formulaMapData} src={apiImage} alt="" /></Link>
          if ((formulaMapData!=1172)&&(formulaMapData!=1173)) {
            outputHTML.push(tempHeader);
          }
        }
        lastItem = formulaMapData
      }
    })

    utilities.usedExceptions.map( mixData => {
      if (mixData == this.state.singleItem.item_type_id) {
        var mixPointer = utilities.usedExceptions.indexOf(mixData)
        utilities.usedTargetExceptions[mixPointer].map( singleMixData => {

          if (singleMixData==0) {
            apiImage="https://wallazee.global.ssl.fastly.net/images/variant/20140304-e1040f438370b85f0fc358d18041f15eb8b8b5c4f5bedca129577f6-100.png";
            tempHeader = <span key={singleMixData+"a"}><img src={apiImage} alt="" /><br/>Random Item</span>;
          } else {
            var tempTarget="/tools/item/"+singleMixData;
            apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+singleMixData+"-200.png";
            tempHeader = <Link key={singleMixData} to={tempTarget}><img src={apiImage} alt="" /></Link>;
          }
          outputHTML.push(tempHeader);
        })
      }
    })

    utilities.SpecialExceptions.map( mixData => {
      if (mixData == this.state.singleItem.item_type_id) {
        apiImage="https://wallazee.global.ssl.fastly.net/images/variant/20140304-e1040f438370b85f0fc358d18041f15eb8b8b5c4f5bedca129577f6-100.png";
        tempHeader = <span key="specialmix"><img src={apiImage} alt="" /><br/>A Special Text Message</span>;
        outputHTML.push(tempHeader);
      }
    })


    return <div id='Formulas'><h4>Used to Make:</h4><div>{outputHTML}</div></div>

  }

  renderSet(){
    //var setMap = this.state.sets;
    var outputHTML= [];

    var subSetId=this.state.singleItem.subset_id;
    var subSetName=this.state.singleItem.subset_name;
    var subSetImg=this.state.singleItem.subset_image;

if (subSetId>0) {
  var setId=this.state.singleItem.set_id;
  var setImg=subSetImg;
  var targetURL = "/tools/sets/" + setId + "/sub/" + subSetId;
} else {
  var setId=this.state.singleItem.set_id;
  var setImg=this.state.singleItem.set_image;
  var targetURL = "/tools/sets/" + this.state.singleItem.set_id;
}
  var tempHeader = null;

//    setMap.map( setMapData => {
//      if (setMapData) {
//        if (this.state.singleItem.set_id == setMapData.id) {
          var tempHeader = <div key={setId}>
          <Link key={setId} to={targetURL}>
          <img key={setId} src={setImg} width='130px' alt="Set Icon" />

          </Link>

          </div>;
          outputHTML.push(tempHeader);
//        }
//      }
//    })

//    if (this.state.singleItem.set_id == 25) {
//      tempHeader = <span key="250"><Link key="251" to={targetURL}><img key="252" src="https://wallazee.global.ssl.fastly.net/images/collections/-51641053-c4f8-4ced-9014-7d59c63dd4aa-100.png" alt="Unique Items" /></Link></span>;
//      outputHTML.push(tempHeader);
//    }
//    if (this.state.singleItem.set_id == 20) {
//      tempHeader = <span key="200"><Link key="201" to={targetURL}><img key="202" src="https://wallazee.global.ssl.fastly.net/images/collections/20-4facea67-b5dc-41cd-b9d0-21df4d4902ab-100.png" alt="Branded Items" /></Link></span>;
//      outputHTML.push(tempHeader);
//    }

    return <span id='Set'>{outputHTML}</span>

  }

  renderBackToSet(){
    var setMap = this.state.sets;
    var outputHTML= [];
//    var targetURL = "/tools/sets/" + this.state.singleItem.set_id;
    var targetText = null
    var tempHeader = null
    var setId=this.state.singleItem.set_id
    var setName="NAME"
    var subSetId=this.state.singleItem.subset_id;


    if (subSetId>0) {
      var setId=this.state.singleItem.set_id;
      var targetURL = "/tools/sets/" + setId + "/sub/" + subSetId;
    } else {
      var setId=this.state.singleItem.set_id;
      var targetURL = "/tools/sets/" + this.state.singleItem.set_id;
    }

          targetText = "Back to Set";

          tempHeader = <span key={setId}>
            <Link  key={setId} className="button outdoors" id="hero-btn" to={targetURL}>{targetText}</Link></span>;
          outputHTML.push(tempHeader);


    return <span id='BackToSet'>{outputHTML}</span>

  }

  searchNumber(){
    var numberSearch=this.state.targetNumber;
    if (numberSearch>0) {
      var item_type_id=this.props.match.params.itemId;
      var targetURL = '/tools/item/'+item_type_id+'/num/'+numberSearch;
      this.props.history.push(targetURL);
    }
  }

  renderForage(){

    var forageMap = this.state.categories
    var placeMap = this.state.locationInfo
    var outputHTML= [];
    var categoryReady = this.state.categoryReady;
    var lastkey=0;
    if (categoryReady) {
      this.state.forageInfo.map( forageData => {
        forageMap.map( ForageMapData => {
          if (ForageMapData) {
            if (forageData == ForageMapData.id) {
              if (lastkey != ForageMapData.id) {
              var tempHeader = <span key={ForageMapData.id}>
              <div>
              <img key={ForageMapData.id} src={ForageMapData.image_url_100} className="icon-place" alt={ForageMapData.name}/><br/>
              <b>{ForageMapData.name}</b>
              </div>
              </span>;
              outputHTML.push(tempHeader);
            }
              lastkey=ForageMapData.id
            }
          }
        })

      })
    }
    this.state.locationInfo.map( (locationData,i) => {
            this.fetchPlace(locationData);
            var placeData=this.state.tempPlace
            var placeURL = "/tools/place/"+locationData

            var tempHeader = <span key={placeData.id}>
            <div>
            <NavLink to={placeURL}><img key={placeData.id} src={placeData.image_url_100} className="icon-place" alt={placeData.name}/><br/>
            <b>{placeData.name}</b></NavLink>
            </div>
            </span>;

            outputHTML.push(tempHeader);

    })


    return <span id='Categories'>{outputHTML}</span>
  }

  renderFAQ(){
    var itemText = "Party Hats have a special game mechanic to create them."
    var faqTarget = "/faq#faq11"

    if (this.props.match.params.itemId==11) {
      itemText = "Christmas Crackers have a special game mechanic associated with them."
    }

    if (this.props.match.params.itemId==2194) {
      itemText = "Wishing Wells have a special game mechanic associated with them."
      faqTarget = "/faq#faq12"
    }
    if (this.props.match.params.itemId==1648) {
      itemText = "Job Lots have a special game mechanic associated with them."
      faqTarget = "/faq#faq13"
    }


    return <span id='SpecialExceptions'>{itemText}<br/>Please see the <a href={faqTarget}>FAQ</a> to learn more.</span>
  }

  renderBagInfo() {

    return <span id='SpecialExceptions'>This item can be found as a rare item in the corresponding set grab bag for this item or in the Mystery Grab Bag.<br/>It can not be sold in Market.<br/>It can not be created from mixers.</span>

  }


  loadData(targetID) {
    this.fetchItem(targetID);
    this.fetchStores();
    this.fetchCategories();
    // this.fetchSets();
    this.fetchOOAK();
    this.fetchRetired();
    this.fetchContainer(targetID);
    this.fetchOwners(targetID);
    this.fetchFormulas(targetID);
    this.fetchStatus(targetID);
  }

  componentWillMount() {
    var targetID = this.props.match.params.itemId
    this.setState({ targetID: targetID});
    this.setState({variantFound: false})
    this.setState({foundContainer: false})
    this.setState({foundItemSource: false})
    this.loadData(targetID);
  }

  componentWillUnmount() {
    this.setState({targetID: 0});
    this.setState({variantFound: false})
    this.setState({foundContainer: false})
    this.setState({foundItemSource: false})
  }

  componentWillReceiveProps(newProps) {
    var targetID = newProps.match.params.itemId
    this.setState({ targetID: targetID });
    this.setState({variantFound: false})
    this.setState({foundContainer: false})
    this.setState({foundItemSource: false})
    this.loadData(targetID);
  }

  openModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal() {
    this.setState({ isModalOpen: false })
  }

  render() {

    var pageTitle=null

    if ((this.state.itemReady == true)&&(this.state.storeReady == true)&&(this.state.statusReady == true)&&(this.state.categoryReady == true)&&(this.state.setReady == true)&&(this.state.mixReady == true)&&(this.state.ownersFound == true)) {

      var mixList = 0;
      var storeList = 0;
      var forageList = 0;
      var locationList = 0;
      var totalIssued = 0;
      var formulaList = 0;
      var releaseDate = "Unreleased";
      var setIcon = null;
      var StatLink = this.renderStatus();
      var exceptionFlag = utilities.mixExceptions.indexOf(this.state.singleItem.item_type_id);
      var apiImage= null
      var mixLink = null
      var storeLink = null;
      var forageLink = null;
      var formulaLink = null;
      var variantLink = null;
      var wandIcon = null;
      var SpecialLink = null;
      var ownerLink = null;
      var faqLink = null;
      var containerLink = null;
      var sourceLink = null;
      var SpecialList = 0;
      var faqFlag=0;
      var bagFlag=0;
      var bagLink=null;
      var storeCost=0;

      if (utilities.usedExceptions.indexOf(this.state.singleItem.item_type_id) > -1)
      {
        var usedExceptionFlag = true;
      }
        else
      {
        var usedExceptionFlag = false;
      }


      pageTitle=this.state.singleItem.name + utilities.metaTitle;
      apiImage="https://wallazee.global.ssl.fastly.net/images/variant/20140304-e1040f438370b85f0fc358d18041f15eb8b8b5c4f5bedca129577f6-200.png";
      if (this.state.singleItem.item_type_id > 0) {
        apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+this.state.singleItem.item_type_id+"-200.png";
      }

      if ((this.state.singleItem.set_id == 141)||(this.state.singleItem.item_type_id == 2194)||(this.state.singleItem.item_type_id == 1648)) {
        faqLink = this.renderFAQ();
        faqFlag = 1;
      }

      if (this.state.singleItem.set_id == 13) {
        bagLink = this.renderBagInfo();
        bagFlag = 1;
      }
      if (this.state.singleItem.set_id) {
        setIcon = this.renderSet();
      }

      if (this.state.singleItem.formulas) {
        mixList = this.state.singleItem.formulas;
      }
      if (this.state.ownersFound==true) {
        ownerLink = this.renderOwner(this.state.targetID);
      }
      if (this.state.singleItem.stores) {
        storeList = this.state.singleItem.stores;
      }
      if (this.state.singleItem.categories) {
        forageList = this.state.singleItem.categories;
      }
      if (this.state.singleItem.locations) {
        locationList = this.state.singleItem.locations;
      }

      if (this.state.singleItem.total_issued) {
        totalIssued = this.state.singleItem.total_issued;
      }

      if (this.state.singleItem.release_date) {
        releaseDate = new Date(this.state.singleItem.release_date).toUTCString()
      }

      if (this.state.foundFormulas) {
        formulaList = this.state.foundFormulas;
      }

      if (this.state.foundContainer) {
        containerLink = this.renderContainers(this.state.targetID)
      } else {
        containerLink = null;
      }

      if (this.state.foundItemSource) {
        sourceLink = this.renderSource(this.state.targetID)
      } else {
        sourceLink = null;
      }

      if (this.state.variantFound==true){
          variantLink = this.renderVariants();
          wandIcon = this.renderWandIcon();
      }

      if ((this.state.singleItem.perfume_message==true)||(this.state.singleItem.aftershave_message==true)) {
        SpecialLink = this.renderSpecialMix();
        SpecialList = 1;
      } else {
        SpecialLink = null;
      }


      if (mixList != 0) {
        mixLink = this.renderMix();
      } else {
        mixLink = null;
      }

      if (exceptionFlag > 0) {
        mixLink = this.renderMixException();
      }


      if ((storeList != 0)||(this.state.marketBuyNow != 0)) {
        storeLink = this.renderStores();
        if (this.state.marketBuyNow != 0) {
          storeCost=this.state.marketBuyNow
        } else {
          storeCost=this.state.singleItem.store_cost
        }
        //		var honeycombicon = <img src='https://wallazee.global.ssl.fastly.net/images/site/honeycomb.png' width='50px'/>
      } else {
        storeLink = null;
      }
      if ((forageList != 0)||(locationList != 0)) {
        forageLink = this.renderForage();
      } else {
        forageLink = null;
      }

      if ((formulaList != 0)||(usedExceptionFlag == true)) {
        formulaLink = this.renderFormulas();
      } else {
        formulaLink = null;
      }



      return (
        <div id="itemDetailPage">

          <MetaTags>
            <title>{pageTitle}</title>
          </MetaTags>

          <div id="modal-container">
            <Modal isOpen={this.state.isModalOpen} onClose={() => this.closeModal()}>
              <img src={apiImage} alt={this.state.singleItem.name}/>
              <i className="fa fa-times-circle fa-4x" style={{position:'absolute', color:'black', cursor:'pointer'}} aria-hidden="true" onClick={() => this.closeModal()}></i>
            </Modal>
          </div>

          <header className="hero hero-header">

            <div className="container">
              <div className="columns">
                <div className="column is-2">
                    {this.renderImage(this.state.targetID)}
        </div>
        <div className="column is-5">
        <h1 className="title">
        {this.state.singleItem.name}
        </h1>
        <h2>
        {this.state.singleItem.description}
        </h2>
        {this.renderBackToSet()}
        </div>
        <div className="column is-5 flex" id="profile-header-stats">
        <div className="header-stats has-text-centered">
        <figure className="image is-96x96 margin">
        <i className="fa fa-check-circle-o header-icon" aria-hidden="true"></i>
        </figure>
        <p className="stat-val" id="set-completed">{this.numberWithCommas(totalIssued)}</p>
        <p className="stat-key">Total Issued</p>
        </div>
        <div className="header-stats has-text-centered">
        <figure className="image is-96x96 margin">
        <i className="fa fa-clock-o header-icon" aria-hidden="true"></i>
        </figure>
        <p className="stat-val" id="item-created">{releaseDate}</p>
        <p className="stat-key">Date Created</p>
        </div>
        <div className="header-stats has-text-centered">
        <figure className="image is-96x96 margin">
        {setIcon}
        </figure>
        <p className="stat-key">Set Icon</p>
        </div>
        </div>
        </div>
        </div>
        </header>

        <section id="item-info">
        <div className="container">
        <div className="content flex" id="search-wrapper">

        <form  className="text-center" id="searchNumber">
        <input type="text" className="input is-medium" placeholder="Search for Number..." onChange={(e) => this.setState({ targetNumber: e.target.value})}/>
        <br/>
        <button className="button outdoors" id="numsearch" onClick={this.searchNumber.bind(this)}>Find</button>
        </form>

        </div>

        <div className="tile is-ancestor text-center">
        <div className="tile is-vertical is-12">
        <div className="tile">

        {ownerLink != null &&
          <div className="tile is-parent is-vertical" id="OwnerInfo">
          <article className="tile is-child box">
          <p className="title">Owner Info: </p>
          <div className="content">
          <span>{ownerLink}</span>
          </div>
          </article>

          </div>
        }

        {storeList != 0 &&
          <div className="tile is-parent is-vertical" id="StoreInfo">
          <article className="tile is-child box">
          <p className="title">Store Info: </p>
          <div className="content">
          <h4>Cost:</h4>
          <div className="flex">
          <div className="store-cost">

          <span>{storeCost}</span>

          </div>

          </div>
          <div>
          <h4>Store(s):</h4>
          {storeLink}
          </div>
          </div>
          </article>

          </div>
        }
        {(forageList != 0 || locationList != 0) &&
          <div className="tile is-parent" id="SetInfo">
          <article className="tile is-child box">
          <p className="title">Forage Info: </p>
          <div className="content">{forageLink}</div>
          </article>
          </div>
        }

        {(mixList != 0 || formulaList != 0 || SpecialList !=0 || exceptionFlag > 0 || wandIcon != null || usedExceptionFlag == true) &&
          <div className="tile is-parent" id="mix-info">
          <article className="tile is-child box">
          <p className="title">Mix Info</p>
          <div className="content">
          <span>{formulaLink}</span>
          <div>{mixLink}</div>
          <div>{SpecialLink}</div>
          <div>{wandIcon}</div>
          </div>
          </article>
          </div>
        }
        {containerLink != null &&
          <div className="tile is-parent is-vertical" id="ContainerInfo">
          <article className="tile is-child box">
          <p className="title">Contains one of the Following: </p>
          <div className="content">
          <span>{containerLink}</span>
          </div>
          </article>

          </div>
        }
        {sourceLink != null &&
          <div className="tile is-parent is-vertical" id="ContainerSourceInfo">
          <article className="tile is-child box">
          <p className="title">Found inside Container(s): </p>
          <div className="content">
          <span>{sourceLink}</span>
          </div>
          </article>

          </div>
        }

        {(faqFlag==1) &&
          <div className="tile is-parent" id="mix-info">
          <article className="tile is-child box">
          <p className="title">Special Game Mechanic</p>
          <div className="content">
          <span>{faqLink}</span>
          </div>
          </article>
          </div>
        }

        {(bagFlag==1) &&
          <div className="tile is-parent" id="mix-info">
          <article className="tile is-child box">
          <p className="title">Special Game Mechanic</p>
          <div className="content">
          <span>{bagLink}</span>
          </div>
          </article>
          </div>
        }

        </div>
        </div>
        </div>
        </div>
        </section>
        <div id="itemDetail">
        <div className="text-center">
        {(this.state.variantFound==true) &&
          <section className="rarebeeStats_section container" key="QDStats">
          <div className="panel">
          <div className="panel-heading">
          Variants
          </div>
          <div className="panel-block">
          <div className="flex">
          <div className="level-item text-center">
          <span>{variantLink}</span>
          </div>
          </div>
          </div>
          </div>
          </section>
        }

        <div>{StatLink}</div>
        </div>
        </div>
        </div>
      )

    } else {

      pageTitle="Loading Page... - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
      return <div>
      <MetaTags>
      <title>{pageTitle}</title>
      </MetaTags>
      <div id="loading-container">
      <img id="starburst" src="https://wallazee.global.ssl.fastly.net/images/site/starburst.png" alt=""/>
      <h2 className="title is-2">Loading...</h2>
      </div>
      </div>
    }
  }
}
