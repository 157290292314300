import React from 'react'
import Panel from '../../../components/panel'

var placeArray = [
  {
      'name': 'GYM',
      'desc': ["Fitness centres (e.g., 24-Hour Fitness, Gold's Gym, etc.)','Yoga/pilates studio','Boxing, martial arts facilities','University/College gyms','NOT: Hotel, condominium gyms"],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/62-51e53dce-018c-4274-b61b-2527c63dd4aa-50.png',
      'id': 1
  },
  {
      'name':'INDOOR SPORTS',
      'desc': ['Various indoor sports facilities (e.g., indoor tennis, curling, etc.)','Indoor combined sports facilities','Indoor gun ranges','NOT: Bowling (recreation/other)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/60-51e53dac-d5b0-405b-ba92-252bc63dd4aa-50.png',
      'id': 2
  },
  {
      'name': 'OUTDOOR SPORTS',
      'desc': ['Baseball diamonds, soccer/football pitches, cricket, tennis, etc.','Outdoor combined sports facilities','Outdoor gun ranges','Equestrian centres','Parks whose primary purpose is sporting activities'],
      'img' : 'https://wallazee.global.ssl.fastly.net/images/variant/20160301-59fdb54e50b0d666c8f3fc3bd1b68c896626a4556178d27c641eb23-50.png',
      'id' : 3

  },
  {
      'name':'SPORTS STORE',
      'desc': ['Athletic equipment stores (e.g., outdoors, hunting, fishing, ski stores, etc.)','Athletic shoe stores','Bicycle stores'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160721-6ddaf7a1ae360a8afb475af0eb019ca0a6e0e0c486db36c96c172cf-50.png',
      'id': 4
  },
  {
      'name':'STADIUM',
      'desc': ['Large-scale sports stadia/venues/arena','NOT: High school stadia, unless of unusual size (outdoor sports); private boxes, etc. in a stadium'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160301-2fdeaa596d24acd2066896b3331fe77428a938dba5395852b62634f-50.png',
      'id': 5
  },
  {
      'name':'SWIMMING POOL/WATER PARK',
      'desc': ['Swimming pools and water parks','College/University pools and natatoria','NOT: Planned community/subdivision pools, hotel pools'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/64-51e56091-ada8-42ea-b612-7e44c63dd4aa-50.png',
      'id': 6
  },
  {
      'name':'WINTER',
      'desc': ['Ski hills','Curling rinks','Ice skating rinks','NOT: Individual ski lifts'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/63-51e542b2-1ac4-40a1-83b7-31fbc63dd4aa-50.png',
      'id': 7
  }
]

export default class PlaceSports extends React.Component {
      render(){
        const places = placeArray.map((place,i) => {
          return <Panel key={i} {...place}/>
        })

    return (
      <div>
        <div className="text-center">

          <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/58-51e53d68-f940-4f6f-98f3-2486c63dd4aa-200.png" alt="Sports Places"/>
          <h2>Sports</h2>
        </div>
        {places}

      </div>
    )
  }
}
