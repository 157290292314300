import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { fetchSingleThread, fetchSingleThreadSuccess, updateText, postComment, clearingCommentState } from './actions'
import ReactPaginate from 'react-paginate'
import TimeAgo from 'react-timeago'
import Modal from '../../components/modal-forums'
import endpoints from '../../util/endpoints'
import utilities from '../../util/utilities'
import MetaTags from 'react-meta-tags'
import Linkify from 'react-linkify'
import $ from 'jquery'
import 'whatwg-fetch'

const mapStateToProps = (state) => {
  return {
    forums: state.forums
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleThread: (threadID, threadPage) => dispatch(fetchSingleThread(threadID, threadPage)),
    fetchSingleThreadSuccess: () => dispatch(fetchSingleThreadSuccess()),
    updateText: (text) => dispatch(updateText(text)),
    postComment: (text) => dispatch(postComment(text)),

  }
}

class ReplyBox extends Component{
  constructor(props){
    super(props)
    this.state = {
      toggleReply: false,
      replyText: ''
    }
     this.toggle = this.toggle.bind(this);
  }

  toggle(){
    this.setState({
      toggleReply: !this.state.toggleReply,
      className: 'reply-active'
    })
  }

  updateReply = (event) => {
    this.setState({
      replyText: event.target.value
    })
  }

  scrollToBottom = () => {
    const node = ReactDOM.findDOMNode(SingleThread.refs.messagesEnd);
    node.scrollIntoView({ behavior: "smooth" });
  }

  submitReply = (event) => {
    event.preventDefault()

      const { replyText } = this.state
      let commentID = this.props.comment_id
      const threadid = this.props.thread_id
      let _history = this.props.history
      let forumid = this.props.forum_id
      let forumpage = this.props.forum_page
      let totalpages = this.props.total_pages

      const forumsUrl = endpoints.forums + '/thread/' + threadid
      const token = JSON.parse(localStorage.getItem('token'))

      const key = utilities.apiKey;

      let commentBody = new FormData();

      commentBody.append('text', replyText)
      commentBody.append('quote_comment_id', commentID)

      fetch(forumsUrl,{method: 'POST', body: commentBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
        .then((res) => {
          return res.json();
        })
        .then((data) => {

          if (data.success) {
            _history.push('/forums/' + forumid + '/' + forumpage + '/' + threadid + '/' + totalpages )
            window.location.reload()
            window.scrollTo(0, document.body.scrollHeight)
          } else {
            alert('Error')
          }

        })
        .catch((e) => {
          console.log(e);
        });

        this.setState({
          replyText: ''
        })
  }

  render(){

    const activeClass = this.state.toggleReply ? "reply-active" : "reply-inactive"

        return <form onSubmit={this.submitReply} id="submit-reply">
          <div className="reply-container">
            <nav className="level is-mobile">
              <div className="level-left">
                <a className="level-item" onClick={this.toggle}>
                  <span className="icon is-small"> <i className="fa fa-reply"></i></span>
                </a>
              </div>
            </nav>

            <article className="media" className={activeClass}>
              <div className="media-content">
                <div className="field">
                  <p className="control">
                    <textarea className="textarea" placeholder="Quote & Add a reply..." onChange={this.updateReply} value={this.state.replyText} ></textarea>
                  </p>
                </div>
                <nav className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <button className="button is-info" type="submit">Submit</button>
                    </div>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        </form>
      }
  }


class SingleThread extends Component {
  constructor(props){
    super(props)
    this.state = {
      isModalOpen: false,
      commentText: ''
    }
  }

  componentWillMount(){
    this.props.fetchSingleThread(this.props.match.params.threadID, this.props.match.params.threadPage)
  }

  submitComment = (event) => {
    event.preventDefault()

      const { commentText } = this.state
      const threadid = this.props.forums.thread_id

      const forumsUrl = endpoints.forums + '/thread/' + threadid
      const token = JSON.parse(localStorage.getItem('token'))

      const key = utilities.apiKey;

      let commentBody = new FormData();

      commentBody.append('text', commentText)

      fetch(forumsUrl,{method: 'POST', body: commentBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
        .then((res) => {
          return res.json();
        })
        .then((data) => {

          if (data.success) {
            this.props.history.push('/forums/' + this.props.match.params.forumid + '/' + this.props.match.params.page + '/' + this.props.match.params.threadID + '/' + this.props.forums.thread_total_pages  )
            window.location.reload()
            this.scrollToBottom()
          } else {
            alert('Error')
          }

        })
        .catch((e) => {
          console.log(e);
        });

        this.setState({
          commentText: ''
        })
  }

  openModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal() {
    this.setState({ isModalOpen: false })
  }

  updateComment = (event) => {
    this.setState({
      commentText: event.target.value
    })
  }

  scrollToBottom = () => {
    const node = ReactDOM.findDOMNode(this.messagesEnd);
    node.scrollIntoView({ behavior: "smooth" });
  }

  closeThread = (event) => {
    event.preventDefault()

    let forumid = this.props.match.params.id
    let threadid = this.props.match.params.threadID

    const forumsUrl = endpoints.forums + '/thread/' + threadid + '/close'
    const token = JSON.parse(localStorage.getItem('token'))

    const key = utilities.apiKey;

    fetch(forumsUrl,{method: 'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((res) => {
        return res.json();
      })
      .then((data) => {

        if (data.success) {
          window.location.reload()
        } else {
          alert('Error')
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  renderForumItems = () => {

    return this.props.forums.posts.map( (item, i) => (

      <li key={i} className="panel-block"><article className="media">
        <figure className="media-left">
          <div className="image is-64x64">
            <Link to={'/tools/profile/' + item.user.name + '/bio'} ><img src={item.user.image_url_200} alt={item.user.name} /></Link>
          </div>
        </figure>
        <div className="media-content">
          <div className="content">
            <div>
              <Link to={'/tools/profile/' + item.user.name + '/bio'} className="user-link"><strong>{item.user.name} </strong></Link>

              {
                (() => {if(item.user.is_staff){
                  return <span className="tag is-warning">Staff</span>
                }})()
              }

              {
                (() => {if(item.user.is_beekeeper){
                  return <span className="tag is-info">BeeKeeper</span>
                }})()
              }

              <small><TimeAgo date={item.created * 1000} /></small>
              <br/>

              {
                (() => {if(item.quote){
                  return <p className="quote"><strong>{item.quote.username}:</strong><Linkify>{item.quote.text}</Linkify></p>
                }})()
              }

              {
                (() => {if(item.is_html === 1){
                  return <Linkify><div dangerouslySetInnerHTML={{__html:item.text}} /></Linkify>
                } else {
                  return <p>{item.text.split('\n').map((item,i) => {
                    return <Linkify><span key={i}>{item}<br/></span></Linkify>
                  })}</p>
                }})()
              }

            </div>
          </div>

          {
            (() => {if(this.props.forums.post_data.is_closed){
                      return null
            } else {
              return <ReplyBox comment_id={item.id} thread_id={this.props.forums.thread_id} history={this.props.history} forum_id={this.props.match.params.forumid} forum_page={this.props.match.params.page} total_pages={this.props.forums.thread_total_pages} />
            }
            })()
          }

        </div>
      </article>
      </li>
                  ))
                }

                  handlePageChange = (data) => {
                    this.props.history.push('/forums/' + this.props.match.params.forumid + '/' + this.props.match.params.page + '/' + this.props.match.params.threadID + '/' + data.selected )
                    this.props.fetchSingleThread(this.props.match.params.threadID, data.selected)
                  }

                  renderPagination(){



    var pageNumber = this.props.forums.thread_total_pages + 1

    let currentPage = parseInt(this.props.match.params.threadPage)

    if(this.props.forums.thread_total_pages >= 1){
      return <ReactPaginate previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={<span>...</span>}
        breakClassName={"pagination-ellipsis"}
        pageCount={pageNumber}
        initialPage={currentPage}
        marginPagesDisplayed={3}
        pageRangeDisplayed={5}
        pageLinkClassName={"pagination-link"}
        containerClassName={"pagination is-centered"}
        subContainerClassName={"pagination-list"}
        activeClassName={"is-current"}
        previousLinkClassName={"pagination-previous"}
        nextLinkClassName={"pagination-next"}
        onPageChange={this.handlePageChange}
             />
    }
  }

  render(){

    if(!localStorage.getItem('token')){
      return <div className="container text-center">

        <img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/472-512.png" style={{'width':'500px'}} alt="You must be logged in to post comments" title="YOU SHALL NOT POST"/>
        <h3>You must be logged in to view the threads and post comments.</h3>
        <Link className="button outdoors" to="/login">Login</Link>
      </div>
    } else if(!this.props.forums.thread_id){
      return <div id="loading-container">
        <img id="starburst" src="https://wallazee.global.ssl.fastly.net/images/site/starburst.png" alt=""/>
        <h2 className="title is-2">Loading</h2>
      </div>
    } else {

    let forumname

      switch (this.props.match.params.forumid) {
        case '1':   forumname = 'Official Business'
        break;
        case '11':  forumname = 'NewBees Hive'
        break;
        case '2':   forumname = 'Item Directory'
        break;
        case '3':   forumname = 'The Trading Post'
        break;
        case '6':   forumname = 'Auction House'
        break;
        case '5':   forumname = 'Bug Reporting'
        break;
        case '4':   forumname = 'WallaBee Chat'
        break;
        case '12':  forumname = 'Party Room'
        break;
        case '10':  forumname = 'Phoenixes of Jabbaclaw'
        break;
        case '7':   forumname = 'Unicorns of Slithering'
        break;
        case '9':   forumname = 'Yetis of Snugglepuff'
        break;
        case '8':   forumname = 'Dragons of Colbourndor'
        break;
        case '5':   forumname = 'General Chat'
        break;
        case '50':   forumname = 'Lemon Drop'
        break;
        case '13':   forumname = 'WallaBoo! Trading'
        break;
        case '22':  forumname = 'Club Phoenix'
        break;
        case '21':   forumname = 'Club Unicorn'
        break;
        case '20':   forumname = 'Club Yeti'
        break;
        case '23':   forumname = 'Club Dragon'

        default: return null
      }

    return(
      <div className="container" id="forums-thread">

        <MetaTags>
          <title>{this.props.forums.post_data.title} | {forumname} | Forums | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
        </MetaTags>

        <Modal isOpen={this.state.isModalOpen} onClose={() => this.closeModal()}>
          <div className="box text-center">

            <h3 className="title is-5">Are you sure you want to close this thread?</h3>

            <div className="field is-grouped is-grouped-centered">
              <p className="control">
                <a className="button travel forum-btn" onClick={this.closeThread}>
                  Close Thread
                </a>
              </p>
              <p className="control">
                <a className="button is-dark forum-btn" onClick={() => this.closeModal()}>
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </Modal>

        <div className="text-center">
          <a className="button outdoors" href={'/forums/' + this.props.match.params.forumid + '/' + this.props.match.params.page} >Back to {forumname}</a>

          {
            (() => {if(this.props.forums.post_data.is_closed){
              return null
            } else if (this.props.forums.can_close){
              return <button className="button travel" onClick={() => this.openModal()}>Close Thread</button>
            } else {
              return null
            }
            })()
          }
          <br/><br/>
        </div>

        {this.renderPagination()}
        <br/>
        <ul className="panel">
          <div className="panel-heading">
            <strong style={{'color':'white'}}>{this.props.forums.post_data.title} </strong>
            {
              (() => {if(this.props.forums.post_data.is_closed){
                return <span className="tag is-dark">Closed</span>
              }
              })()
            }
          </div>

          {
            (() => {if(this.props.forums.item){
              return <div className="panel-block text-center">
                <img src={this.props.forums.item.image_url} alt={this.props.forums.item.name} style={{'width':'220px'}}/><br/>
                <strong>{this.props.forums.item.name}</strong>
              </div>
            }})()
          }

          {this.renderForumItems()}

          {
            (() => {if(this.props.forums.post_data.is_closed){
              return null
            } else {
              return <article className="media" id="comment-container" ref={(el) => { this.messagesEnd = el; }}>
                <figure className="media-left">
                  <p className="image is-64x64">
                    <img src={this.props.forums.user_avatar} alt=""/>
                  </p>
                </figure>
                <div className="media-content">
                  <form onSubmit={this.submitComment} id="submit-comment">
                    <div className="field">
                      <p className="control">
                        <textarea className="textarea" placeholder="Add a reply..." onChange={this.updateComment} value={this.state.commentText} ></textarea>
                      </p>
                    </div>
                    <nav className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <button className="button is-info" type="submit" id="submit-btn">Submit</button>
                        </div>
                      </div>

                    </nav>
                  </form>
                </div>
              </article>
            }
            })()
          }

        </ul>
      </div>
    )}
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleThread))
