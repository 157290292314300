import React from 'react'
import { connect } from 'react-redux'
import { fetchSetCompletion } from '../actions'
import { Link, Switch, Route } from 'react-router-dom'

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSetCompletion: () => dispatch(fetchSetCompletion())
  }
}

class ProfileSets extends React.Component{
  constructor(){
    super()
    this.state = { search: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e){
    this.setState({search: e.target.value})
  }

  renderBranded () {

      return  (
       <div key="20" className="card">
         <div className="card-image">
           <Link to={'/u/' + this.props.profile.name + '/sets/20'}>
             <img src="https://wallazee.global.ssl.fastly.net/images/collections/20-4facea68-da9c-4fdf-b510-21df4d4902ab-200.png" alt="Branded Items" />
           </Link>
         </div>
         <div className="card-content">
           <div className="set-info">
             <h5>Branded Items</h5>
           </div>
         </div>
       </div>
       )
  	}

  renderUnique () {

      return  (
       <div key="25" className="card">
         <div className="card-image">
           <Link to={'/u/' + this.props.profile.name + '/sets/25'}>
             <img src="https://wallazee.global.ssl.fastly.net/images/collections/-51641054-80dc-4520-8d7c-7d59c63dd4aa-200.png" alt="Unique Items" />
           </Link>
         </div>
         <div className="card-content">
           <div className="set-info">
             <h5>Unique Items</h5>
           </div>
         </div>
       </div>
       )
  	}

  renderSets () {

    var libraries = this.props.profile.sets,
           search = this.state.search.trim().toLowerCase();

      var wallagraphics = 13;
      var uniques = 25;
      var exclusives = 20;

      libraries.map((sets,i) => {
	      if (sets.id==wallagraphics) { libraries.splice(i, 1); }
	      if (sets.id==uniques) { libraries.splice(i, 1); }
	      if (sets.id==exclusives) { libraries.splice(i, 1); }
      })

       if(search.length > 0){

           libraries = libraries.filter(function(l){
               return l.name.toLowerCase().match( search );
           });
       }

    return libraries.map( set => (

       <div key={set.id} className="card">
         <div className="card-image">
           <Link to={'/u/' + this.props.profile.name + '/sets/' + set.id}>
             <img  src={set.image_url_200} alt={set.name} />
           </Link>
         </div>

         <div className="card-content">
           <div className="progress-bar">
             { (set.percentage <= 33) && <progress className="progress is-danger" value={set.percentage} max="100"> : {set.percentage}</progress> }
             { (set.percentage <= 66 && set.percentage >= 34) && <progress className="progress is-warning" value={set.percentage} max="100"> : {set.percentage}</progress>}
             { (set.percentage <= 99 && set.percentage >= 67) && <progress className="progress is-info" value={set.percentage} max="100"> : {set.percentage}</progress>}
             { (set.percentage === 100) && <progress className="progress is-success" value={set.percentage} max="100"> : {set.percentage}</progress> }

             <h4 className="progress-percent">{set.percentage}%</h4>
           </div>

           <div className="set-info">
             <h5>{set.name}</h5>
             {set.number > 0 ? <strong className="set-finished">Finished: #{set.number}</strong> : <strong>&nbsp;</strong> }
           </div>
         </div>
       </div>
        )
  )}

  render() {

    return (
      <div>
        <div className="text-center">

          <Switch>
            <Route path={'/u/:username/sets/:id'}  />
          </Switch>

          <input className="banner-search input is-medium" type="text" placeholder="Search Your Sets..." onChange={this.handleChange}/>
        </div>
        <div className="flex" id="set-completion">
          {this.renderSets()}

        </div>

      </div>

    )
  }
}

//{this.renderBranded()}
//{this.renderUnique()}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSets)
