import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import $ from "jquery";

export default class Footer extends Component{
  constructor(){
    super()
    this.state = { posts: [] }
  }

  getBlog(){
      $.ajax({ url:'https://www.wallabeeblog.com/feed/json', dataType:'jsonp'})
      .then((data) => {
        this.setState({ posts: data });
      });
  }

  componentDidMount(){
    // this.getBlog()
  }


//  <div id="footer-blog">
//    <h3>Latest Blog Posts</h3>
//    <ul>
//      { blogPosts }
//    </ul>
//  </div>


  render() {

    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    const blogPosts = this.state.posts.slice(0, 7).map((post, i) => {
      return <li key={i}><a href={post.permalink} target="_blank" rel="noopener noreferrer">{renderHTML(post.title)}</a></li>
    });

    return <footer id="footer">
      <div className="container">
        <div id="footer-social">
          <NavLink to="/"><img id="footer-logo" src="https://wallazee.global.ssl.fastly.net/images/site/logo.png" alt="WallaBee Logo"/></NavLink>
          <div><a href="https://itunes.apple.com/us/app/wallabee-collecting-trading/id1155134198?mt=8" target="_blank" rel="noopener noreferrer"><img src="https://wallazee.global.ssl.fastly.net/images/site/appstorebadge.svg" alt="App Store"/></a><br/>
            <a href="https://play.google.com/store/apps/details?id=com.wallabee&hl=en" target="_blank" rel="noopener noreferrer"><img className="google-play" src="https://wallazee.global.ssl.fastly.net/images/site/badge_googleplay.png" alt="Google Play Store"/></a>
          </div>
          <a href="mailto:support@wallabeegame.com" id="footer-email">support@wallabeegame.com</a>
          <div className="social">
            <a className="icon is-medium" href="https://www.facebook.com/WallaBeeGame/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square" aria-hidden="false"></i></a>
            <a className="icon is-medium" href="https://twitter.com/wallabeegame" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square" aria-hidden="false"></i></a>
            <a className="icon is-medium"  href="https://www.instagram.com/wallabeegame/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="false"></i></a>
          </div>
        </div>
        <div id="footer-sitemap">
          <h3>Sitemap</h3>
          <ul>
            <li><NavLink to="/">Home</NavLink></li>
            <li><NavLink to="/about">About</NavLink></li>
            <ul>
            <li><NavLink to="/faq">FAQ</NavLink></li>
            <li><NavLink to="/places">Places</NavLink></li>
            <li><NavLink to="/rules">Rules</NavLink></li>
            </ul>
            <li><NavLink to="/uniques">Uniques</NavLink></li>
            <li><NavLink to="/tools/sets">ItemBee</NavLink></li>
            <li><NavLink to="/forums">Forums</NavLink></li>
            <li><a href="https://wallabee.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Support</a></li>
            <li><a href="https://www.wallabeeblog.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
            <li><a href="https://store.munzee.com/collections/wallabee" target="_blank" rel="noopener noreferrer">Store</a></li>
            <li><NavLink to="/retrobee">RetroBee</NavLink></li>
            <li><NavLink to="/terms">Terms of Service</NavLink></li>
            <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
          </ul>
        </div>
      </div>
      <div className="legal">
        <div className="container">
          <p>
            Copyright <span className="icon is-small"><i className="fa fa-copyright" aria-hidden="true"></i></span> 2021 <a href="https://www.freezetag.com" target="_blank" rel="noopener noreferrer">Freeze Tag, Inc</a> v{global.appVersion}
          </p>
        </div>
      </div>
    </footer>
  }
}
