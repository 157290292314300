import React from 'react'
import { connect } from 'react-redux'
import { loginUser, updateUsername, updatePassword, pushProfile } from './actions'
import { NavLink, withRouter } from 'react-router-dom'

const mapStateToProps = (state) => {
  return {login: state.login}
}

const mapDispatchToProps = (dispatch) => {
  return {
            updateUsername: (text) => dispatch(updateUsername(text)),
            updatePassword: (text) => dispatch(updatePassword(text)),
            loginUser: (event) => dispatch(loginUser(event)),
            pushProfile: (userId, token) => dispatch(pushProfile(userId, token))
         }
}

class Login extends React.Component {

  componentWillReceiveProps(nextProps){
    if(!this.props.login.userId && nextProps.login.userId) {
      this.props.history.push('/u/' + this.props.login.username + '/pouch')
    }
  }

  render() {

    return (
      <div className="container" id="login">
        <div className="panel login-form">
          <div className="panel-heading"> <img className="logo-wallabee" src="https://wallazee.global.ssl.fastly.net/images/site/logo_wallabee.png" alt="WallaBee Logo"/></div>
          <div className="panel-block">
            <form onSubmit={this.props.loginUser}>
              <input type="text" placeholder="Username" value={this.props.login.username} onChange={(e) => this.props.updateUsername(e.target.value) }></input>
              <input type="password" placeholder="Password" value={this.props.login.password} onChange={(e) => this.props.updatePassword(e.target.value) }></input>
              <button type="submit" className="button outdoors">Login</button>
            </form>
          </div>
        </div>
        <div className="notification is-danger" id="incorrect-login">
          Username or Password is incorrect
        </div>

      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
