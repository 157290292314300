import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import MetaTags from 'react-meta-tags'

const idPic = {
  width: '130px'
}

export default class About extends Component {

  render() {

    return <div className="container" id="about">

      <MetaTags>
        <title>About WallaBee | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
      </MetaTags>

      <div className="text-center">
        <h1>Meet the Team</h1>
        <p>WallaBee is a self-funded startup from Munzee. It is currently maintained by a core team of 3 staff members and one volunteer player:</p>
      </div>
      <div className="columns" id="id-wrapper">
        {/* Start Rob */}
        <div className="column is-half">
          <div className="card is-fullwidth content-box">
            <div className="card-header">
              <h2 className="card-header-title">President</h2>
            </div>
            <div className="card-content">

              <div className="content">
                <div className="columns">
                  <div className="column is-narrow">
                    <img className="image is-128x128 card-pic" src="https://wallazee.global.ssl.fastly.net/images/site/rob.jpg" alt="Rob Vardeman"></img>
                  </div>

                  <div className="column" id="card-details">
                    <div >
                      <small>Name</small>
                      <p>Rob Vardeman</p>
                    </div>

                    <div >
                      <small>
                        WallaBee ID
                      </small>
                      <p><a href="https://www.wallabeegame.com/tools/profile/mrv/bio">MrV</a></p>
                    </div>

                    <div>
                      <small>Favorite Set & Item</small>
                      <p  className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/collections/S113-20190215-cebb79b1442c4a8796e74a24e6ec5eef93b5e4c7c6d573eda4a78b1-200.png'/>" alt="Straying Alive"><a href="https://www.wallabeegame.com/tools/sets/113">Straying Alive</a></p>
                      <p className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/variant/20151014-9f12675d5b9da0c6cf2294fdd4b5934a3803a73c8c4621b986478ec-200.png'/>" alt="Firebreather"><a href="https://www.wallabeegame.com/tools/item/1924">Firebreather</a></p>
                    </div>

                    <div>
                      <small>Social</small>
                      <a className="twitter icon is-medium" href="https://twitter.com/CZeeO" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square" aria-hidden="false"></i></a>
                    </div>

                  </div>
                </div>
                <p>
                Rob has played WallaBee since the early days and has a real passion for the game. His experience leading the crew over at Munzee has prepared him to offer WallaBee players additional opportunities beyond gameplay with the cards we all love. He enjoys building communities and loves the interactive and cooperative environment that exists here. He loves reading the forums and bringing ideas to life from creative players.</p>

                <img className="image is-128x128 id-img" src="https://wallazee.global.ssl.fastly.net/images/variant/20170320-4385ce5a9ac9d84e3dcc324da53e0f89f462872a75e9585a80d60cf-200.png" alt="Rob Vardeman"/>

              </div>
            </div>
          </div>
        </div>
        {/*End Rob*/}
        <div className="column">
          <div className="card is-fullwidth content-box">
            <div className="card-header">
              <h2 className="card-header-title">Product Manager</h2>
            </div>
            <div className="card-content">

              <div className="content">
                <div className="columns">
                  <div className="column is-narrow">
                    <img className="image is-128x128 card-pic" src="https://wallazee.global.ssl.fastly.net/images/users/13338-eb71586d-becc-466b-a63b-faaa09fda3b2-200.jpg" alt="Robbie McGuire"></img>
                  </div>

                  <div className="column" id="card-details">
                    <div >
                      <small>Name</small>
                      <p>Robbie McGuire</p>
                    </div>

                    <div >
                      <small>
                        WallaBee ID
                      </small>
                      <p><a href="https://www.wallabeegame.com/tools/profile/redcarrobbie/bio"> RedCarRobbie</a></p>
                    </div>

                    <div>
                      <small>Favorite Set & Item</small>
                      <p className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/collections/S131-20190215-adc11376c38ed888aa79d3ff86be1b1055321507a5b6e06dbacb8b7-200.png'/>" alt="Sushi Madness"><a href="https://www.wallabeegame.com/tools/sets/131">Sushi Madness</a></p>
                      <p className="fave-items" data-tip="<img src='https://wallazee.global.ssl.fastly.net/images/variant/20160601-27a3521fc682dc2887c279a0088defe77ec80f42820d8b1dd750ada-200.png'/>" alt="Surf Boards"><a href="https://www.wallabeegame.com/tools/item/1299">Surf Boards</a></p>
                    </div>

                    <div>
                      <small>Social</small>
                      <a className="twitter icon is-medium" href="https://twitter.com/RedCarRobbie" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square" aria-hidden="false"></i></a>
                    </div>

                  </div>
                </div>
                <p>
                  Robbie has taken over the face of WallaBee. Starting as a player in January 2015, he began running almost every aspect of the game nearly a year later. From curing the dreaded Friend Bug to directing future sets, he strives toward making the community a better place, all with the future of mobile-based collecting in mind. When he isn&apos;t fiddling over something work related, you can usually find him with his wife and two dogs, catching up on life.
                </p>
                <img className="id-img" src="https://wallazee.global.ssl.fastly.net/images/variant/20170320-3e4f3b54eab541dea64dd5655a6f8689fc4cfa7fc5ce094971da72c-200.png" alt="Robbie McGuire"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-half">
          <div className="card is-fullwidth content-box">
            <div className="card-header">
              <h2 className="card-header-title">Lead Designer</h2>
            </div>
            <div className="card-content">

              <div className="content">
                <div className="columns">
                  <div className="column is-narrow">
                    <img className="image is-128x128 card-pic" src="https://wallazee.global.ssl.fastly.net/images/site/andy.jpg" alt="Andy Cameron"></img>
                  </div>

                  <div className="column" id="card-details">
                    <div >
                      <small>Name</small>
                      <p>Andy Cameron</p>
                    </div>

                    <div >
                      <small>
                        WallaBee ID
                      </small>
                      <p><a href="https://www.wallabeegame.com/tools/profile/225/bio">Andrew Cameron</a></p>
                    </div>

                    <div>
                      <small>Favorite Sets</small>
                      <p className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/collections/S110-20190215-a8f90c03324292735ac7e245f055727bd944cc7c1e41d65a06caee6-200.png'/>" alt="The Beanstalk"><a href="https://www.wallabeegame.com/tools/sets/110">The Beanstalk</a></p>
                      <p className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/collections/S114-20190215-86887f2664bc0d21ed11e7d86d88364cdf394a7b45bcc71e3593b3e-200.png'/>" alt="Carnival of Curiosities"><a href="https://www.wallabeegame.com/tools/sets/114">Carnival of Curiosities</a></p>
                    </div>

                    <div>
                      <small>Social</small>
                      <a className="insta icon is-medium" href="https://www.instagram.com/karategrafika/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="false"></i></a>
                    </div>

                  </div>
                </div>
                <p>Every company has a special sauce. For McDonalds, that's literal. For us, it's Andy from <a href="https://www.flickr.com/photos/karategrafika/" target="_blank" rel="noopener noreferrer">Karate Grafika</a>. Andy has produced over 1800 jaw-dropping beautiful items for us comprising of over 100 sets and nearly 300 player uniques.</p>
                <img className="id-img" src="https://wallazee.global.ssl.fastly.net/images/variant/20170320-d545b7b4b0c1cac6ce97bdc211515cdef9c4b406cc07797ebdc652e-200.png" alt="Andy Cameron"/>
              </div>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="card is-fullwidth content-box">
            <div className="card-header">
              <h2 className="card-header-title">Volunteer Programmer</h2>
            </div>
            <div className="card-content">

              <div className="content">
                <div className="columns">
                  <div className="column is-narrow">
                    <img className="image is-128x128 card-pic" src="https://munzee.global.ssl.fastly.net/images/new_badges/m_mizak.png" alt="Mizak"></img>
                  </div>

                  <div className="column" id="card-details">
                    <div >
                      <small>Name</small>
                      <p>Mizak</p>
                    </div>

                    <div >
                      <small>
                        WallaBee ID
                      </small>
                      <p><a href="https://www.wallabeegame.com/tools/profile/Mizak/bio">Mizak</a></p>
                    </div>

                    <div>
                      <small>Favorite Set & Item</small>
                      <p className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/collections/S126-20190215-99cb647b3d30e52d569556c3c86e19389031cf79e77ee6340e1e575-200.png'/>" alt="Veni Vidi Mixi"><a href="https://www.wallabeegame.com/tools/sets/126">Veni Vidi Mixi</a></p>
                      <p className="fave-items" data-tip="<img className='fave-item' src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/34-200.png'/>" alt="Sasquatch"><a href="https://www.wallabeegame.com/tools/item/34">Sasquatch</a></p>
                    </div>

                    <div>
                      <small>Social</small>
                      <a className="twitter icon is-medium" href="https://twitter.com/bighagrid" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square" aria-hidden="false"></i></a>
                    </div>

                  </div>
                </div>
                <p>Mizak has been around Wallabee since the dark ages, when very few features existed. He is the self-proclaimed collector of #1 cards, boasting a collection of more than 250 over 8 years of gameplay. His phone is always open.</p>
                <img className="id-img" src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/2510-200.png" alt="Mizak"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactTooltip effect="solid" type="light" html={true} scrollHide={true}/>
    </div>
  }
}
