import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'

class RetroBee extends Component{
  render(){
    return(
      <div className="text-center" id="retrobee">
        <MetaTags>RetroBee | WallaBee: Collecting and Trading Card Game on iOS & Android</MetaTags>

        <iframe width="100%" height="800" align="center" src="https://wallazee.global.ssl.fastly.net/RetroBee/index.html" frameborder="0" allowfullscreen></iframe>
        
      </div>
        )
        }
        }

export default RetroBee
