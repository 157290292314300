import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import 'whatwg-fetch';

export default class BirthdayCakes extends React.Component{
  constructor(){
    super()
    this.state = {userInfo:[],birthdayInfo:[],month:1,day:1}
    this.fetchProfile = this.fetchProfile.bind(this)
    this.fetchBirthday = this.fetchBirthday.bind(this)

  }

  fetchProfile(){
    const personID = JSON.parse(localStorage.getItem('userId'))
    const key = utilities.apiKey;
    const profileurl = endpoints.userProfile+personID;

    fetch(profileurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(jsonPerson => {
      this.setState({
        userInfo: jsonPerson
      });
    });
  }

  fetchBirthday(){
    const personID = JSON.parse(localStorage.getItem('userId'))
    const key = utilities.apiKey;
    const chkbdayURL = endpoints.userProfile+personID+"/chkbday";

    var birthDate = null;

    fetch(chkbdayURL,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(jsonBday => {
      this.setState({
        birthdayInfo: jsonBday
      });
    })
  }


  componentDidMount() {
    this.fetchBirthday()
    this.fetchProfile()
  }

  doSet() {
		var uid = this.state.userInfo.id
    var month = this.state.month
    var day = this.state.day

	 	console.log("ID: " + uid + ", month: " + month + ", day: " + day )

		if (uid === null) {
			var resultText = <div className="message is-danger">
        <div className="message-header">
          <strong>Error</strong>
        </div>
        <div className="message-body">
          Birthday Not Set.
        </div>
      </div>

		} else {
			this.setBirthday(uid,month,day)
		}
	}

  setBirthday(id,month,day){
    const key = utilities.apiKey;
    const personID = JSON.parse(localStorage.getItem('userId'))
    const uname = JSON.parse(localStorage.getItem('username'))
    const setBirthdayURL = endpoints.userProfile+personID+"/setbirthday";
    var birthday = { 'month': month, 'day': day}
  	var formBody = [];

  	for (var property in birthday) {
  	  var encodedKey = encodeURIComponent(property);
  	  var encodedValue = encodeURIComponent(birthday[property]);
  	  formBody.push(encodedKey + "=" + encodedValue);
  	}
  	formBody = formBody.join("&");
      fetch(setBirthdayURL,{method:'POST',
      headers: {'X-WallaBee-API-Key': key,  'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded'},
      body: formBody })
        .then(response => response.json())
        .then(json => {
       		if (json.error) {
      			console.log(json.error)
      			var resultText = <div className='travel' style={{'color':'white', 'padding':'5px'}}>Error: {json.error}</div>
      		} else {
            window.location.reload(false);
      		}
        });

  }




  render() {

    const personName = JSON.parse(localStorage.getItem('username'))

    if (this.state.infoLoaded == false) {
      var pageTitle="Loading Page... | WallaBee: Collecting and Trading Card Game on iOS";
      return <div>


        <div id="loading-container">
          <img id="starburst" src="https://wallazee.global.ssl.fastly.net/images/site/starburst.png"/>
          <h2 className="title is-2">Loading</h2>
        </div>

      </div>

        } else {

      var pageTitle=this.state.userInfo.name+"'s Birthday Information | WallaBee: Collecting and Trading Card Game on iOS";


      return <div id="lockedlist-container">
        <MetaTags>
          <title>{pageTitle}</title>
        </MetaTags>
        <div className="container">
          <div className="columns is-mobile is-multiline">
            <div className="column is-desktop is-full-mobile">

              <div className="panel">
                <center><h1>Set Your Birthday</h1>
                <span id="birthday">
                    {(() => {
                      switch (this.state.birthdayInfo.birthday) {
                        case "Date Missing":     return [
                                            <form key="sbf" id="setbirthdayform">
                                            <div className="field text-center">
                                              <p className="text-center">
                                              By setting your birthday you will automatically receive a Birthday Cake Item in-game on the day of your birthday.<br/>
                                              Caution: You can only set this date once so please make sure you set it carefully.<br/>
                                                <span className="select is-medium">
                                                  <select name="month" onChange={(e)=>this.setState({month: e.target.value})}>
                                                    <option key="m1" value='1'>January</option>
                                                    <option key="m2" value='2'>February</option>
                                                    <option key="m3" value='3'>March</option>
                                                    <option key="m4" value='4'>April</option>
                                                    <option key="m5" value='5'>May</option>
                                                    <option key="m6" value='6'>June</option>
                                                    <option key="m7" value='7'>July</option>
                                                    <option key="m8" value='8'>August</option>
                                                    <option key="m9" value='9'>September</option>
                                                    <option key="m10" value='10'>October</option>
                                                    <option key="m11" value='11'>November</option>
                                                    <option key="m12" value='12'>December</option>
                                                  </select>
                                                </span>
                                                <span className="select is-medium">
                                                  <select name="day" onChange={(e)=>this.setState({day: e.target.value})}>
                                                  <option key="d1">1</option>
                                                  <option key="d2">2</option>
                                                  <option key="d3">3</option>
                                                  <option key="d4">4</option>
                                                  <option key="d5">5</option>
                                                  <option key="d6">6</option>
                                                  <option key="d7">7</option>
                                                  <option key="d8">8</option>
                                                  <option key="d9">9</option>
                                                  <option key="d10">10</option>
                                                  <option key="d11">11</option>
                                                  <option key="d12">12</option>
                                                  <option key="d13">13</option>
                                                  <option key="d14">14</option>
                                                  <option key="d15">15</option>
                                                  <option key="d16">16</option>
                                                  <option key="d17">17</option>
                                                  <option key="d18">18</option>
                                                  <option key="d19">19</option>
                                                  <option key="d20">20</option>
                                                  <option key="d21">21</option>
                                                  <option key="d22">22</option>
                                                  <option key="d23">23</option>
                                                  <option key="d24">24</option>
                                                  <option key="d25">25</option>
                                                  <option key="d26">26</option>
                                                  <option key="d27">27</option>
                                                  <option key="d28">28</option>
                                                  <option key="d29">29</option>
                                                  <option key="d30">30</option>
                                                  <option key="d31">31</option>
                                                  </select>
                                                </span>
                                              </p>
                                            </div>
                                            <a className="button outdoors" onClick={this.doSet.bind(this)}>Set Birthday</a>
                                          </form>
                                        ]
                        default:                 return <span ><h2>Thank You for setting your birthday. You will receive an in-game Birthday Cake on {this.state.birthdayInfo.birthday}.</h2></span>;
                      }
                    })()}
                </span>
                </center>


              </div>


            </div>
          </div>
        </div>
      </div>

    }
  }
}
