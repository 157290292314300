import React, { Component } from 'react'
import endpoints from '../../util/endpoints'
import utilities from '../../util/utilities'
import ReactTooltip from 'react-tooltip'
import $ from "jquery";
import 'whatwg-fetch'

export default class News extends Component {
  constructor(){
    super()
    this.state = { posts: [] }
  }

  getBlog(){
      $.ajax({ url:'https://www.wallabeeblog.com/feed/json', dataType:'jsonp'})
      .then((data) => {
        this.setState({ posts: data });
      });
  }

  componentDidMount(){
    this.getBlog()
  }



  render() {
//
//      <ReactTooltip effect="solid" type="light" scrollHide={true}/>

    const renderHTML = (rawHTML: string) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });
    const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
    const blogPosts = this.state.posts.slice(0, 6).map((post, i) => {
      var tempDate = new Date(post.date).toLocaleDateString('en-US', DATE_OPTIONS);
      return <div key={i} className="news">
              <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                <img src={post.thumbnail} className='imgLayer' />
                <div className='topLayer'></div>
                <div className='topLayer-text'>{renderHTML(post.title)}</div>
                <div className='topLayer-bottomText'>{tempDate}</div>

              </a>
            </div>
    });

    return <div id="news">

      <h2>News</h2>
        { blogPosts }

    </div>

  }
}
