const Retired={
  "Retired":[
    {
        "item_type_id": "251"
      },
      {
          "item_type_id": "404"
        },
        {
            "item_type_id": "438"
          },
  	{
        "item_type_id": "522"
      },
  	{
        "item_type_id": "524"
      },
  	{
        "item_type_id": "533"
      },
  	{
        "item_type_id": "534"
      },
  	{
        "item_type_id": "589"
      },
  	{
        "item_type_id": "644"
      },
  	{
        "item_type_id": "642"
      },
  	{
        "item_type_id": "696"
      },
  	{
        "item_type_id": "723"
      },
  	{
        "item_type_id": "752"
      },
  	{
        "item_type_id": "776"
      },
  	{
        "item_type_id": "775"
      },
  	{
        "item_type_id": "847"
      },
  	{
        "item_type_id": "870"
      },
      {
        "item_type_id": "873"
      },
  	{
        "item_type_id": "818"
      },
  	{
        "item_type_id": "819"
      },
  	{
        "item_type_id": "911"
      },
  	{
        "item_type_id": "914"
      },
  	{
        "item_type_id": "939"
      },
  	{
        "item_type_id": "944"
      },
  	{
        "item_type_id": "965"
      },
  	{
        "item_type_id": "963"
      },
  	{
        "item_type_id": "1169"
      },
  	{
        "item_type_id": "1220"
      },
  	{
        "item_type_id": "1227"
      },
  	{
        "item_type_id": "1233"
      },
  	{
        "item_type_id": "1232"
      },
  	{
        "item_type_id": "1231"
      },
  	{
        "item_type_id": "1230"
      },
  	{
        "item_type_id": "1229"
      },
  	{
        "item_type_id": "1228"
      },
  	{
        "item_type_id": "1253"
      },
  	{
        "item_type_id": "1310"
      },
  	{
        "item_type_id": "1396"
      },
  	{
        "item_type_id": "1344"
      },
  	{
        "item_type_id": "1321"
      },
      {
          "item_type_id": "1397"
        },
    {
          "item_type_id": "1402"
        },
  	{
        "item_type_id": "1411"
      },
  	{
        "item_type_id": "1433"
      },
  	{
        "item_type_id": "1469"
      },
      {
          "item_type_id": "1472"
        },
        {
            "item_type_id": "1473"
          },
  	{
        "item_type_id": "1492"
      },
  	{
        "item_type_id": "1491"
      },
  	{
        "item_type_id": "1509"
      },
  	{
        "item_type_id": "1530"
      },
  	{
        "item_type_id": "1536"
      },
  	{
        "item_type_id": "1546"
      },
  	{
        "item_type_id": "1616"
      },
  	{
        "item_type_id": "1663"
      },
  	{
        "item_type_id": "1727"
      },
  	{
        "item_type_id": "1762"
      },
  	{
        "item_type_id": "1818"
      },
  	{
        "item_type_id": "1823"
      },
  	{
        "item_type_id": "1817"
      },
  	{
        "item_type_id": "1821"
      },
  	{
        "item_type_id": "1901"
      },
  	{
        "item_type_id": "1903"
      },
  	{
        "item_type_id": "2004"
      },
  	{
        "item_type_id": "2025"
      },
  	{
        "item_type_id": "2533"
      },
  	{
        "item_type_id": "2418"
      },
  	{
        "item_type_id": "2397"
      },
  	{
        "item_type_id": "2398"
      },
  	{
        "item_type_id": "2396"
      },
  	{
        "item_type_id": "2395"
      },
  	{
        "item_type_id": "2392"
      },
  	{
        "item_type_id": "2391"
      },
  	{
        "item_type_id": "2394"
      },
  	{
        "item_type_id": "2393"
      },
        {
            "item_type_id": "2575"
          },
  	{
        "item_type_id": "3049"
      },
  	{
        "item_type_id": "1286"
      },
  	  {
        "item_type_id": "1933"
      },
  	  {
        "item_type_id": "3140"
      },
  	  {
        "item_type_id": "3143"
      },
  	  {
        "item_type_id": "3144"
      },
  	  {
        "item_type_id": "3145"
      },
  	  {
        "item_type_id": "3149"
      },
  	  {
        "item_type_id": "2167"
      },
  	  {
        "item_type_id": "660"
      },
  	  {
        "item_type_id": "692"
      },
    {
      "item_type_id": "3345"
    },
    {
      "item_type_id": "3346"
    },
    {
      "item_type_id": "3347"
    },
    {
      "item_type_id": "3229"
    },
    {
      "item_type_id": "3384"
    },
    {
      "item_type_id": "3385"
    },
    {
      "item_type_id": "3386"
    },
    {
      "item_type_id": "3387"
    },
    {
      "item_type_id": "3388"
    },
    {
      "item_type_id": "3389"
    },
    {
      "item_type_id": "1345"
    },
    {
      "item_type_id": "3626"
    },
    {
      "item_type_id": "3337"
    },
    {
      "item_type_id": "3630"
    },
    {
      "item_type_id": "1596"
    },
    {
      "item_type_id": "2537"
    },
    {
      "item_type_id": "3434"
    },
    {
      "item_type_id": "3435"
    },
    {
      "item_type_id": "3124"
    },
    {
      "item_type_id": "1791"
    },
    {
      "item_type_id": "1475"
    },
    {
      "item_type_id": "964"
    },
    {
      "item_type_id": "797"
    },
    {
      "item_type_id": "663"
    },
    {
      "item_type_id": "1441"
    },
    {
      "item_type_id": "3083"
    },
    {
      "item_type_id": "4120"
    },
    {
      "item_type_id": "3887"
    },
    {
      "item_type_id": "3889"
    },
    {
      "item_type_id": "3995"
    },
    {
      "item_type_id": "1091"
    },
    {
      "item_type_id": "1410"
    },
    {
      "item_type_id": "1552"
    },
    {
      "item_type_id": "2507"
    },
    {
      "item_type_id": "1323"
    },
    {
      "item_type_id": "4168"
    },
    {
      "item_type_id": "1032"
    },
    {
      "item_type_id": "1237"
    },
    {
      "item_type_id": "1493"
    },
    {
      "item_type_id": "2124"
    },
    {
      "item_type_id": "2468"
    },
    {
      "item_type_id": "969"
    },
    {
      "item_type_id": "1540"
    },
    {
      "item_type_id": "1544"
    },
    {
      "item_type_id": "2112"
    },
    {
      "item_type_id": "2155"
    },
    {
      "item_type_id": "3390"
    },
    {
      "item_type_id": "3391"
    },
    {
      "item_type_id": "3392"
    },
    {
      "item_type_id": "3634"
    },
    {
      "item_type_id": "3637"
    },
    {
      "item_type_id": "3904"
    },
    {
      "item_type_id": "3905"
    },
    {
      "item_type_id": "4135"
    },
    {
      "item_type_id": "4136"
    },
    {
      "item_type_id": "4043"
    },
    {
      "item_type_id": "4212"
    },
    {
      "item_type_id": "4213"
    },
    {
      "item_type_id": "4345"
    },
    {
      "item_type_id": "3523"
    },
    {
      "item_type_id": "3524"
    },
    {
      "item_type_id": "3533"
    },
    {
      "item_type_id": "3715"
    },
    {
      "item_type_id": "4369"
    },
    {
      "item_type_id": "4377"
    },
    {
      "item_type_id": "4338"
    },
    {
      "item_type_id": "4078"
    },
    {
      "item_type_id": "4291"
    },
    {
      "item_type_id": "4197"
    },
    {
      "item_type_id": "3402"
    },
    {
      "item_type_id": "3403"
    },
    {
      "item_type_id": "4093"
    },
    {
      "item_type_id": "4262"
    },
    {
      "item_type_id": "4504"
    },
    {
      "item_type_id": "3318"
    },
    {
      "item_type_id": "3531"
    },
    {
      "item_type_id": "3660"
    },
    {
      "item_type_id": "3661"
    },
    {
      "item_type_id": "3662"
    },
    {
      "item_type_id": "3696"
    },
    {
      "item_type_id": "3931"
    },
    {
      "item_type_id": "4204"
    },
    {
      "item_type_id": "4382"
    },
    {
      "item_type_id": "4604"
    },
    {
      "item_type_id": "4706"
    },
    {
      "item_type_id": "5330"
    },
    {
      "item_type_id": "5416"
    },
    {
      "item_type_id": "5417"
    },
    {
      "item_type_id": "4605"
    },
    {
      "item_type_id": "4607"
    },
    {
      "item_type_id": "1789"
    },
    {
      "item_type_id": "2077"
    },
    {
      "item_type_id": "2158"
    },
    {
      "item_type_id": "2509"
    },
    {
      "item_type_id": "3055"
    },
    {
      "item_type_id": "3455"
    },
    {
      "item_type_id": "5164"
    },
    {
      "item_type_id": "1470"
    }
  ]
}

export default Retired
