export default actionTypes([
  'FETCH_PROFILE',
  'FETCH_PROFILE_DATA_SUCCESS',
  'FETCHED_USER',
  'FETCH_USER_SETS',
  'FETCH_USER_LOCKED_ITEMS',
  'FETCH_USER_ACHIEVEMENTS',
  'FETCH_USER_FRIENDS',
  'FETCH_USER_POUCH',
  'FETCH_USER_BIRTHDAY'
])

function actionTypes(names) {
  return names.reduce((result, name) => {
    result[name] = name
    return result
  }, {})
}
