import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { fetchSingleForum, fetchSingleForumSuccess } from './actions'
import MetaTags from 'react-meta-tags'
import 'whatwg-fetch'

const mapStateToProps = (state) => {
  return {
    forums: state.forums
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleForum: (forumID) => dispatch(fetchSingleForum(forumID)),
    fetchSingleForumSuccess: () => dispatch(fetchSingleForumSuccess())
  }
}

class ForumsMain extends Component {

  renderForumItems() {
    return this.props.forums.forum_list.map( (forumitem, i) => (
      <li key={i} className="panel-block"><article className="media">
        <figure className="media-left">
          {(() => {
            switch (forumitem.id) {
              case 1:     return <p className="title is-3"><span role="img" aria-label="Official Business">📣</span></p>
              case 11:    return <p className="title is-3"><span role="img" aria-label="NewBees Hive">🐝</span></p>
              case 2:     return <p className="title is-3"><span role="img" aria-label="Item Directory">📖</span></p>
              case 3:     return <p className="title is-3"><span role="img" aria-label="The Trading Post">🤝</span></p>
              case 6:     return <p className="title is-3"><span role="img" aria-label="Auction House">🏦</span></p>
              case 4:     return <p className="title is-3"><span role="img" aria-label="WallaBee Chat">💬</span></p>
              case 12:    return <p className="title is-3"><span role="img" aria-label="Party Room">🎉</span></p>
              case 10:    return <p className="title is-3"><span role="img" aria-label="Phoenixes of Jabbaclaw">🔥</span></p>
              case 7:     return <p className="title is-3"><span role="img" aria-label="Unicorns of Slithering">🦄</span></p>
              case 9:     return <p className="title is-3"><span role="img" aria-label="Yetis of Snugglepuff">❄️</span></p>
              case 8:     return <p className="title is-3"><span role="img" aria-label="Dragons of Colbourndor">🐲</span></p>
              case 5:     return <p className="title is-3"><span role="img" aria-label="Bug Reporting">🐛</span></p>
              case 50:     return <p className="title is-3"><span role="img" aria-label="Lemon Drop">💬</span></p>
              case 13:     return <p className="title is-3"><span role="img" aria-label="WallaBoo! Trading">🤝</span></p>
              case 22:    return <p className="title is-3"><span role="img" aria-label="Club Phoenix">🔥</span></p>
              case 21:     return <p className="title is-3"><span role="img" aria-label="Club Unicorn">🦄</span></p>
              case 20:     return <p className="title is-3"><span role="img" aria-label="Club Yeti">❄️</span></p>
              case 23:     return <p className="title is-3"><span role="img" aria-label="Club Dragon">🐲</span></p>
              default:          return null
            }
          })()}
        </figure>
        <div className="media-content">
          <div className="content">
            <p>
              <a href={'/forums/'+ forumitem.id + '/0'} className="thread-title"><strong className="title is-4">{forumitem.title}</strong></a>
              <br/>
              {forumitem.subtitle}
            </p>
          </div>
          {/* <div>
            <em>Latest Thread: </em>
          </div> */}
        </div>
        <div className="media-right forum-chevron">
          <a href={'/forums/'+ forumitem.id + '/0'}><i className="fa fa-chevron-right fa-2x" aria-hidden="false"></i></a>
        </div>
      </article>
      </li>
    ))
  }

  render(){

    if(!localStorage.getItem('token')){
      return <div className="container text-center">

        <img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/472-512.png" style={{'width':'500px'}} alt="You must be logged in to post comments" title="YOU SHALL NOT POST"/>
        <h3>You must be logged in to view the threads and post comments.</h3>
        <Link className="button outdoors" to="/login">Login</Link>
      </div>
    } else {
    return(
      <div className="container" id="forums-main">

        <MetaTags>
          <title>Forums | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
        </MetaTags>

        <ul className="panel">
          <strong className="panel-heading">Forum Topics</strong>

          {this.renderForumItems()}

        </ul>
      </div>
    )
  }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForumsMain))
