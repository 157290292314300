import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink, Link } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import 'whatwg-fetch';

export default class ItemNumberSearch extends React.Component{
  constructor(){
    super()
    this.state = {singleItem:[],userInfo:[],placeInfo:[],mixInfo:[],itemInfo:[]}
    this.fetchItem = this.fetchItem.bind(this)

  }

  fetchItem(targetID,targetNumber){
    const itemurl = endpoints.singleItem+targetID+"/number/"+targetNumber;
    const key = utilities.apiKey;
    var personID = null;
    var placeID = null;
    var mixID = null;


    fetch(itemurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        singleItem: json
      });
      personID = json.user_id;
      placeID = json.place_id;
      mixID = json.used_in_mix;
    })
    .then(json => {
      if (personID>0) {
        const profileurl = endpoints.userProfile+personID;
        fetch(profileurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
        .then(response => response.json())
        .then(jsonPerson => {
          this.setState({
            userInfo: jsonPerson
          });
        });
      }
    })
    .then(json => {
      if (mixID>0) {
        const mixurl = endpoints.itemByID+mixID;
        fetch(mixurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
        .then(response => response.json())
        .then(jsonMix => {
          this.setState({
            mixInfo: jsonMix[mixID]
          });
        });
      }
    })
    .then(json => {
      const iteminfourl = endpoints.singleItem+targetID;
      fetch(iteminfourl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
      .then(response => response.json())
      .then(jsonItem => {
        this.setState({
          itemInfo: jsonItem
        });
      });
    })
    .then(json => {
      if (placeID>0) {
        const placeurl = endpoints.places+placeID;
        fetch(placeurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
        .then(response => response.json())
        .then(jsonPlace => {
          this.setState({
            placeInfo: jsonPlace
          });
        });
      }
    });
  }

  locateItem() {
    var placeID = this.state.singleItem.place_id;
    var personID = this.state.singleItem.user_id;
    var itemStatus = this.state.singleItem.status;
    var mixID = this.state.singleItem.used_in_mix;
    var mixingPool = this.state.singleItem.mixing_pool;
    var itemSaved = this.state.singleItem.saved;
	  var targetURL = null;
    var lockBoxImage= "https://wallazee.global.ssl.fastly.net/images/site/lockbox.png"
    var pouchImage = "https://wallazee.global.ssl.fastly.net/images/site/logo.png"


    if (personID>0) {
      targetURL = "/tools/profile/"+ this.state.userInfo.id+"/bio"
      if (itemSaved===true) {
        return <div>
          <p className="card-header-title">
            Saved by:
          </p>
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <NavLink to={targetURL}><img src={this.state.userInfo.image_url_100} alt={this.state.userInfo.name} /></NavLink>
                </figure>
              </div>
              <div className="media-content">
                <p className="title is-4"><NavLink to={targetURL}>{this.state.userInfo.name}</NavLink></p>
              </div>
            </div>
          </div>
        </div>;
      } else if (placeID === 0) {
          if (itemStatus=="LOCKBOX") {
            return <div><p className="card-header-title">In the LOCKBOX of:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><NavLink to={targetURL}><img src={lockBoxImage} alt={this.state.userInfo.name} /></NavLink></figure></div><div className='media-content'><p className='title is-4'><NavLink to={targetURL}>{this.state.userInfo.name}</NavLink></p></div></div></div></div>;
//            return <div>This item is <b>LOCKED</b> <br/><br/><img src='https://wallazee.global.ssl.fastly.net/images/variant/20171010-6f8d2bd28b0e00ba945a12122a82e849d68f2dc8e3deac288eb45a6-100.png'/><br/>and in the <b>Lock Box</b> of <br/><br/> <NavLink to={targetURL}><img src={this.state.userInfo.image_url_100} alt={this.state.userInfo.name} /><br/><b>{this.state.userInfo.name}</b></NavLink></div>;
          } else {
            return <div><p className="card-header-title">In the POUCH of:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><NavLink to={targetURL}><img src={this.state.userInfo.image_url_100} alt={this.state.userInfo.name} /></NavLink></figure></div><div className="media-content"><p className="title is-4"><NavLink to={targetURL}>{this.state.userInfo.name}</NavLink></p></div></div></div></div>;
//            <div>This item is in the <b>Pouch</b> of <br/><br/> <NavLink to={targetURL}><img src={this.state.userInfo.image_url_100} alt={this.state.userInfo.name} /><br/><b>{this.state.userInfo.name}</b></NavLink></div>;
          }
      }
    }

    if (placeID>0) {
      var targetPlaceURL = "/tools/place/"+ placeID
      if (itemStatus==="LOCKED") {
        targetURL = "/tools/profile/"+ this.state.userInfo.id+"/bio"
          return <div><p className="card-header-title">Is LOCKED by <NavLink to={targetURL}>&nbsp;{this.state.userInfo.name}&nbsp;</NavLink> at:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><NavLink to={targetPlaceURL}><img src={this.state.placeInfo.image_url_100} alt={this.state.placeInfo.name} /></NavLink></figure></div><div className="media-content"><p className="title is-5"><NavLink to={targetPlaceURL}>{this.state.placeInfo.name}</NavLink></p></div></div></div></div>;
          //      return <div>This item is <b>LOCKED</b> and dropped at <br/><br/><NavLink to={targetPlaceURL}><img src={this.state.placeInfo.image_url_100} alt={this.state.placeInfo.name} /><br/><b>{this.state.placeInfo.name}</b></NavLink><br/><br/>This is owned by <br/><br/><NavLink to={targetURL}><img src={this.state.userInfo.image_url_100} alt={this.state.userInfo.name} /><br/><b>{this.state.userInfo.name}</b></NavLink></div> ;
          } else {
            return <div><p className="card-header-title">Is DROPPED at:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><NavLink to={targetPlaceURL}><img src={this.state.placeInfo.image_url_100} alt={this.state.placeInfo.name} /></NavLink></figure></div><div className="media-content"><p className="title is-5"><NavLink to={targetPlaceURL}>{this.state.placeInfo.name}</NavLink></p></div></div></div></div>;
            // return <div>This item is dropped at <br/><br/><NavLink to={targetPlaceURL}><img src={this.state.placeInfo.image_url_100} alt={this.state.placeInfo.name} /><br/><b>{this.state.placeInfo.name}</b></NavLink></div>;
          }
          }

          if (mixID>0) {
            var targetItem="/tools/item/"+this.state.mixInfo.item_type_id+"/num/"+this.state.mixInfo.number;
              return <div><p className="card-header-title">Used to CREATE or ADD to:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><NavLink to={targetItem}><img src={this.state.mixInfo.image_url_100} alt={this.state.userInfo.name} /></NavLink></figure></div><div className="media-content"><p className="title is-5"><NavLink to={targetItem}>{this.state.mixInfo.name} #{this.state.mixInfo.number}</NavLink></p></div></div></div></div>;
              // return <div>This item was used to create <br/><br/> <NavLink key={mixID} to={targetItem}><img src={this.state.mixInfo.image_url_100} alt={this.state.mixInfo.name} /><br/><b>{this.state.mixInfo.name} #{this.state.mixInfo.number}</b></NavLink></div>;
          }

          if (mixingPool) {
            return <div><p className="card-header-title">Can be found in:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><img src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/724-100.png' alt='Mixerpool' /></figure></div><div className="media-content"><p className="title is-5">The Mixerpool</p></div></div></div></div>;
            // return <div>This item is in the <b>Mixing Pool</b><br/><br/><img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/724-100.png" alt="Mixing Pool"/></div>;
          }

          if (itemStatus==="UNIQUEPOOL") {
            return <div><p className="card-header-title">Can be found in:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><img src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/724-100.png' alt='Unique Pool' /></figure></div><div className="media-content"><p className="title is-5">The Unique Pool</p></div></div></div></div>;
            // return <div>This item is in the <b>Mixing Pool</b><br/><br/><img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/724-100.png" alt="Mixing Pool"/></div>;
          }

          if ((itemStatus==="LIMBO")||(itemStatus==="UNIQUE")) {
            return <div><p className="card-header-title">Can be found in:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><img src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/48-100.png' alt='Limbo' /></figure></div><div className="media-content"><p className="title is-5">Limbo</p></div></div></div></div>;
            // return <div>This item is in <b>Limbo</b><br/><br/><img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/48-100.png" alt="Limbo" /></div>;
          }

          if (itemStatus==="RECYCLE") {
            return <div><p className="card-header-title">Can be found in:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><img src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/269-100.png' alt='Recycle Center' /></figure></div><div className="media-content"><p className="title is-5">The Recycle Center</p></div></div></div></div>;
          }

          if ((personID===0)&&(mixID===0)&&(placeID===0)&&(itemStatus==="DEFAULT")&&(mixingPool===false)) {
            return <div><p className="card-header-title">Was used/destroyed/opened:</p><div className="card-content"><div className="media"><div className="media-left"><figure className="image is-48x48"><img src='https://wallazee.global.ssl.fastly.net/images/dynamic/items/39-100.png' alt='Item Destroyed' /></figure></div><div className="media-content"><p className="title is-5">Item Destroyed</p></div></div></div></div>;
        //    return <div>This item was used/destroyed/opened<br/><br/><img src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/39-100.png" alt="Item Destroyed"/></div>;
          }

          return <div>Item has not been created yet!</div>

          }

          renderBackToItem(){
            var targetURL = "/tools/item/" + this.state.itemInfo.item_type_id;
            var targetText = "Back to " + this.state.itemInfo.name;

              var backButton = <span><NavLink className="button outdoors" to={targetURL}>{targetText}</NavLink></span>;
            return backButton;
          }

          renderItemHistory(){
            var targetURLHistory = "/tools/item/history/" + this.state.singleItem.item_id;
            var targetTextHistory = "View History";

              var historyButton = <span><NavLink className="button outdoors" to={targetURLHistory}>{targetTextHistory}</NavLink></span>;
            return historyButton;
          }

          renderNumberBrowse(){
            var currentNumber = this.props.match.params.itemNumber;
            var prevNumber = parseInt(currentNumber,10) - 1;
            var prevItem = "/tools/item/" + this.state.itemInfo.item_type_id +"/num/" + prevNumber
            var nextNumber = parseInt(currentNumber,10) + 1;
            var nextItem = "/tools/item/" + this.state.itemInfo.item_type_id +"/num/" + nextNumber
            var maxNumber = this.state.itemInfo.total_issued;
            var maxItem = "/tools/item/" + this.state.itemInfo.item_type_id +"/num/" + maxNumber
			         var backButton = null;


            if (prevNumber<=0) {
              backButton = <NavLink className="card-footer-item" to={nextItem}>Next</NavLink>;
            } else if (currentNumber === (maxNumber+10)) {
              backButton = <NavLink className="card-footer-item" to={prevItem}>Previous</NavLink>;
            } else if ((currentNumber > (maxNumber+10))) {
              backButton = <NavLink className="card-footer-item" to={maxItem}>Previous</NavLink>;
            } else {
              backButton = <div className="flex" id="btn-wrapper">
                <NavLink  className="browse-btn" to={prevItem}>Previous</NavLink>
                <NavLink  className="browse-btn" to={nextItem}>Next</NavLink>
              </div>;
            }
            return backButton;
          }

          componentWillReceiveProps(nextProps) {
            if ((nextProps.match.params.itemNumber !== this.props.match.params.itemNumber)||(nextProps.match.params.itemId !== this.props.match.params.itemId)) {
              var nextNumber = nextProps.match.params.itemNumber
              var nextItem = nextProps.match.params.itemId
              this.setState({singleItem:[],userInfo:[],placeInfo:[],mixInfo:[],itemInfo:[]});
              this.fetchItem(nextItem,nextNumber);
            }
          }


          componentWillMount() {
            var targetNumber = this.props.match.params.itemNumber
            var itemID = this.props.match.params.itemId
            this.fetchItem(itemID,targetNumber);

          }

          render() {


			var pageTitle = "WallaBee | WallaBee: Collecting and Trading Card Game on iOS";

			if (!this.state.itemInfo.item_type_id) {

				pageTitle="Loading Page... - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
				return <center>
					<MetaTags>
		            <title>{pageTitle}</title>
        			  </MetaTags>
    	    		  <br/><br/><br/><br/><br/><h1>Loading...</h1><br/><br/><br/><br/></center>
			} else {

            pageTitle=this.state.itemInfo.name + " - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
            var altTag = this.state.itemInfo.name;
            var apiImage=this.state.singleItem.image_url_200


			        var errorText=null;

            if ((this.props.match.params.itemNumber > this.state.itemInfo.total_issued )||(this.state.singleItem.number==null)) {
              errorText = "Item has not been created yet!";
              apiImage = "https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+this.state.itemInfo.item_type_id+"-200.png"
            }

              return <div className="container" id="itemNumberSearchPage">

                <MetaTags>
                  <title>{pageTitle}</title>
                </MetaTags>

                <div className="text-center">
                  <div className={"card prestige_"+this.state.singleItem.prestige_color}>
                    <div className="card-image">
                      <figure>
                        <Link to={'/tools/item/'+ this.state.itemInfo.item_type_id}>
                          <img src={apiImage} alt={altTag}/></Link>
                      </figure>
                    </div>
                    <h1>{this.state.itemInfo.name} #{this.props.match.params.itemNumber}</h1>
                    <h3>{this.locateItem()}</h3>
                    {this.renderItemHistory()}
                    <div className="card-footer">
                      {this.renderNumberBrowse()}

                    </div>
                  </div>
                  {this.renderBackToItem()}
                </div>
              </div>
          }
          }
          }
