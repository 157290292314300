import React from 'react';
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import MetaTags from 'react-meta-tags'
import Linkify from 'react-linkify'
import 'whatwg-fetch';

export default class PlayerBio extends React.Component{
  constructor(){
    super()
    this.state = {userInfo:[], foundPlayer: false}
    this.fetchProfile = this.fetchProfile.bind(this)

  }

  fetchProfile(personID){

    const key = utilities.apiKey;
	  const profileurl = endpoints.userProfile+personID;

	fetch(profileurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
		.then(response => response.json())
      	.then(jsonPerson => {
	        if (jsonPerson===null) {
	        	this.setState({
					foundPlayer: true,
					noPlayer: true
		        });
	        } else {
		    	this.setState({
					foundPlayer: true,
					userInfo: jsonPerson
	        	});
	        }
      	});
	}

  componentDidMount() {
    var targetSearch = this.props.match.params.playerID
    this.fetchProfile(targetSearch);
  }

  render() {
    if (!this.state.foundPlayer) {
  		return <div id="profile-bio"><center><br/><br/><br/><br/><br/><h1>Searching...</h1><br/><br/><br/><br/><br/></center></div>
    } else {
		    var bio=0;
        return (
          <div id="profile-bio">
            <MetaTags>
              <title>{this.state.userInfo.name + "'s Profile" + utilities.metaTitle}</title>
            </MetaTags>
            {this.state.userInfo.bio.split("\n").map(i => {
              return <Linkify><div key={bio++}>{i}</div></Linkify>
            })}
          </div>
        )
    }
	}

}
