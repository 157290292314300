import actionTypes from './actionTypes'
import endpoints from '../../util/endpoints'
import utilities from '../../util/utilities'
import  { fetchProfile } from '../profile-page/actions'
import 'whatwg-fetch';

export const updateUsername = (text) => {
  return {
    type: actionTypes.UPDATE_USERNAME,
    payload: text
  }
}

export const updatePassword = (text) => {
  return {
    type: actionTypes.UPDATE_PASSWORD,
    payload: text
  }
}

export const loginSuccess = (res) => {
  return (dispatch) => {

    const userProps = {
      userId: res.user_id,
      token: res.token,
      username: res.username
    }

    dispatch({
      type: actionTypes.LOGIN_SUCCESS,
      payload: userProps
    })
  }

}

export const pushProfile = (userId, token, username) => {
  return (dispatch) => {
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('userId', JSON.stringify(userId))
    localStorage.setItem('username', JSON.stringify(username))

    const userProps = {
      userId: userId,
      token: token,
      username: username
    }
    dispatch({
      type: actionTypes.LOGIN_SUCCESS,
      payload: userProps
    })
    dispatch(fetchProfile(userId, token, username))
  }
}

export const loginUser = (event) => {

  event.preventDefault();

    return(dispatch, getState) => {

    const loginurl = endpoints.loginUser;
    const key = utilities.apiKey;

    const { username, password } = getState().login;

    let loginData = new FormData();

    loginData.append('username', username)
    loginData.append('password', password)

    fetch(loginurl,{method: 'POST', body: loginData, headers: {'X-WallaBee-API-Key': key} })
      .then((res) => {
        return res.json();
      })
      .then((data) => {

        if (data.valid) {

          dispatch(pushProfile(data.user_id, data.token, data.username))

        } else {
          document.getElementById('incorrect-login').style.display = 'block';
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
