import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

export default class Rules extends Component {
  render() {

    return (
      <div className="container" id="rules">
        <MetaTags>
          <title>Rules | WallaBee: Collecting and Trading Card Game on iOS & Android</title>
        </MetaTags>

        <div className="text-center">
          <h1>WallaBee Game Rules</h1>

          <p>While we don't want to get in the way of your enjoyment of the app, there are some general rules and guidelines that need to be followed.<br/>
            <em>We reserve the right to disable certain features of your account for any infraction. Updated: 10/21/2021</em></p>
        </div>
        <ul className="panel">
          <div className="panel-heading"><strong>Player Etiquette</strong></div>
          <li className="panel-block">
            Please conduct yourself in a respectful manner. Breaking Player Etiquette rules will result in being muted and/or having your account suspended.<br/><br/>

            <b>Swearing Publicly / Inappropriate Conversations</b><br/>
            WallaBee is a family game, with players in all age groups. For this reason, we ask that you not swear or use foul language in any public areas of the app that our younger users might have access to. This includes, but is not limited to, forums, comments, your username, bio, profile picture, etc.<br/><br/>

            <b>Harassing / Bullying</b><br/>
            You should not defame, harass, or otherwise bully any players in our game, whether publicly or privately. We have a zero tolerance policy on harassment, and any breaches will lead to immediate banning of your account and your devices.<br/><br/>
            
            <b>Spamming / Trolling In The Forums</b><br/>
            As a family friendly game, no one wants to read petty arguments within the forums. In the end, this is just a game. Anybody getting riled up enough to start a flaming war has no place here. In addition, if you spam the forums, provoke arguments, troll others, or are confrontational, we reserve the right to disable your ability to take part in the forums. This applies whether you start an argument or were provoked into one.<br/><br/>
          
          </li>
        </ul>

        <ul className="panel">

          <div className="panel-heading">
            <strong>Real World Places & Items</strong>  </div>
          <li className="panel-block">When submitting new places in WallaBee, make sure it is a real, publicly accessible place. Other users should be able to physically visit the location in the real world. Therefore, places named "My Kitchen" would not be accepted, as other players may never be able to access your kitchen.<br/><br/>

            Duplicate places are not allowed - they will be deleted or repurposed to other legitimate locations. By researching which locations already exist in your area, you can avoid submitting duplicate places. To find which locations are near you, go to the "Explore" section within WallaBee.<br/><br/>

            Is a place near you in the wrong location? Tap that place's icon and select "Report a Problem." Provide any helpful details for adjusting the location. Our BeeKeepers will handle it as soon as they can!<br/><br/>

            For your security, we ask that you do not create any places such as "My house" or "Josh's place". Houses do not fit within WallaBee's <NavLink to="/places">Place Guidelines</NavLink>. Please keep your new locations within the Categories that already exist<br/><br/>
            </li>
        </ul>

        <ul className="panel">
          <div className="panel-heading"><strong>Faking Your Location</strong></div>
          <li className="panel-block">This is not allowed under any circumstances.
          <br/><br/>WallaBee is a social geolocation game. If you find an item that you want/need, but you are unable to get there in real life, try asking around the community to see if anyone in that area can help you out.
          <br/><br/>By faking your location, you ruin the experience for everyone. Anyone found faking their GPS will lose access to location-based aspects of the game, and ultimately their account.</li>
        </ul>

        <ul className="panel">
          <div className="panel-heading"><strong>Item Ownership</strong></div>
          <li className="panel-block">
          <b>Items at Places</b><br/>
          A big part of WallaBee is trading. Having stashes of items can come in handy when making a deal with other players, but once an unlocked item is dropped at a location, <i>you no longer own it</i>. 
          If you plan on using an item in the future, and don't want other players in your area picking it up, item locks should be used before it is dropped. 
          While many players believe there is etiquette involved when picking items up, there is not. Any dropped item is fair game to be picked up. If you are criticized for doing this, please report the harrassment to <a href="mailto:support@wallabeegame.com">Support</a>.
          <br/><br/>
          <b>Number Collecting</b><br/>
          Some Collectors decide to chase a particular number (or series of numbers) for their collection. These players are referred to as "Number Collectors." Choosing to be a "Number Collector" does not mean they own or have claim to a specific number. Multiple people can collect same number, though it may be difficult.
          <br/><br/>NOTE: Collecting specific numbers may make you vulnerable to price gouging and players deciding not to trade with you.
          <br/><br/>
          <b>Deactivated Accounts</b><br/>
          If your account deactivates from a lack of activity, or you choose to delete your account, all items collected will be removed and released back into the game.
          </li>
        </ul>

        <ul className="panel">
          <div className="panel-heading"><strong>One Account Per User</strong></div>
          <li className="panel-block">Only one account is allowed to be operated per person. Breaking this rule will result in having ALL accounts connected to you disabled, with items removed from all accounts.
          <br/><br/>We want the game to be as fair as possible for everyone. One way we handle this is by only allowing one account per user. The following are examples of illegal gameplay, worthy of being banned:
          <br/>• Running accounts for family members, pets, friends, etc.<br/>• Allowing others to use your account. <br/>• Transferring your account to another person.
          <br/><br/>You are allowed to be logged into your account on multiple devices (eg. phone and iPad), so long as you are the only person using the devices.
          <br/><br/>If you wish to start the game from scratch, you must contact <a href="mailto:support@wallabeegame.com">Support</a> - we will delete your existing account and recycle the items and then allow you to create a new account.
          <br/><br/>If you have any questions about this rule, please contact <a href="mailto:support@wallabeegame.com">Support</a>.</li>
        </ul>

        <ul className="panel"><div className="panel-heading"><strong>
        Real-World Trading / Selling</strong></div>
          <li className="panel-block">While you may trade items and honeycombs with other players within WallaBee, we do not allow the real-world sale or purchase of accounts, items, or any in-app currency.
          <br/><br/>The only exception to this rule is for any charity items WallaBee may add to the Collectibles section (for example: Dryathlon, Able Gamers, WallaBacker) which may be made available only via donation.</li>
        </ul>

        <ul className="panel">
          <div className="panel-heading"><strong>Inactivity Timeout</strong></div>
          <li className="panel-block">WallaBee is a game that is always in motion.<br/><br/>

            New items are released into the game frequently, with rare gaps in releases lasting longer than a few days. Whether you are a devout or casual player, there’s always something new to find and collect every week.<br/><br/>

            Despite all the possibilities, some players decide that WallaBee is no longer for them. While we hate to see players leave the game, we understand that life can get in the way, priorities change, etc.<br/><br/>

            In an effort to keep items in the game, we have an <b>Inactivity Timeout</b>. Starting January 1st, 2022: If an account goes 12 months without completing a set, the account is deactivated. All items owned by the deactivated account are removed from the account and are reintroduced into the game.<br/><br/>
          </li>
        </ul>

        <div className="guidelines">
          <p className="text-center">In addition to our rules, we also have some guidelines to help you get the most out of the game:</p>


          <ul className="panel">
            <div className="panel-heading">Tips & Guidelines</div>
            <li className="panel-block"><span><strong>Foraging while driving</strong> - Traffic laws differ from city, to state, to country... so please obey the law where you live.</span></li>

            <li className="panel-block"><span><strong>Find your friends</strong> - WallaBee can be played as a hermit, but is way more fun with others! There are many benefits to having friends in the game. You can use the "Find Friends" feature to add people you know from your social networks and also to find new friends that are near you.</span></li>

            <li className="panel-block"><span><strong>Add comments to your stories and others</strong> - Who knows what tips and tricks you'll pick up from our more seasoned players?</span></li>

            <li className="panel-block"><span><strong>Help each other</strong> - See someone struggling or missing a few items from a set? Get in touch using messaging and propose a trade.</span></li>

            <li className="panel-block"><span><strong>Follow through with what you say</strong> - We strongly encourage players to follow through with agreed upon trades.</span></li>

            <li className="panel-block"><span><strong>Use our privacy features</strong> - We allow players to hide their location. This helps players stay sociable without other people knowing exactly where they are.</span></li>

            <li className="panel-block"><span><strong>Tell us how we can improve</strong> - Do you have ideas for a set or improvements to the game? Let us know by messaging staff members directly, posting on the forums, or by emailing our <a href="mailto:support@wallabeegame.com">support team</a>.</span></li>

            <li className="panel-block">Above all, be <b>respectful of other players</b> and <b>have fun!</b></li>
          </ul>
        </div>
      </div>
    )
  }
}
