import React from 'react'
import endpoints from '../../../util/endpoints'
import utilities from '../../../util/utilities'
import MetaTags from 'react-meta-tags'
import { Link } from 'react-router-dom'
import {Map, GoogleApiWrapper} from 'google-maps-react'
import Marker from './Google/Component/Marker'

export class Place extends React.Component{
  constructor(){
    super()
    this.state = { place:[],placeItems:[],visitors:[],search:null}
  }

  handleChange(e){
    this.setState({search: e.target.value})
  }

  fetchPlace(targetID){
    const placeurl = endpoints.places+targetID;
    const key = utilities.apiKey;

    fetch(placeurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        place: json
      });
    });
  }

  fetchVisitors(targetID){
    const visitorurl = endpoints.places+targetID+"/people";
    const key = utilities.apiKey;

    fetch(visitorurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        visitors: json.people
      });
    });
  }

  fetchItems(targetID){
    const itemurl = endpoints.places+targetID+"/items";
    const key = utilities.apiKey;

    fetch(itemurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
    .then(response => response.json())
    .then(json => {
      this.setState({
        placeItems: json.items
      });
    });
  }

  componentWillMount() {
    var targetID = this.props.match.params.placeID
    this.setState({ targetID: targetID});
    this.fetchPlace(targetID)
    this.fetchVisitors(targetID)
    this.fetchItems(targetID)
  }

  renderVisitors(){
    var outputHTML = [];
    var tempHeader = null
    var tempURL = null
    var visitors = this.state.visitors
    var peopleCount = this.state.place.people_count
    if (peopleCount>0) {
      visitors.map( visitorData => {
        tempURL = "/tools/profile/" + visitorData.name + "/bio"
        tempHeader = <Link to={tempURL} key={visitorData.id} className="image is-48x48 visitor"><img src={visitorData.image_url_50} alt={visitorData.name}/></Link>;
        return outputHTML.push(tempHeader);
      })
  }
    return <span id='Visitors'>{outputHTML}</span>
  }

  renderItems(){
        var libraries = this.state.placeItems

        return libraries.map( item => (

            <div key={item.id} className="card text-center">
              <div className="card-image">
                <Link to={"/tools/item/" + item.item_type_id }>
                  <img src={item.image_url_100}  alt={item.name}/>
                </Link>
              </div>

              <div className="card-content">
                <div >
                  <h5>#{item.number}</h5>
                  <Link to={"/tools/item/" + item.item_type_id }>
                    <h5>{item.name}</h5>
                  </Link>
                  {(item.status=="LOCKED") && <Link to={"/tools/profile/" + item.username + "/bio"} >
                    <strong><i className="fa fa-lock" aria-hidden="true"></i> <span className="locked-by" >{item.username}</span></strong></Link>}

                </div>
              </div>
            </div>

                  ))}

                  render() {
                    var pageTitle = null

                    if ( (!this.state.place)&&(!this.state.visitors)&&(!this.state.placeItems) ){

                      pageTitle="Loading Page... - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
                        return <div>
                          <MetaTags>
                            <title>{pageTitle}</title>
                          </MetaTags>
                          <div id="loading-container">
                            <img id="starburst" src="https://wallazee.global.ssl.fastly.net/images/site/starburst.png" alt=""/>
                            <h2 className="title is-2">Loading...</h2>
                          </div>
                        </div>

                    } else {

                      pageTitle=this.state.place.name + utilities.metaTitle;

                      var visitors=this.renderVisitors()
                      var latlng = {lat: this.state.place.lat, lng: this.state.place.lng }
                        var thisSpot = <Marker title={this.state.place.name} name={this.state.place.name} position={latlng} icon={this.state.place.image_url_50} />

                      return ( <div id="placepage">

                        <MetaTags>
                          <title>{pageTitle}</title>
                        </MetaTags>

                        {/*Place Header Start*/}

                        <header className="hero hero-header" id="public-profile">
                          <div className="container">
                            <div className="columns">
                              <div className="column is-2">
                                <div className="image is-128x128 text-center margin">
                                  <img src={this.state.place.image_url_200} alt={this.state.place.name} />
                                </div>
                              </div>
                              <div className="column is-4">
                                <h1 className="title">
                                  {this.state.place.name}
                                </h1>

                              </div>

                              <div className="column is-6 flex" id="profile-header-stats">
                                <div className="header-stats has-text-centered">
                                  <figure className="image is-96x96 margin">
                                    <i className="fa fa-circle-o header-icon" aria-hidden="true"></i>
                                  </figure>
                                  <p className="stat-val" id="set-completed">{this.state.place.item_count}/{this.state.place.max_items}</p>
                                  <p className="stat-key">Items</p>
                                </div>
                                <div className="header-stats has-text-centered">
                                  <figure className="image is-96x96 margin">
                                    <img className="image is-96x96 text-center" src="https://wallazee.global.ssl.fastly.net/images/site/friends-icon.svg" alt="Friends"/>
                                  </figure>
                                  <p className="stat-val" id="friend-count">{this.state.place.people_count}</p>
                                  <p className="stat-key">Visitors</p>
                                </div>

                              </div>
                            </div>
                          </div>
                        </header>

                        {/*Place Header End*/}

                        <section className="container" id="item-info">

                          <div className="tile is-ancestor text-center">
                            <div className="tile is-vertical is-12">
                              <div className="tile">
                                <div className="tile is-parent is-vertical" id="StoreInfo">
                                  <article className="tile is-child box" style={{background:'white'}}>
                                    <p className="title">Location: </p>
                                    <div className="content">

                                      <Map google={this.props.google}
                                        center={latlng}
                                        containerStyle={{width: '100%', height: '300px', position: 'relative'}}
                                        zoom={15}>
                                        {thisSpot}
                                      </Map>

                                    </div>
                                  </article>
                                </div>

                                <div className="tile is-parent" id="SetInfo">
                                  <article className="tile is-child box" style={{background:'white'}}>
                                    <p className="title">Visitor List: </p>
                                    <div className="content">{visitors}</div>
                                  </article>
                                </div>

                              </div>
                            </div>
                          </div>
                          {/* <div className="text-center">  <input className="banner-search input is-medium" type="text" placeholder="Search Items..." onChange={this.handleChange}/></div>
                          */}
                          <div className="flex">

                {this.renderItems()}

              </div>
        </section>

      </div>

    )
  }
}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyB_S37u-kciQhrnx5J2Xzdq0QN2sWebvCY',
  version: '3.27'
})(Place);
