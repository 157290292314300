import React from 'react'
import { connect } from 'react-redux'
import { fetchProfile } from './actions'
import { pushProfile } from '../login-page/actions'
import { NavLink, withRouter, Route, Switch } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import UserSets from './components/user-sets'
import UserAchievements from './components/user-achievements'
import UserFriends from './components/user-friends'
import UniqueGifting from './components/unique-gifting'
import ExportLockList from './components/locklist-export'
import UserPouch from './components/user-pouch'
import UserSetDetail from './components/set-detail/user-setdetail'
import UserRedeemCode from './components/RedeemCode'
import Inbox from './components/messages/Inbox'
import BirthdayCakes from './components/user-cakes'

const mapStateToProps = (state) => {
  return {
    login: state.login,
    profile: state.profile,
    birthday: state.birthday
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProfile: () => dispatch(fetchProfile()),
    pushProfile: (userId, token, username) => dispatch(pushProfile(userId, token, username))
  }
}

class Profile extends React.Component {
  constructor(){
    super()
    this.numberWithCommas = this.numberWithCommas.bind(this)
  }

  numberWithCommas(x){
    if(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }else{
      return '0'
    }
  }

  componentWillMount(){
    const token = JSON.parse(localStorage.getItem('token'))
    const userId = JSON.parse(localStorage.getItem('userId'))
    const username = JSON.parse(localStorage.getItem('username'))

    if (token && userId) {
      this.props.pushProfile(userId, token, username)
    }
      else
    {
      this.props.history.push('/login');
    }
  }

  render() {

		var userSetsUrl = "/u/" + this.props.profile.name + "/sets"
		var userAchievementsUrl = "/u/" + this.props.profile.name + "/achievements"
		var userGiftUrl = "/u/" + this.props.profile.name + "/giftunique"
		var userExportUrl = "/u/" + this.props.profile.name + "/export"
    var userFriendsUrl = "/u/" + this.props.profile.name + "/friends"
    var userInboxUrl = '/u/' + this.props.profile.name + '/inbox'
//    var userLockedItemsUrl = "/u/" + this.props.profile.name + "/lockeditems"
    var userPouchUrl = "/u/" + this.props.profile.name + "/pouch"
    var userBirthdayUrl = "/u/" + this.props.profile.name + "/birthday"
    var userRedeemUrl = "/u/" + this.props.profile.name + "/redeem"

    var pageTitle= this.props.profile.name + " | WallaBee: Collecting and Trading Card Game on iOS & Android";

	  var createdat = new Date(this.props.profile.created_at).toUTCString();

    var birthday = this.props.profile.birthday;



        return ( <div id="profile">
          <MetaTags>
            <title>{pageTitle}</title>
          </MetaTags>

          {/*START OF PROFILE*/}
          <div>

            <header className="section hero is-primary is-bold profile-heading">
              <div className="container">
                <div >
                  <div className="columns">
                    <div className="column is-2 text-center">
                      <div className="image is-128x128">
                        <img className="border-radius" src={this.props.profile.image_url_200} id="round-profile" alt={this.props.profile.username}/>
                      </div>
                    </div>
                    <div className="column is-4 name">
                      <p>
                        <span className="title is-bold">{this.props.profile.name}</span>

                      </p>
                      <p className="tag is-info">Member Since: {createdat}</p><br/>
                        <span id="birthday">
                            {(() => {
                              switch (this.props.profile.birthday) {
                                case "Date Missing":     return [ <span key='gobday'><span className='tag is-danger'>Birthday: Birthday Not Set</span><span className='tag is-warning'><NavLink className="link" to={userBirthdayUrl}>Set Birthday</NavLink></span></span> ]
                                default:                 return <span className='tag is-success'>Birthday: {this.props.profile.birthday}</span>;
                              }
                            })()}
                        </span>
                        <br/>


                      <div id="acct-type">
                        {(() => {
                          switch (this.props.profile.account_type) {
                            case "staff":     return <span className="tag is-warning">Staff</span>;
                            case "beekeeper": return <span className="tag is-success">BeeKeeper</span>;
                            case "player":    return <span className="tag is-info">Player</span>;
                            default:          return null;
                          }
                        })()}
                      </div>
                    </div>

                    <div className="column is-6 flex" id="profile-header-stats">
                      <div className="honeycomb-section has-text-centered">
                        <a href="https://store.munzee.com/collections/wallabee/products/honeycombs" target="_blank" rel="noopener noreferrer">
                          <figure className="text-center image is-96x96">

                            <img className="image is-96x96 text-center" src="https://wallazee.global.ssl.fastly.net/images/site/honeycomb-white-3.svg" alt="Buy HoneyCombs!s"/>
                          </figure>
                          <p className="stat-val">{this.numberWithCommas(this.props.profile.balance)}</p>
                          <p className="stat-key">HoneyCombs</p></a>
                      </div>
                      <div className="friends-section has-text-centered">
                        <NavLink className="link" to={userFriendsUrl}>
                          <figure className="image is-96x96">
                            <img className="image is-96x96 text-center" src="https://wallazee.global.ssl.fastly.net/images/site/friends-icon.svg" alt="Your Friends"/>
                          </figure>
                          <p className="stat-val">{this.numberWithCommas(this.props.profile.friends_count)}</p>
                          <p className="stat-key">Friends</p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="profile-options">
                  <div className="tabs is-centered is-boxed">
                    <ul>
                      <li>
                        <NavLink className="link" to={userPouchUrl} ><span className="icon">
                          <i className="fa fa-suitcase" aria-hidden="true"></i></span>
                          <span>Pouch</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="link" to={userSetsUrl} ><span className="icon">
                          <i className="fa fa-circle-o" aria-hidden="true"></i></span>
                          <span>Sets</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="link" to={userAchievementsUrl} ><span className="icon">
                          <i className="fa fa-trophy" aria-hidden="true"></i></span>
                          <span>Achievements</span>
                        </NavLink>
                      </li>

                      {
                        (() => {
                          if (this.props.profile.unread_conversations) {
                            return <li>
                              <NavLink className="link" to={userInboxUrl}><span className="icon badge is-badge-danger is-badge-small" data-badge="">
                                <i className="fa fa-envelope" aria-hidden="true"></i></span>
                                <span>Inbox</span>
                              </NavLink>
                            </li>
                          } else {
                            return <li>
                              <NavLink className="link" to={userInboxUrl}><span className="icon">
                                <i className="fa fa-envelope" aria-hidden="true"></i></span>
                                <span>Inbox</span>
                              </NavLink>
                            </li>
                          }
                        })()
                      }

                      <li>
                        <NavLink className="link" to={userFriendsUrl} ><span className="icon">
                          <i className="fa fa-users" aria-hidden="true"></i></span>
                          <span>Friends</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="link" to={userGiftUrl} ><span className="icon">
                          <i className="fa fa-star" aria-hidden="true"></i></span>
                          <span>Gift Unique</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="link" to={userExportUrl} ><span className="icon">
                          <i className="fa fa-lock" aria-hidden="true"></i></span>
                          <span>Locked Items</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="link" to={userRedeemUrl} ><span className="icon">
                          <i className="fa fa-star" aria-hidden="true"></i></span>
                          <span>Redeem</span>
                        </NavLink>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            </header>

            <div id="profile-info-container">

              <Switch>
              <Route path={'/u/:username/pouch'} component={UserPouch} />
              <Route path={'/u/:username/birthday'} component={BirthdayCakes} />
                <Route exact path={'/u/:username/sets'} component={UserSets} />
                <Route path={'/u/:username/achievements'} component={UserAchievements}/>
                <Route path={'/u/:username/inbox'} component={Inbox}/>
                <Route path={'/u/:username/friends'} component={UserFriends}/>
                <Route path={'/u/:username/giftunique'} component={UniqueGifting}/>
                <Route path={'/u/:username/export'} component={ExportLockList}/>
                <Route path={'/u/:username/sets/:id'} component={UserSetDetail} />
                <Route path={'/u/:username/redeem'} component={UserRedeemCode} />
                <Route component={UserPouch} />
              </Switch>

            </div>

            {/*END OF PROFILE*/}

          </div>

        </div>
      )
    }
  }

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
