import React from 'react';
import { Link } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import MetaTags from 'react-meta-tags'
import Linkify from 'react-linkify'
import RetiredTable from '../../../util/retired'
import 'whatwg-fetch';

export default class PlayerUniques extends React.Component{
  constructor(){
    super()
    this.state = {userInfo:[], foundPlayer: false}
    this.fetchProfile = this.fetchProfile.bind(this)

  }

  fetchProfile(personID){

    const key = utilities.apiKey;
	  const profileurl = endpoints.userProfile+personID;

	fetch(profileurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
		.then(response => response.json())
      	.then(jsonPerson => {
	        if (jsonPerson===null) {
	        	this.setState({
					foundPlayer: true,
					noPlayer: true
		        });
	        } else {
		    	this.setState({
					foundPlayer: true,
					userInfo: jsonPerson
	        	});
	        }
      	});
	}

  fetchRetired(){
    var tempArray = []

    RetiredTable.Retired.map( item => {
        tempArray.push(item.item_type_id)
    })

    if (tempArray.length != 0) {
      this.setState({Retired: tempArray})
    }

  }

  componentDidMount() {
    var targetSearch = this.props.match.params.playerID
    this.fetchProfile(targetSearch);
    this.fetchRetired()
  }

  render() {
    if (!this.state.foundPlayer) {
  		return <div id="profile-uniques"><center><br/><br/><br/><br/><br/><h1>Searching...</h1><br/><br/><br/><br/><br/></center></div>
    } else {
		    var unique=0;
        if (this.state.userInfo.uniques_owned.length>0) {
        var RetiredItems = this.state.Retired;
        return (
          <div id="profile-uniques">
            <MetaTags>
              <title>{this.state.userInfo.name + "'s Uniques Distributed" + utilities.metaTitle}</title>
            </MetaTags>
            <div className="flex" id="wishlist">
            {this.state.userInfo.uniques_owned.map(i => {
              var retiredFlag = 0;

              RetiredItems.map( item_type_id =>{
                if (item_type_id==i) {
                  retiredFlag = 1
                }
              })

              var apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+i+"-100.png";
              if (retiredFlag == 1) {
                var displayImage = <div className="card-image">
                      <div className="itemSetDisplay">
                      <Link to={"/tools/item/" + i }>
                        <img key={i} src={apiImage} />
                        <img src="https://wallazee.global.ssl.fastly.net/images/site/retired-overlay.png" className="retired" />
                      </Link>
                      </div>
                      </div>
              } else {
                var displayImage = <div className="card-image">
                      <div className="itemSetDisplay">
                      <Link to={"/tools/item/" + i }>
                      <img key={i} src={apiImage} />
                      </Link>
                      </div>
                      </div>
              }
              return <div key={unique++} className="card">
                        {displayImage}
                        <div className="card-content">
                          <div className="achievement-info">
                            <h5>&nbsp;</h5>
                          </div>
                        </div>
                      </div>

            })}
            </div>
          </div>
        )

      } else {
        return <div id="profile-uniques"><center><br/><br/><br/><br/><br/><h1>No Uniques Owned</h1><br/><br/><br/><br/><br/></center></div>
      }
    }
	}

}
