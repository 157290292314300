export default actionTypes([
  'FETCH_FORUM_TOPICS_SUCCESS',
  'FETCH_SINGLE_FORUM_SUCCESS',
  'FETCH_SINGLE_THREAD_SUCCESS',
  'FETCH_USER_DATA',
  'UPDATE_COMMENT',
  'POST_COMMENT_SUCCESS',
  'POST_REPLY_SUCCESS',
  'CLEAR_COMMENT_STATE'
])

function actionTypes(names) {
  return names.reduce((result, name) => {
    result[name] = name
    return result
  }, {})
}
