import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import Promise from 'promise-polyfill'

import thunk from 'redux-thunk'
//import logger from 'redux-logger'

import login from './pages/login-page/reducers'
import profile from './pages/profile-page/reducers'
import sets from './pages/profile-page/components/set-detail/reducers'
import forums from './pages/forums/reducers'

unregister();

// To add to window
if (!window.Promise) {
  window.Promise = Promise;
}

const middleware = () => {
  return applyMiddleware(thunk)
}

export const store = createStore(
  combineReducers({
    login: login,
    profile: profile,
    sets: sets,
    forums: forums
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  middleware()
)

render((
  <Provider store={store}>
    <App/>
  </Provider>
),document.getElementById('root'))
