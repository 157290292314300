import React from 'react'
import { connect } from 'react-redux'
import { fetchUserFriendsSuccess } from '../actions'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      fetchUserFriendsSuccess: () => dispatch(fetchUserFriendsSuccess()),
  }
}

class ProfileFriends extends React.Component{
  constructor(){
    super()
    this.state = { search: '' }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e){
    this.setState({search: e.target.value})
  }

  renderUserFriends () {

    var libraries = this.props.profile.friends,
           search = this.state.search.trim().toLowerCase();

       if(search.length > 0){

           libraries = libraries.filter(function(l){
               return l.name.toLowerCase().match( search );
           });
       }

	var friendCount = 0;

    return libraries.map( friend => (

        <div key={friendCount++} className="card">
          <div className="card-image">
            <Link to={"/tools/profile/" + friend.id + '/bio'}>
              <img src={friend.image_url_200}  alt={friend.name}/>
            </Link>
          </div>

          <div className="card-content">

            <div className="achievement-info">
              <Link to={"/tools/profile/" + friend.id + '/bio'}>
                <h5>{friend.name}</h5>
              </Link>
              <h5>{friend.home_location}</h5>
            </div>
          </div>
        </div>

    ))}

  render() {

    var pageTitle=this.props.profile.name + "'s Friends - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";

    return (
      <div>
        <div className="text-center">
          <input className="banner-search input is-medium" type="text" placeholder="Search Your Friends..." onChange={this.handleChange}/>
        </div>
        <div className="flex" id="friends">
          <MetaTags>
            <title>{pageTitle}</title>
          </MetaTags>

          {this.renderUserFriends()}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileFriends)
