import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import endpoints from '../../../../util/endpoints'
import utilities from '../../../../util/utilities'
import TimeAgo from 'react-timeago'
import { connect } from 'react-redux'
import Linkify from 'react-linkify'
import 'whatwg-fetch'

import VirtualizedSelect from 'react-virtualized-select'
import 'react-select/dist/react-select.css'
import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'

const mapStateToProps = (state) => {
  return {
    profile: state.profile
  }
}

const Tab = (props) => {

    return <li className={ props.isActive ? 'panel-block navigation--active': 'panel-block' }
      onClick={() => props.onActiveTab(props.user_id, props.avatar, props.name) } >

      <div >
        <div className="media">
          <figure className="media-left">
            <p className="image is-32x32">
              <img src={props.avatar} />
            </p>
          </figure>
          <div className="media-content">
            <div className="content">
              <p>
                <strong>{props.name}</strong> <small><TimeAgo date={props.created * 1000} /></small>
                <br/>
                <span className="msg-preview">{props.text}</span>
              </p>
            </div>
          </div>
          <div className="media-right">
            {
              (() => {if(props.unread){
                return <span className="tag is-red">{props.unread}</span>
              }})()
            }
          </div>
        </div>
      </div>

    </li>
}

class Inbox extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      messages:[],
      conversation:[],
      user_avatar:'',
      username:'',
      message: '',
      userID: null
    }
  }

  updateMessage = (e) => {
    this.setState({
      message: e.target.value
    })
  }

  isActive(id) {
    return this.state.selectedTabId === id;
  }

  setActiveTab = (selectedTabId, avatar, username) => {
    this.setState({ selectedTabId })
    this.markRead(selectedTabId, avatar, username)
  }

  handleSelect = (selectValue) => {

    if(selectValue){
      this.setState({
        userID: selectValue.id,
        selectedTabId: selectValue.id
      })

      this.fetchConversation(selectValue.id,selectValue.image_url_100, selectValue.name)
      this.fetchMessageList()

    } else {
      this.setState({
        selectValue: null,
        userID: null,
        username: '',
        user_avatar:'',
        conversation:[]
      })
    }
  }

  selectFriend = () => {
    return (
      <VirtualizedSelect
        options={this.props.profile.friends}
        onChange={this.handleSelect}
        value={this.state.selectValue}
        labelKey='name'
        valueKey='id'
        placeholder="search friends..."

      />
    )
  }

  fetchMessageList = () => {

    const inboxurl = endpoints.inbox + 'conversations'
    const key = utilities.apiKey;
    const token = JSON.parse(localStorage.getItem('token'))

    fetch(inboxurl,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token } })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.setState({
          messages: data
        });

      })
      .catch((e) => {
        console.log(e);
      });

  }

  getInbox = () => {
    const inboxurl = endpoints.inbox + 'conversations'
    const key = utilities.apiKey
    const token = JSON.parse(localStorage.getItem('token'))

    return fetch(inboxurl,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token } })
            .then((res) => {
            return res.json();
          })
          .then((data) => {
            return new Promise((resolve,reject) => {
              this.setState({
                messages: data,
                userID: data[0].user.id,
                user_avatar: data[0].user.image_url_200,
                username: data[0].user.name,
                selectedTabId: data[0].user.id
              }, () => resolve() );
            })
          })
          .catch((e) => {
            console.log(e);
          });
  }

  onLoadMessages = () => {

      this.getInbox().then(() => {

        const convoUrl = endpoints.inbox + 'conversation/' + this.state.userID
        const key = utilities.apiKey
        const token = JSON.parse(localStorage.getItem('token'))

        fetch(convoUrl,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token } })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            this.setState({
              conversation: data.conversation.reverse()
            });
          })
          .catch((e) => {
            console.log(e);
          })
      })
  }

  fetchConversation = (userID, user_avatar, username) => {

    const convoUrl = endpoints.inbox + 'conversation/' + userID
    const key = utilities.apiKey;
    const token = JSON.parse(localStorage.getItem('token'))

    fetch(convoUrl,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token } })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return new Promise((resolve, reject) => {
          this.setState({
            conversation: data.conversation.reverse(),
            user_avatar: user_avatar,
            username: username,
            userID: userID
          }, () => resolve() )
        })
      })
      .catch((e) => {
        console.log(e);
      })
  }

  markRead = (userID, user_avatar, username) => {

    const readUrl = endpoints.inbox + 'conversation/read/' + userID
    const token = JSON.parse(localStorage.getItem('token'))
    const key = utilities.apiKey

    fetch(readUrl,{method:'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token } })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.fetchConversation(data.recipient_id, user_avatar, username)
        this.fetchMessageList()
      })
      .catch((e) => {
        console.log(e);
      })

  }

  postMessage = (e) => {
    e.nativeEvent.preventDefault()

    const { message, userID, user_avatar, username  } = this.state
    const convoUrl = endpoints.inbox + 'conversation/' + userID
    const token = JSON.parse(localStorage.getItem('token'))
    const key = utilities.apiKey

    if (message != "") {

    let messageBody = new FormData();

    messageBody.append('text', message)

    fetch(convoUrl,{method: 'POST', body: messageBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
          .then((res) => {
            return res.json();
          })
          .then((data) => {

            if(data.success){

              this.fetchConversation(data.recipient_id, user_avatar, username)
              this.fetchMessageList()
              this.setState({
                message: ''
              })

            } else {
              alert('error!')
            }

          })
          .catch((e) => {
            console.log(e);
          });
        }

    }

  refreshMessages = () => {
    const { message, userID, user_avatar, username  } = this.state
    this.fetchMessageList()
    this.fetchConversation(userID, user_avatar, username)
  }


  renderMessages(){

    let avatar = this.props.user_avatar
    let user_id = this.props.user_id
    let name = this.props.name
    let created = this.props.created
    let text = this.props.text
    let unread = this.props.unread

    return this.state.messages.map( (message,i) => {
      return <Tab key={i} isActive={ this.isActive(message.user.id)  }
        onActiveTab={ this.setActiveTab.bind(this) } avatar={message.user.image_url_200} user_id={message.user.id} name={message.user.name} created={message.created_at} text={message.text} unread={message.unread}/>

        })
        }

        renderConversation(){

          let sender = JSON.parse(localStorage.getItem('userId'))

          return this.state.conversation.map( (conv,i) => (

            <div key={i}>
              {
                (() => {if(conv.sender_id === sender){
                  return <article className="media sent-msg">
                    <div className="media-content">
                      <div className="content">


                        {
                          (() => {if(conv.text){
                            return <Linkify><p>{conv.text.split('\n').map((item,i) => {
                              return <span key={i}>{item}<br/></span>
                            })}</p></Linkify>
                          }})()
                        }


                        <div style={{'textAlign':'right'}}>  <small><TimeAgo date={conv.created_at * 1000} /></small></div>
                      </div>
                    </div>
                  </article>
                } else {
                  return <article className="media rcvd-msg">
                    <figure className="media-left">
                      <p className="image is-32x32">
                        <Link to={'/tools/profile/' + this.state.username + '/bio'}><img src={this.state.user_avatar}/></Link>
                      </p>
                    </figure>
                    <div className="media-content">
                      <div className="content">

                        {
                          (() => {if(conv.text){
                            return <Linkify><p>{conv.text.split('\n').map((item,i) => {
                              return <span key={i}>{item}<br/></span>
                            })}</p></Linkify>
                          }})()
                        }

                        <div>  <small><TimeAgo date={conv.created_at * 1000} /></small></div>
                      </div>
                    </div>
                  </article>
                }})()
              }
            </div>
          ))
        }

        componentWillMount(){
          this.onLoadMessages()
        }

        componentDidUpdate(){
          var messageScroll = document.getElementById('message-container')
          messageScroll.scrollTop = messageScroll.scrollHeight
        }

        render() {

          return (
            <div id="inbox">

              <div className="container" id="inbox-container">
                <div className="columns is-gapless">
                  <div className="column is-one-third">
                    <nav className="panel" id="contact-list">
                      <p className="panel-heading" id="contacts-header">
                        Messages
                        <button id="btn-refresh" onClick={this.refreshMessages}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                      </p>

                      {this.selectFriend()}

                      <div id="contacts">
                        {this.renderMessages()}
                      </div>

                    </nav>

                  </div>

                  <div className="column">
                    <div className="panel-heading" id="message-user">{this.state.username}</div>

                    <div id="message-container">
                      {this.renderConversation()}
                    </div>

                    <article className="media">

                      <div className="media-content">
                        <form onSubmit={this.postMessage}>
                          <div className="field">
                            <div className="control" id="message-box">
                              <textarea className="textarea" placeholder="Write your message..." onChange={this.updateMessage} value={this.state.message}></textarea>
                            </div>
                          </div>
                          <div className="field">
                            <p className="control" style={{'textAlign':'right'}}>
                              <button className="button is-info send-btn" type="submit">Send</button>
                            </p>
                          </div>
                        </form>
                      </div>
                    </article>

                  </div>

                </div>

              </div>
            </div>

    )
  }
}

export default connect(mapStateToProps)(Inbox)
