import React from 'react'
import Panel from '../../../components/panel'

  var placeArray = [
  {
      'name': 'AIPORT/HELIPAD',
      'desc': ['Airport, including each terminal','Significant helipad','NOT: Airport gates, ticket counters, luggage claims, airplanes, etc.'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/66-51e54306-7b8c-4d4f-85fc-319cc63dd4aa-50.png',
      'id': 1
  },
  {
      'name':'BOATING',
      'desc': ['Ferry port or dock','Marinas, Dock, Wharfs','NOT: cruise ships, ferries themselves'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/67-51e54324-582c-4c20-be55-30ebc63dd4aa-50.png',
      'id': 2
  },
  {
      'name':'BUS STATION',
      'desc': ['Bus station or significant terminus','NOT: bus stops'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/68-51e54336-9f50-45be-9eeb-2df6c63dd4aa-50.png',
      'id': 3
  },
  {
      'name':'CAMPING',
      'desc': ['Campgrounds (e.g., KOA)','Vacation RV Parks'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160807-a160d88cc42c823b74cacfceb3b72e0b757db831975081cc621225e-50.png',
      'id': 4
  },
  {
      'name':'HOTEL',
      'desc': ['Hotels/Hostels','Resorts','Bed and Breakfasts'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160808-c8c1428612a22480bab48e6132e54d239c2965c7938e21aff1a17eb-50.png',
      'id': 5
  },
  {
      'name':'OTHER',
      'desc': ['Auto rental (e.g., Avis, Enterprise, etc.)','Truck rental (e.g. U-Haul, Hertz, etc.)','Standalone parking structures or large Park & Ride lots','Significant bicycle share (e.g., Bixi) stands','Significant highway rest stops','NOT: towns, cities, states, border crossings, etc.; toll booths; freeways or roads; valet stands'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/71-51e54376-60f0-4519-b208-3339c63dd4aa-50.png',
      'id': 6
  },
  {
      'name':'RAILWAY',
      'desc': ['Rail station','Tram/trolley/street car station or terminus','NOT: Insignificant tram/trolley/street car stops (apply same rules as buses)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/69-51e54343-2644-4527-ad72-3399c63dd4aa-50.png',
      'id': 7
  },
  {
      'name':'SERVICE STATION',
      'desc': ['Fuel stations','Vehicle mechanics','NOT: Car wash (service/other), vehicle rental (travel/other), vehicle dealerships (shopping/other), car part stores (shopping/other), insignificant tram/trolley/street car stops (apply same rules as buses)'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/45-51e53b68-8b08-432b-a9a9-1f04c63dd4aa-50.png',
      'id': 8
  },
  {
      'name':'UNDERGROUND',
      'desc': ['Subway/tube stations'],
      'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/70-51e54357-3190-4f94-9cea-2b4cc63dd4aa-50.png',
      'id': 9
  }
]

export default class PanelList extends React.Component {
  render(){
    const places = placeArray.map((place,i) => {
      return <Panel key={i} {...place}/>
    })

    return (
      <div>
        <div className="text-center">

          <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/65-51e542f2-59ac-4763-a17a-3124c63dd4aa-200.png" alt="Travel Places"/>
          <h2>Travel</h2>
        </div>
        {places}

</div>
    )
  }
}
