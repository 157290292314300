import actionTypes from './actionTypes';
import endpoints from '../../util/endpoints';
import utilities from '../../util/utilities';
import 'whatwg-fetch';

export const fetchProfileDataSuccess = (data) => {
  return{
      type: actionTypes.FETCH_PROFILE_DATA_SUCCESS,
      payload: data
    }
}

export const fetchSetCompletion = (res) => {
  return{
    type: actionTypes.FETCH_USER_SETS,
    payload: res
  }
}

export const fetchUserAchievementsSuccess = (res) => {
 return {
   type: actionTypes.FETCH_USER_ACHIEVEMENTS,
   payload: res
 }
}

export const fetchUserFriendsSuccess = (res) => {
  return {
    type: actionTypes.FETCH_USER_FRIENDS,
    payload: res
  }
}

//export const fetchUserLockedItemsSuccess = (res) => {
//  return {
//    type: actionTypes.FETCH_USER_LOCKED_ITEMS,
//    payload: res
//  }
//}

export const fetchUserPouchSuccess = (res) => {
  return {
    type: actionTypes.FETCH_USER_POUCH,
    payload: res
  }
}

export const fetchUserBirthdaySuccess = (res) => {
  return {
    type: actionTypes.FETCH_USER_BIRTHDAY,
    payload: res
  }
}

export const fetchProfile = (userId, token) => {
  return (dispatch, getState) => {

    const userurl = endpoints.userStats + userId
    const key = utilities.apiKey
    const userSetsUrl = userurl + '/sets/'
    const userAchievementsUrl = userurl + '/achievements/new'
    const userFriendsUrl = userurl + '/friends/'
    const userLockedUrl = userurl + '/lockeditems/'
    const userPouchUrl = userurl + '/pouch/'
    const userBirthdayUrl = userurl + '/chkbday'


    fetch(userurl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        dispatch(fetchProfileDataSuccess(data))
      })
      .catch((e) => {
        console.log(e)
      })

    fetch(userSetsUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
      .then((data) => {
        return data.json();
      })
      .then((data) => {

        dispatch(fetchSetCompletion(data))
      })
      .catch((e) => {
        console.log(e)
      })

     fetch(userAchievementsUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
       .then((data) => {
         return data.json();

       })
       .then((data) => {

         dispatch(fetchUserAchievementsSuccess(data))
       })
       .catch((e) => {
         console.log(e)
       })

      fetch(userFriendsUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
          .then((data) => {
            return data.json();
          })
          .then((data) => {

            dispatch(fetchUserFriendsSuccess(data))
          })
          .catch((e) => {
            console.log(e)
          })

      fetch(userPouchUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            dispatch(fetchUserPouchSuccess(data))
          })
          .catch((e) => {
            console.log(e)
          })

      fetch(userBirthdayUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            dispatch(fetchUserBirthdaySuccess(data))
          })
          .catch((e) => {
            console.log(e)
          })

//      fetch(userLockedUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
//          .then((data) => {
//            return data.json();
//          })
//          .then((data) => {
//            dispatch(fetchUserLockedItemsSuccess(data))
//          })
//          .catch((e) => {
//            console.log(e)
//          })

      }
}
