import React, { Component } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { fetchForums, fetchForumTopicsSuccess } from './actions'
import { connect } from 'react-redux'
import ForumsMain from './forums-main'
import ForumSingle from './forums-single'
import Thread from './thread-single'
import 'whatwg-fetch'

const mapStateToProps = (state) => {
  return {
    login: state.login,
    profile: state.profile,
    forums: state.forums
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchForums: () => dispatch(fetchForums()),
    fetchForumTopicsSuccess: () => dispatch(fetchForumTopicsSuccess())
  }
}

class Forums extends Component {
  componentWillMount(){
    this.props.fetchForums()
  }

  render(){
    return(
      <div id="forums">

        <header className="hero is-dark is-bold">
          <section className="container">
            <div id="itembee-header" className="forums-header">

              <div id="itembee-logo">
                <img width="93px" src="https://wallazee.global.ssl.fastly.net/images/dynamic/items/1474-200.png" alt="WallaBee Forums" />
              </div>

              <div id="itembee-desc">
                <h1 className="title">WallaBee Forums</h1>
                <h2>
                  Welcome to the forums... please play nice! :)
                </h2>

                <Link className="button outdoors" to="/forums" style={{'marginLeft':'0'}}>Forums Home</Link>

              </div>
            </div>

          </section>
        </header>

        <section id="forums-container">
          <Switch>
            <Route exact path='/forums' component={ForumsMain} />
            <Route exact path='/forums/:forumid/:page' component={ForumSingle} />
            <Route exact path='/forums/:forumid/:page/:threadID/:threadPage' component={Thread} />
          </Switch>
        </section>

      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Forums)
