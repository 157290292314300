import React from 'react'
import MetaTags from 'react-meta-tags'

export default class ProfileSearch extends React.Component{
  constructor(){
    super()
    this.state = {targetName:[], searchForProfile: false}

  }

  searchProfile(){
	  	var nameSearch=this.state.targetName;
  		if (nameSearch != null) {
	  		var targetURL = '/tools/profile/'+nameSearch+'/bio';
  			this.props.history.push(targetURL);
  		}
  }

  render() {


    return <div className="container" id="searchProfile">

      <MetaTags>
        <title>Profile Search | WallaBee: Collecting and Trading Card Game on iOS</title>
      </MetaTags>

      <div className="text-center">
        <form id="searchPlayer">
          <input type="text" className="banner-search input is-medium" placeholder="Search for Profile..." onChange={(e) => this.setState({ targetName: e.target.value})}/><br/>
          <button className="button outdoors" id="nameSearch" onClick={this.searchProfile.bind(this)}>Search</button>
        </form>
      </div>
    </div>
  }
}
