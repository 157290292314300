import React, { Component } from 'react'
import { SideNav, MenuIcon } from 'react-simple-sidenav'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  return {
    login: state.login,
    profile: state.profile
  }
}

class SideBar extends Component {
  constructor(){
    super()
    this.state = {
      showNav: false
    }
  }


  logoutButton(){
    localStorage.clear()
  }


  render(){

    const token = JSON.parse(localStorage.getItem('token'))


    var profileName = JSON.parse(localStorage.getItem('username'))

    var userGiftUrl = "/u/" + profileName + "/giftunique"
    var userSetsUrl = "/u/" + profileName + "/sets"
		var userAchievementsUrl = "/u/" + this.props.profile.name + "/achievements"

		var userExportUrl = "/u/" + profileName + "/export"
    var userFriendsUrl = "/u/" + profileName + "/friends"
//    var userLockedItemsUrl = "/u/" + this.props.profile.name + "/lockeditems"
    var userPouchUrl = "/u/" + profileName + "/pouch"

    var userRedeemUrl = "/u/" + profileName + "/redeem"


	if (token) {

    return(
      <nav>
        <MenuIcon className="nav-icon" onClick={() => this.setState({showNav: true})}/>

        <nav>
          <SideNav
            showNav = {this.state.showNav}
            onHideNav = {() => this.setState({showNav: false})}
            items={[
              <span>
                <p className="menu-label">PROFILE</p>
                <ul className="menu-list">
                  <li><NavLink to={userPouchUrl}>Pouch</NavLink></li>
                  <li><NavLink to={userSetsUrl}>Set Completion</NavLink></li>
                  <li><NavLink to={'/u/' + profileName + '/achievements' }>Achievements</NavLink></li>
                  <li><NavLink to={userFriendsUrl}>Friends</NavLink></li>
                  <li><NavLink to={userGiftUrl}>Gift Unique</NavLink></li>
                  <li><NavLink to={userExportUrl}>Locked Items</NavLink></li>
                  <li><NavLink to={userRedeemUrl}>Redeem Code</NavLink></li>
                </ul>
                <p className="menu-label">ITEMBEE</p>
                <ul className="menu-list">
                  <li><NavLink to="/tools/sets">Browse All Sets</NavLink></li>
                  <li><NavLink to="/tools/search">Item Search</NavLink></li>
                  <li><NavLink to="/tools/profile">Profile Search</NavLink></li>
                </ul>
                <p className="menu-label">GENERAL</p>
                <ul className="menu-list">
                  <li><NavLink to="/">Home</NavLink></li>
                  <li><NavLink to="/forums">Forums</NavLink></li>
                  <li><NavLink to="/about">About</NavLink></li>
                  <li><NavLink to="/faq">FAQ</NavLink></li>
                  <li><NavLink to="/places">Places</NavLink></li>
                  <li><NavLink to="/rules">Rules</NavLink></li>
                  <li><NavLink to="/uniques">Uniques</NavLink></li>
                  <li><a href="https://wallabee.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Support</a></li>
      			  <li><a onClick={this.logoutButton} href="/">Logout</a></li>
                </ul>
              </span>
            ]}
//            onHideNav      =  {() => document.getElementById("main-app").setAttribute("style", "width:100%; margin-left:0;")}
            navStyle={{
              position: 'fixed',
              top: '50px',
              left: '0',
              bottom: '0',
              padding: '0 0 50 0',
              width: '220px',
              minWidth: '45px',
              maxHeight: '100vh',
              height: 'calc(100% - 50px)',
              zIndex: '1023',
              background: '#fff',
              boxShadow: '0 2px 3px hsla(0,0%,7%,.1), 0 0 0 1px hsla(0,0%,7%,.1)',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            title = {<div><img src='https://wallazee.global.ssl.fastly.net/images/site/logo.png' width='110' alt="WallaBee Logo" className="sidebar-avatar"/></div>}
            titleStyle= {{backgroundColor: '#2e2b2b', textAlign:'center', lineHeight:'1.3', fontSize:'14px', padding:'19px', height:'171px'}}
            itemStyle= {{padding:'10px'}}
          />
        </nav>
      </nav>
    )


	} else {

    return(
      <nav>
        <MenuIcon className="nav-icon" onClick={() => this.setState({showNav: true})}/>

        <nav>
          <SideNav
            showNav = {this.state.showNav}
            onHideNav = {() => this.setState({showNav: false})}
            items={[
              <span>
                <li><NavLink to="/login">Login</NavLink></li>
                <p className="menu-label">ITEMBEE</p>
                <ul className="menu-list">
                  <li><NavLink to="/tools/sets">Browse All Sets</NavLink></li>
                  <li><NavLink to="/tools/search">Item Search</NavLink></li>
                  <li><NavLink to="/tools/profile">Profile Search</NavLink></li>
                </ul>
                <p className="menu-label">GENERAL</p>
                <ul className="menu-list">
                  <li><NavLink to="/">Home</NavLink></li>
                  <li><NavLink to="/about">About</NavLink></li>
                  <li><NavLink to="/faq">FAQ</NavLink></li>
                  <li><NavLink to="/places">Places</NavLink></li>
                  <li><NavLink to="/rules">Rules</NavLink></li>
                  <li><NavLink to="/uniques">Uniques</NavLink></li>
                  <li><a href="https://wallabee.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer">Support</a></li>

                </ul>
              </span>
            ]}
 //          onHideNav      =  {() => document.getElementById("main-app").setAttribute("style", "width:100%; margin-left:0;")}
            navStyle={{
              position: 'fixed',
              top: '50px',
              left: '0',
              bottom: '0',
              padding: '0 0 50 0',
              width: '220px',
              minWidth: '45px',
              maxHeight: '100vh',
              height: 'calc(100% - 50px)',
              zIndex: '1023',
              background: '#fff',
              boxShadow: '0 2px 3px hsla(0,0%,7%,.1), 0 0 0 1px hsla(0,0%,7%,.1)',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            title = {<div>
              <img src='https://wallazee.global.ssl.fastly.net/images/site/logo.png' width='110' alt="WallaBee Logo" className="sidebar-avatar"/>
            </div>}
            titleStyle= {{backgroundColor: '#2e2b2b', textAlign:'center', lineHeight:'1.3', fontSize:'14px', padding:'19px', height:'171px'}}
            itemStyle= {{padding:'10px'}}
          />
        </nav>
      </nav>
    )
    } // end else
  }
}

/*
avatar code for later maybe
              <img src={this.props.profile.image_url_200} width='110' className="sidebar-avatar"/>
              <div>{this.props.profile.balance}</div>
*/


export default connect(mapStateToProps)(SideBar)
