import React from 'react';
import packageJson from '../package.json';

global.appVersion = packageJson.version;

const checkVersionMismatch = (letestVersion, currentVersion) => {

    var a = 0;
    var b = 0;

    letestVersion= letestVersion.split(/\./g);  

    currentVersion= currentVersion.split(/\./g);

    while (letestVersion.length || currentVersion.length) {

       a = Number(letestVersion.shift());  

       b = Number(currentVersion.shift());

      // eslint-disable-next-line no-continue
      // console.log(`a ${a} b ${b} `);

      if (a !== b)
      {
        return a > b || isNaN(b);
      };

      // eslint-disable-next-line no-restricted-globals


    }

    return false;

  };

  class HandleCache extends React.Component {

    constructor(props) {

      super(props);

      this.state = {

        isLoading: true,

        isLatestVersionAvailable: false,

        clearCacheAndReload: () => {

          console.log('Clearing cache and hard reloading...')

          if (caches) {

            // deleting saved cache one by one

            caches.keys().then(function(names) {

              for (let name of names) caches.delete(name);

            });

          }

          // after deleting cached data hard reload the site

          window.location.reload(true);

        }

      };

    }  

    componentDidMount() {

      fetch('/meta.json')

        .then((response) => response.json())

        .then((meta) => {

          const latestVersion = meta.version;

          const currentVersion = global.appVersion;  

          const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion);

          if (shouldForceRefresh) {

            console.log(`New verion - ${latestVersion}. available need to force refresh`);

            this.setState({ loading: false, isLatestVersion: false });

          } else {

            console.log(`Already latest version - ${latestVersion}. No refresh required. ${currentVersion}`);

            this.setState({ loading: false, isLatestVersion: true });

          }

        });

    }  

    render() {

      const { isLoading, isLatestVersionAvailable, clearCacheAndReload} = this.state;

      return this.props.children({ isLoading, isLatestVersionAvailable, clearCacheAndReload});

    }

  }

  export default HandleCache;
