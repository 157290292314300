const endpoints = {

  latestItems: 'https://api.wallabeegame.com/items/latest',

  singleItem: 'https://api.wallabeegame.com/itemtypes/',

  itemByID: 'https://api.wallabeegame.com/items/',

  itemSearch: 'https://api.wallabeegame.com/items/search/',

  formulas: 'https://api.wallabeegame.com/items/formulas/known',

  stores: 'https://api.wallabeegame.com/stores',

  categories: 'https://api.wallabeegame.com/placecategories',

  latestSets: 'https://api.wallabeegame.com/sets/latest',

  allSets: 'https://api.wallabeegame.com/v2/sets/',

  oldAllSets: 'https://api.wallabeegame.com/sets/',

  branded: 'https://api.wallabeegame.com/v2/sets/20/',

  wallagraphics: 'https://api.wallabeegame.com/wallagraphics',

  uniqueset: 'https://api.wallabeegame.com/v2/sets/25/',

  olduniqueset: 'https://api.wallabeegame.com/uniqueitems',

  places: 'https://api.wallabeegame.com/places/',

  globalStats: 'https://api.wallabeegame.com/globalstats',

  loginUser: 'https://api.wallabeegame.com/login',

  uniques: 'https://api.wallabeegame.com/users/gift/uniques',

  userStats: 'https://api.wallabeegame.com/users/',

  userStatsV2: 'https://api.wallabeegame.com/v2/users/',

  userProfile: 'https://api.wallabeegame.com/users/',

  userRegister: 'https://api.wallabeegame.com/register/',

  forums: 'https://api.wallabeegame.com/forum',

  inbox: 'https://api.wallabeegame.com/inbox/',

  isFriend: 'https://api.wallabeegame.com/is_friends/'

}

export default endpoints
