import actionTypes from './actionTypes'

const defaultState = { items: []}

const reducer = (state=defaultState,action) => {

  switch (action.type) {
    case actionTypes.FETCH_USER_SET_DETAIL:
      return { ...state,
        ...action.payload
      }
    case actionTypes.CLEAR_SET_ITEM_STATE:
      return defaultState
      default:
          return state
    }

}

export default reducer
