export default actionTypes([
  'UPDATE_USERNAME',
  'UPDATE_PASSWORD',
  'LOGIN_SUCCESS'

])

function actionTypes(names) {
  return names.reduce((result, name) => {
    result[name] = name
    return result
  }, {})
}
