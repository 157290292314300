import React from 'react'

const PanelItem = (props) => {

  const descriptions = props.desc.map(( desc, i ) => {
    return <li className="panel-block" key={i}>{desc}</li>
  })

  return (
      <ul className="panel">
        <div className="panel-heading">
          <img src={props.img} alt={props.name}/>
          <span>{props.name}</span>
        </div>
        {descriptions}
      </ul>
  )
}

export default PanelItem
