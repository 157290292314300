import actionTypes from './actionTypes'
import endpoints from '../../util/endpoints'
import utilities from '../../util/utilities'
import 'whatwg-fetch'

export const fetchForumTopicsSuccess = (data) => {
  return{
      type: actionTypes.FETCH_FORUM_TOPICS_SUCCESS,
      payload: data
    }
}

export const fetchSingleForumSuccess = (data) => {
  return{
      type: actionTypes.FETCH_SINGLE_FORUM_SUCCESS,
      payload: data
    }
}

export const fetchSingleThreadSuccess = (data) => {
  return{
      type: actionTypes.FETCH_SINGLE_THREAD_SUCCESS,
      payload: data
    }
}

export const fetchAvatar = (data) => {
  return{
      type: actionTypes.FETCH_USER_DATA,
      payload: data
    }
}

export const updateText = (text) => {
  return{
      type: actionTypes.UPDATE_COMMENT,
      payload: text
    }
}

export const postCommentSuccess = (data) => {
  return{
      type: actionTypes.POST_COMMENT_SUCCESS,
      payload: data
    }
}

export const postReplySuccess = (data) => {
  return{
      type: actionTypes.POST_REPLY_SUCCESS,
      payload: data
    }
}

export const clearingCommentState = () => {
  return {
    type: actionTypes.CLEAR_COMMENT_STATE
  }
}

export const fetchForums = (id, title, subtitle) => {
  return (dispatch, getState) => {


    const forumsUrl = endpoints.forums

    const key = utilities.apiKey
    const token = JSON.parse(localStorage.getItem('token'))


    fetch( forumsUrl ,{ method: 'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        dispatch(fetchForumTopicsSuccess(data))

      })
      .catch((e) => {
        console.log(e)
      })

  }
}

export const fetchSingleForum = (forumID, pageNum, id, title, closed, sticky, latest_username, latest_created_at) => {
  return (dispatch, getState) => {

    const forumsUrl = endpoints.forums + `/${forumID}/${pageNum}`

    const key = utilities.apiKey
    const token = JSON.parse(localStorage.getItem('token'))

    fetch( forumsUrl ,{ method: 'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((data) => {
        return data.json();
      })
      .then((data) => {

        dispatch(fetchSingleForumSuccess(data))
      })
      .catch((e) => {
        console.log(e)
      })

  }
}

export const fetchSingleThread = (threadID, threadPage) => {
  return (dispatch, getState) => {

    const token = JSON.parse(localStorage.getItem('token'))
    const userId = JSON.parse(localStorage.getItem('userId'))

    const forumsUrl = endpoints.forums + `/thread/${threadID}/${threadPage}`
    const userUrl = endpoints.userStats + userId

    const key = utilities.apiKey

    fetch( forumsUrl ,{ method: 'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((data) => {
        return data.json();
      })
      .then((data) => {

        dispatch(fetchSingleThreadSuccess(data))

      })
      .catch((e) => {
        console.log(e)
      })

      fetch( userUrl ,{ method: 'GET', headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            dispatch(fetchAvatar(data))

          })
          .catch((e) => {
            console.log(e)
      })

  }
}

export const postComment = (text) => {

    return(dispatch, getState) => {

    const { thread_id } = getState().forums;

    const forumsUrl = endpoints.forums + '/thread/' + thread_id
    const token = JSON.parse(localStorage.getItem('token'))

    const key = utilities.apiKey;

    let commentBody = new FormData();

    commentBody.append('text', text)

    fetch(forumsUrl,{method: 'POST', body: commentBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((res) => {
        return res.json();
      })
      .then((data) => {

        if (data.success) {
          dispatch(postCommentSuccess(data))

        } else {
          alert('Error')
        }

      })
      .catch((e) => {
        console.log(e);
      });
  }
}

export const postReply = (comment_id, text) => {

    return(dispatch, getState) => {

    const { thread_id } = getState().forums;

    const forumsUrl = endpoints.forums + '/thread/' + thread_id
    const token = JSON.parse(localStorage.getItem('token'))

    const key = utilities.apiKey;

    let commentBody = new FormData();

    commentBody.append('text', text)
    commentBody.append('quote_comment_id', comment_id)

    fetch(forumsUrl,{method: 'POST', body: commentBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
      .then((res) => {
        return res.json();
      })
      .then((data) => {

        if (data.success) {

          dispatch(postReplySuccess(data))

        } else {
          alert('Error')
        }

      })
      .catch((e) => {
        console.log(e);
      });
  }
}
