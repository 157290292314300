export default actionTypes([
  'FETCH_USER_SET_DETAIL',
  'CLEAR_SET_ITEM_STATE'
])

function actionTypes(names) {
  return names.reduce((result, name) => {
    result[name] = name
    return result
  }, {})
}
