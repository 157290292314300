import React from 'react';
import MetaTags from 'react-meta-tags'
import { NavLink } from 'react-router-dom'
import endpoints from '../../../util/endpoints';
import utilities from '../../../util/utilities';
import RetiredTable from '../../../util/retired'
import 'whatwg-fetch';


export default class NameSearch extends React.Component{
  constructor(){
    super()
    this.state = {itemList:[],targetName:[], searchForItem: false, found: false}

  }

 fetchItem(targetSearch){
	const searchurl = endpoints.itemSearch;
    const key = utilities.apiKey;

	var search = { 'text': targetSearch }

	var formBody = [];
	for (var property in search) {
	  var encodedKey = encodeURIComponent(property);
	  var encodedValue = encodeURIComponent(search[property]);
	  formBody.push(encodedKey + "=" + encodedValue);
	}
	formBody = formBody.join("&");

    fetch(searchurl,{method:'POST',
    headers: {'X-WallaBee-API-Key': key,  'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded'},
    body: formBody })
      .then(response => response.json())
      .then(json => {
	        this.setState({
	          itemList: json,
	          found: true
	        });
      });
  }

  fetchRetired(){
    var tempArray = []

    RetiredTable.Retired.map( item => {
        tempArray.push(item.item_type_id)
    })

    if (tempArray.length != 0) {
      this.setState({Retired: tempArray})
    }

  }

   searchItem(){
	  	var nameSearch=this.state.targetName;
  		if (nameSearch != null) {
	  		var targetURL = '/tools/search/'+nameSearch;
  			this.props.history.push(targetURL);
  		}
  }

   renderResults(){

   if (this.state.itemList.length> 0) {

    var RetiredItems = this.state.Retired;

    return this.state.itemList.map( item => {
      var retiredFlag = 0;

      RetiredItems.map( item_type_id =>{
        if (item_type_id==item.id) {
          retiredFlag = 1
        }
      })

	    var tempTarget="/tools/item/"+item.id;
     	var apiImage="https://wallazee.global.ssl.fastly.net/images/dynamic/items/"+item.id+"-100.png";

      if (retiredFlag == 1) {
        var displayImage = <div className="card-image text-center">
              <div className="itemSetDisplay">
              <NavLink  key={item.id} to={tempTarget} >
              <img key={item.id} src={apiImage} data-tip={item.name} alt={item.name} />
              <img src="https://wallazee.global.ssl.fastly.net/images/site/retired-overlay.png" className="retired" />
              </NavLink>
              </div>
              </div>
      } else {
        var displayImage = <div className="card-image text-center">
              <NavLink  key={item.id} to={tempTarget} >
              <img key={item.id} src={apiImage} data-tip={item.name} alt={item.name} />
              </NavLink>
              </div>
      }



      	return <div key={item.id} className="card" id="item">
          {displayImage}
          <div className="card-content">
            <NavLink className="text-center" key={item.id} to={tempTarget}>
              <p className="title">{item.name}</p>
            </NavLink>
            <p>{item.description}</p>

          </div>
        </div>
    } )

    } else {
    	return <h1>No Items Found</h1>


    }


  }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.query !== this.props.match.params.query) {
        	var nextQuery = nextProps.match.params.query
            this.setState({itemList:[],targetName:[],found:false});

		    this.fetchItem(nextQuery);
        }
    }


  componentWillMount() {
    var targetSearch = this.props.match.params.query
    this.fetchItem(targetSearch);
    this.fetchRetired()
  }

  render() {
	if (!this.state.found) {
		return <div className="container" id="ItemSearch"><center><br/><br/><br/><br/><br/><h1>Searching...</h1><br/><br/><br/><br/><br/></center></div>
	} else {

	var targetSearch = this.props.match.params.query
    return <div className="container" id="search">

      <MetaTags>
        <title>Search Items - WallaBee | WallaBee: Collecting and Trading Card Game on iOS</title>
      </MetaTags>

      <div className="container text-center">

        <form id="searchItems">
          <input className="banner-search input is-medium" type="text" size="50" placeholder="Search for Item..." onChange={(e) => this.setState({ targetName: e.target.value})}/><br/>
          <button className="button outdoors" id="nameSearch" onClick={this.searchItem.bind(this)}>Search</button><br/><br/>
        </form>

        <h2 className="title is-5">Item Search Results: '{targetSearch}'</h2>
      </div>

      <div className="flex card-container" id="item-results">
        {this.renderResults()}
      </div>

    </div>
	}
  }
}
