import React from 'react'
import MetaTags from 'react-meta-tags'
import { NavLink, Switch, Route } from 'react-router-dom'
import endpoints from '../../../util/endpoints'
import utilities from '../../../util/utilities'
import PlayerBio from './PlayerBio'
import PlayerUniques from './PlayerUniques'
import PlayerLocked from './LockedItemsList'
import PlayerWishlistMenu from './WishlistMenu'
import PlayerWishlist from './Wishlist'
import Modal from '../../../components/modal-publicprofile'
import 'whatwg-fetch'

export default class PlayerProfile extends React.Component{
  constructor(){
    super()
    this.state = {
      userInfo:[],
      foundPlayer: false,
      noPlayer: false,
      targetName:[],
      searchForProfile: false,
      isFriends: null,
      isModalOpen: false,
      message: ''
    }
    this.fetchProfile = this.fetchProfile.bind(this)
  }

  numberWithCommas(x){
    if(x){
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }else{
      return '0'
    }
  }

  openModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal() {
    this.setState({ isModalOpen: false })
  }

  updateMessage = (e) => {
    this.setState({
      message: e.target.value
    })
  }

  fetchProfile(personID){

    const key = utilities.apiKey;
	  const profileurl = endpoints.userProfile+personID;

    return fetch(profileurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
      .then(response => response.json())
          .then(jsonPerson => {
            if (jsonPerson===null) {
              this.setState({
                foundPlayer: true,
                noPlayer: true
              });
            } else {
            return new Promise((resolve, reject) => {
              this.setState({
                foundPlayer: true,
                userInfo: jsonPerson
              }, () => resolve() )
            })
          }
          })
	}

  isFriend = (personID) => {

    this.fetchProfile(personID).then(() => {

      const key = utilities.apiKey;
      const userID = JSON.parse(localStorage.getItem('userId'))
      const friendurl = endpoints.isFriend + userID + '/' + this.state.userInfo.id

      fetch(friendurl,{method:'GET', headers: {'X-WallaBee-API-Key': key} })
      .then((response) => { return response.json()})
        .then(data => {
          this.setState({
            isFriends: data.is_friends
          })

        })
      })
  }

  postMessage = (e) => {
    e.preventDefault()

    const { message, userInfo } = this.state
    const convoUrl = endpoints.inbox + 'conversation/' + userInfo.id
    const token = JSON.parse(localStorage.getItem('token'))
    const username = JSON.parse(localStorage.getItem('username'))
    const key = utilities.apiKey

    if (message != "") {

    let messageBody = new FormData();

    messageBody.append('text', message)

    fetch(convoUrl,{method: 'POST', body: messageBody, headers: {'X-WallaBee-API-Key': key, 'X-User-Token': token} })
          .then((res) => {
            return res.json();
          })
          .then((data) => {

            if(data.success){
              this.setState({
                message: ''
              })
              this.props.history.push('/u/' + username + '/inbox')

            } else {
              alert('error!')
            }

          })
          .catch((e) => {
            console.log(e);
          });
        }
    }

   searchProfile(){
	  	var nameSearch=this.state.targetName;
  		if (nameSearch != null) {
	  		var targetURL = '/tools/profile/'+nameSearch;
  			this.props.history.push(targetURL);
  		}
  }

  componentDidMount() {
    var targetSearch = this.props.match.params.playerID
	  this.fetchProfile(targetSearch);
    this.isFriend(targetSearch)

  }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.playerID !== this.props.match.params.playerID) {
        	var nextQuery = nextProps.match.params.playerID
            this.setState({userInfo:[],foundPlayer: false,noPlayer: false,targetName:[], searchForProfile: false});

		    this.fetchProfile(nextQuery);
        }
    }


  render() {

	if (!this.state.foundPlayer) {
		return <div className="container" id="PlayerProfile"><center><br/><br/><br/><br/><br/><h1>Searching...</h1><br/><br/><br/><br/><br/></center></div>
	} else if (this.state.noPlayer) {

		return <div className="container" id="PlayerProfile"><center><br/><br/><br/><br/><br/><h1>Error No User Found</h1><br/><br/><h1>Search for Player</h1>
		<form id="searchPlayer">
          <input type="text" size="50" onChange={(e) => this.setState({ targetName: e.target.value})}/><br/>
          <button className="outdoors" id="nameSearch" onClick={this.searchProfile.bind(this)}>Search</button>
        </form>
		</center></div>

	} else {

	var pageTitle=this.state.userInfo.name + " Profile - WallaBee | WallaBee: Collecting and Trading Card Game on iOS";
  var account = "Player";
	switch (this.state.userInfo.account_type) {
		case "staff":
			account = "👨‍💻 Staff👨‍💻 ";
			break;
		case "beekeeper":
			account = "🐝 BeeKeeper 🐝";
			break;
		default:
      if (this.state.userInfo.is_memorial==1) {
        account = "🕊 🕯 Memorial Account 🕯 🕊";
      } else {
        account = "Player";
      }
			break;
	}

  var userLockList = "/tools/profile/"+this.state.userInfo.id+"/locklist"
  var userWishList = "/tools/profile/"+this.state.userInfo.id+"/wishlist"
  var userBio= "/tools/profile/"+this.state.userInfo.id+"/bio"
  var userUniques= "/tools/profile/"+this.state.userInfo.id+"/uniques"

	var createdat = new Date(this.state.userInfo.created_at).toUTCString()
  var lastactive = new Date(this.state.userInfo.last_active).toUTCString()
  var deletionDate = new Date(this.state.userInfo.deletion_date).toUTCString()
  var deleteFlag = false
  var selfDeleteFlag = false
  var memorialFlag = false
  var activeFlag = false

  var nowTime = new Date()
  var thenTime = new Date(this.state.userInfo.deletion_date)

    if (nowTime>thenTime) (
      deleteFlag = true
    )
    if ((this.state.userInfo.is_enabled==0)&&(nowTime<thenTime)) {
      selfDeleteFlag = true
    }
    if (this.state.userInfo.is_memorial==1) {
      memorialFlag = true
      deleteFlag = false
      selfDeleteFlag = false 
    } else {
      activeFlag = true
    }

	    return <div id="PlayerProfile">

        <MetaTags>
          <title>{pageTitle}</title>
        </MetaTags>

        <Modal isOpen={this.state.isModalOpen} onClose={() => this.closeModal()}>
          <div className="box text-center">

            <h3 className="title is-5">Send {this.state.userInfo.name} a message</h3>

            <form onSubmit={this.postMessage}>
              <textarea className="textarea" placeholder="Write your message..." onChange={this.updateMessage} value={this.state.message}></textarea>
              <div className="field is-grouped is-grouped-centered">

                <p className="control">
                  <button className="button outdoors forum-btn" type="submit">
                    Send
                  </button>
                </p>
                <p className="control">
                  <a className="button sports forum-btn" onClick={() => this.closeModal()}>
                    Cancel
                  </a>
                </p>

              </div>
            </form>
          </div>
        </Modal>

        <header className="hero hero-header" id="public-profile">
          <div className="container">
            <div className="columns">
              <div className="column is-2">
                <div className="image is-128x128 text-center margin">
                  <img src={this.state.userInfo.image_url_200} id="round-profile" alt={this.state.userInfo.name} />
                </div>
              </div>
              <div className="column is-4">
                <h1 className="title">
                  {this.state.userInfo.name}
                </h1>
                <h2>
                  {(activeFlag) && <strong><font color="green">Last Active: {lastactive}</font><br/></strong>}
                  {(memorialFlag) && <strong><font color="green">Created: {createdat})</font><br/></strong>}
                  {(deleteFlag) && <strong><font color="red">Deleted: {deletionDate}</font><br/></strong>}
                  {(selfDeleteFlag) && <strong><font color="red">Self Deleted or Banned</font><br/></strong>}
                  {account}
                </h2>

                {
                  (() => { if(this.state.isFriends){
                    return <button className="button outdoors" onClick={() => this.openModal()} style={{'margin-left':'0'}}>
                      <span className="icon"><i className="fa fa-envelope" aria-hidden="true"></i></span>
                      <span>Send Message</span>
                    </button>
                  }})()
                }

              </div>

              <div className="column is-6 flex" id="profile-header-stats">
                <div className="header-stats has-text-centered">
                  <figure className="image is-96x96 margin">
                    <i className="fa fa-check-circle-o header-icon" aria-hidden="true"></i>
                  </figure>
                  <p className="stat-val" id="set-completed">{this.state.userInfo.completed_sets_count}</p>
                  <p className="stat-key">Sets Completed</p>
                </div>
                <div className="header-stats has-text-centered">
                  <figure className="image is-96x96 margin">
                    <img className="image is-96x96 text-center" src="https://wallazee.global.ssl.fastly.net/images/site/friends-icon.svg" alt="Friends"/>
                  </figure>
                  <p className="stat-val" id="friend-count">{this.numberWithCommas(this.state.userInfo.friends_count)}</p>
                  <p className="stat-key">Friends</p>
                </div>
                <div className="header-stats has-text-centered">
                  <figure className="image is-96x96 margin">
                    <i className="fa fa-clock-o header-icon" aria-hidden="true"></i>
                  </figure>
                  <p className="stat-val" id="set-date">{createdat}</p>
                  <p className="stat-key">Foraging Since</p>
                </div>
              </div>
            </div>
          </div>
          <section className="tabs is-centered is-boxed">
            <ul>
              <li>
                <NavLink className="link" to={userBio}>
                  <span className="icon"><i className="fa fa-circle-o" aria-hidden="true"></i></span>
                  <span>Bio</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to={userUniques}>
                  <span className="icon"><i className="fa fa-star" aria-hidden="true"></i></span>
                  <span>Uniques Distributed</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to={userLockList}>
                  <span className="icon"><i className="fa fa-lock" aria-hidden="true"></i></span>
                  <span>Locked Items</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="link" to={userWishList}>
                  <span className="icon"><i className="fa fa-lock" aria-hidden="true"></i></span>
                  <span>Wish Lists *BETA*</span>
                </NavLink>
              </li>

            </ul>
          </section>
        </header>
        <section id="profile-info-container">
          <Switch>
            <Route path='/tools/profile/:playerID/bio' component={PlayerBio} />
            <Route path='/tools/profile/:playerID/uniques' component={PlayerUniques} />
            <Route path='/tools/profile/:playerID/locklist' component={PlayerLocked} />
            <Route exact path='/tools/profile/:playerID/wishlist' component={PlayerWishlistMenu} />
            <Route exact path='/tools/profile/:playerID/wishlist/:targetNumber' component={PlayerWishlist} />
            <Route exact path='/tools/profile/:playerID/wishlist/:targetNumber/:includeUniques' component={PlayerWishlist} />
          </Switch>
        </section>

		</div>

	}
	}

}
