import React from 'react'
import Panel from '../../../components/panel'

var placeArray = [
  {
    'name': 'BEACH',
    'desc': ['Public beach (one spot)'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/25-51e53836-6d38-4e0a-a3e9-162bc63dd4aa-50.png',
    'id': 1
  },
  {
    'name':'BRIDGE',
    'desc': ['Bridges of significant size, generally over water','NOT: Overpasses, roads'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/21-51e537f4-8918-4945-b48f-1068c63dd4aa-50.png',
    'id': 2
  },
  {
    'name':'CAMPING',
    'desc': ['Campgrounds (e.g., KOA)','Vacation RV Parks'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160807-a160d88cc42c823b74cacfceb3b72e0b757db831975081cc621225e-50.png',
    'id': 3
  },
  {
    'name':'CEMETERY/GRAVEYARD',
    'desc': ['"I see dead people"','NOT: Individual (high-profile) graves (monument/sculpture)'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20161215-b92869f3dff13b0a7504960b75e6d926e68e55f6661b5e3493c02b8-50.png',
    'id': 4
  },
  {
    'name':'HISTORIC STRUCTURE/SITE',
    'desc': ['Historically relevant structures','Notable battlefields and historically relevant cemeteries/burial grounds','NOT: Locations where structures used to exist, but have since been demolished; structures/homes simply possessing heritage designation'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160302-74db191343cc33c1c0fc31226eafae40668c913fd502418495a52d2-50.png',
    'id': 5
  },
  {
    'name':'MONUMENT/SCULPTURE',
    'desc': ['Significant, named monuments and sculptures','NOT: Individual graves, garden gnomes'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/23-51e53816-5870-4c89-b862-1360c63dd4aa-50.png',
    'id': 6
  },
  {
    'name':'OTHER',
    'desc': ['Defined outdoor plazas and city squares','Fairgrounds','Significant community gardens','Significant bodies of water (e.g., lakes)','U-Pick farms','NOT: Water towers (service/other), radio towers, Equestrian centres (sports/outdoor), farms'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/28-51e538c2-f798-48b9-b5d9-162cc63dd4aa-50.png',
    'id': 7
  },
  {
    'name':'OUTDOOR SPORTS',
    'desc': ['Baseball diamonds, soccer/football pitches, cricket, tennis, etc.','Outdoor combined sports facilities','Outdoor gun ranges','Equestrian centres','Parks whose primary purpose is sporting activities'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/variant/20160301-59fdb54e50b0d666c8f3fc3bd1b68c896626a4556178d27c641eb23-50.png',
    'id': 8
  },
  {
    'name':'PARK',
    'desc': ['Parks whose primary purpose is not sporting activities','Defined green spaces'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/27-51e5388d-b540-4949-a3fe-16d1c63dd4aa-50.png',
    'id': 9
  },
  {
    'name':'PLACE OF WORSHIP',
    'desc': ['Various places of worship, e.g., churches, mosques, synagogues, etc.','NOT: cemeteries (cemetery/graveyard)'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/24-51e53828-7424-4fde-8c87-1360c63dd4aa-50.png',
    'id': 10
  },
  {
    'name':'PLAYGROUND',
    'desc': ['Small, community pocket playgrounds','NOT: As part of larger park, interior playground (e.g., in a mall, child play centre)'],
    'img': 'https://wallazee.global.ssl.fastly.net/images/placecategories/26-51e53876-5330-4721-891d-15c0c63dd4aa-50.png',
    'id': 11
  }
]

export default class PlaceOutdoors extends React.Component {
  render(){
    const places = placeArray.map((place,i) => {
      return <Panel key={i} {...place}/>
    })

    return (
      <div>
        <div className="text-center">
          <img src="https://wallazee.global.ssl.fastly.net/images/placecategories/20-51e537e3-3198-4f7b-b45f-1068c63dd4aa-200.png" alt="Outdoor Places"/>
          <h2>Outdoors</h2>
        </div>
        {places}
      </div>
    )
  }
}
