import actionTypes from './actionTypes';
import endpoints from '../../../../util/endpoints';
import utilities from '../../../../util/utilities';
import 'whatwg-fetch';

const fetchUserSetDetailSuccess = (res) => {
  return {
    type: actionTypes.FETCH_USER_SET_DETAIL,
    payload: res
  }
}

export const clearingSetItemsState = () => {
  return {
    type: actionTypes.CLEAR_SET_ITEM_STATE
  }
}

export const fetchSetDetail = (userId, setID) => {

  return (dispatch, getState) => {


    const userurl = endpoints.userStats + userId

    const key = utilities.apiKey

	  var userSetDetailUrl = null;

//	if (setID === "20") {
//			userSetDetailUrl = userurl + `/branded`
//	} else if (setID === "25") {
//			userSetDetailUrl = userurl + `/unique`
//	} else {
			userSetDetailUrl = userurl + `/sets/${setID}`
//	}

    fetch(userSetDetailUrl,{ method: 'GET', headers: {'X-WallaBee-API-Key': key} })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        dispatch(fetchUserSetDetailSuccess(data))

      })
      .catch((e) => {
        console.log(e)
      })

  }
}
